<div class="card card-primary">
    <div class="card-header"> <i class="fa fa-users"></i>จัดการสมาชิก </div>
    <div class="card-body">
        <div class="container-fluid">
            <button class="btn btn-success" (click)="nullMember()" data-toggle="modal" data-target="#AddMember"><i
                    class="fa fa-user-plus"></i>เพิ่มสมาชิก</button> &nbsp;
                    <button class="btn btn-success"  data-toggle="modal" data-target="#AddclassMember"><i
                        class="fa fa-users"></i>ประเภทสมาชิก</button>

        </div>
        <hr>

        <div class="row">
            <div class="col-12">
                <!-- Custom Tabs -->
                <div class="card ">
                    <div class="card-header ">
                        <h3 class="card-title "></h3>
                        <ul class="nav nav-pills ">
                            <li class="nav-item">

                            </li>
                          
                            <li class="nav-item" *ngFor="let item of mamberclass">
                                &nbsp;  <a class="btn btn-md btn-success"  (click)="selecteclass(item.class)" href="#{{item.class}}"
                                    data-toggle="tab"><i class="fas fa-users"></i>
                                     {{item.class}}</a>
                            </li>
                           
                        </ul>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="tab-content">
                          
                            <div class="tab-pane  active" id="{{selectclass}}">
                                <div class="d-flex flex-row-reverse bd-highlight">
                                    <div><input type="search" class="form-control" placeholder="ค้นหาสมาชิก"
                                            [(ngModel)]="searchRestaurantmember" name="" id=""></div>
                                    <div class="p-2 bd-highlight">
                                        <pagination-controls (pageChange)="AllRestaurantmember = $event">
                                        </pagination-controls>

                                    </div>
                                </div>
                                <div class="row">
                                    <div
                                        *ngFor="let item of members|filter:searchRestaurantmember | paginate: { itemsPerPage: 100, currentPage: AllRestaurantmember }">
                                        <div class="col-sm-12">

                                            <div class="info-box ">
                                                <span class="info-box-icon bg-success elevation-1 btn "
                                                    data-toggle="modal" (click)="lookupresturant(item)"
                                                    data-target="#lookupresturant"><i class="fas fa-user "> </i></span>
                                                <br>
                                                <div class="row">

                                                    <div class="info-box-content ">

                                                        <span class="info-box-text "></span>
                                                        <strong>ชื่อ-ร้านอาหาร:{{item.company}} &nbsp;
                                                        </strong>

                                                        <span class="info-box-number "> <strong>เบอร์โทร
                                                            </strong>&nbsp;{{item.phone}} </span>
                                                        <span class="info-box-number "> &nbsp;<button
                                                                (click)="editmemberdatabase(item) " data-toggle="modal"
                                                                data-target="#AddMember" class="btn btn-sm btn-warning">
                                                                <i class="fas fa-edit"></i> </button>&nbsp;<button
                                                                class="btn btn-sm btn-danger" (click)="del(item)">
                                                                <i class="fas fa-trash"></i></button></span>

                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                       
                        </div>
                        <!-- /.tab-content -->
                    </div>
                </div>
                <!-- ./card -->
            </div>
            <!-- /.col -->
        </div>
    </div>
</div>



<!-- Modal -->
<div class="modal fade" id="lookupallmembercredit" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ข้อมูลการค้างค่าชำระสินค้าของสมาชิก ชื่อ :{{companycutomer}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>วัน-เดือน-ปี</th>
                            <th>เลขที่ใบเสร็จ</th>
                            <th>ยอดค้างชำระ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of customercreditdetail">
                            <td><button type="button" data-toggle="modal" data-target="#detailcreditcustomerlookup"
                                    (click)="detailcreditcustomerlookup(item)" class="btn btn-sm btn-primary"><i
                                        class="fa fa-search" aria-hidden="true"></i></button></td>
                            <td>{{item.date_creact|date:'dd/MM/yyyy'}}</td>
                            <td>#{{item.ID_order}}</td>
                            <td>{{item.total_credit|number:'2.2'}}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="lookupmember" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ข้อมูลสมาชิก</h5>
                <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">


                        <div class="col-lg-3">


                            <label for="">ชื่อ-สกุล:</label>{{member.first_name}}&nbsp;{{member.last_name}}
                            <br>
                            <label for="">email:</label>{{member.email}}
                            <br>
                            <label for="">เบอร์โทร:</label>{{billing.phone}}
                        </div>
                        <div class="col-lg-9">
                            <h3>ประวัติการซื้อสินค้า</h3>
                            <table class="table table-hover table-responsive">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>เลขที่ใบเสร็จ</th>


                                        <th>ราคา</th>
                                        <th>สถานะ</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of orderdetail">
                                        <td scope="row"><button class="btn btn-sm btn-info"
                                                (click)="seedetailorder(item)" data-toggle="modal"
                                                data-target="#lookupBilldetaill">
                                                <i class="fas fa-search"></i></button></td>
                                        <td> <strong>#{{item.id}}</strong> </td>


                                        <td>{{item.total|number}}</td>
                                        <td>
                                            <div *ngIf="item.status == 'processing'" class="text-warning">
                                                กำลังจัดส่งสินค้า
                                            </div>
                                            <div *ngIf="item.status == 'on-hold'" class="text-warning">
                                                รอการชำระเงิน
                                            </div>
                                            <div *ngIf="item.status == 'completed'" class="text-success">
                                                ได้รับสินค้าเรียบร้อยแล้ว
                                                &nbsp;{{item.date_completed|date:'dd-mm-yyyy '}}
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>
</div>
<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="detailcreditcustomerlookup" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><strong>เลขที่ใบเสร็จ:#{{orderID}}</strong> </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card ">
                            <div class="card-header"><strong>ข้อมูลสินค้า</strong></div>
                            <div class="card-body">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>รหัสสินค้า</th>
                                            <th>ชื่อสินค้า</th>
                                            <th>จำนวน</th>
                                            <th>ราคา</th>
                                            <th>ราคารวม</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of productdetail">
                                            <td>{{item.sku}}</td>
                                            <td>{{item.name}}</td>
                                            <td>{{item.quantity}}</td>
                                            <td>{{item.price|number:'2.2'}}</td>
                                            <td>{{item.quantity * item.price |number:'2.2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card ">
                            <div class="card-header"><strong>ข้อมูลประวัติการชำระ</strong></div>
                            <div class="card-body">
                                <button type="button" data-toggle="modal" data-target="#paymentcredit"
                                    class="btn btn-primary">ชำระยอดค้าง</button>
                                <hr>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>วัน-เดือน-ปี</th>
                                            <th>ประเภท</th>
                                            <th>จำนวนเงิน</th>
                                            <th>บันทึกโดย</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of creditdetail">
                                            <td>{{item.date_input|date:'dd/MM/yyyy'}}</td>
                                            <td>
                                                <div *ngIf="item.type_payment === 'bank'" class="text-info">
                                                    ชำระผ่านการโอน</div>
                                                <div *ngIf="item.type_payment === 'cash'" class="text-success">
                                                    ชำระด้วยเงินสด</div>
                                            </td>
                                            <td>{{item.total_payment|number:'2.2'}}</td>
                                            <td>{{item.ID_users}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="lookupBilldetaill" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-xl " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ข้อมูลใบเสร็จ</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h4>เลขที่ใบเสร็จ {{seedetail.id}}</h4>
                <table class="table table-hover table-inverse table-responsive">
                    <thead class="thead-inverse">
                        <tr>
                            <th>ลำดับที่</th>
                            <th>รหัสสินค้า</th>
                            <th>ชื่อ-สินค้า</th>
                            <th>ราคาต่อหน่วย</th>
                            <th>จำนวน</th>

                            <th>ราคารวม</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of seedetail.line_items;let i =index">
                            <td scope="row">{{i +1}}</td>
                            <td>{{item.id}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.price|number}}</td>
                            <td>{{item.quantity}}</td>

                            <td>{{item.subtotal|number}}</td>
                            <td></td>
                        </tr>

                    </tbody>
                    <h4>ราคารวมทั้งสิน <strong>{{seedetail.total|number}}</strong> บาท</h4>
                </table>
            </div>

        </div>
    </div>
</div>
<!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="AddMember" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-ml" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="member.ID === null ">เพิ่มข้อมูลสมาชิก</h5>
                <h5 class="modal-title" *ngIf="member.ID !== null ">แก้ไขข้อมูลสมาชิก</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="contrainer">
                    <div class="row" *ngIf="member.ID === null ">
                            <select class="form-control" (click)="selectmemberclass($event)" name="" id="">
                                <option  value="0">--เลือกประเภทลูกค้า---</option>
                              
                                <option *ngFor="let item of mamberclass" value="{{item.class}}">
                                    {{item.class}}
                                </option>

                            </select>
                       
                    </div>
                 
                        <div class="row" *ngIf="member.class !== '0' && member.class !== null ">
                            <div class="col-sm-12">
                                <label for="">ชื่อ-{{member.class}}:<strong class="text-danger">*</strong></label>
                                <input type="text" class="form-control" placeholder="กรอก ชื่อ-{{member.class}}" [(ngModel)]="member.company" name="" id="">
                            </div>
                        </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">ชื่อ:<strong class="text-danger">*</strong></label>
                            <input type="text" [(ngModel)]="member.first_name" placeholder="กรอก ชื่อ-สกุล"
                                class="form-control">
                        </div>
                        <div class="col-sm-6">
                            <label for="">สกุล</label>
                            <input type="text" [(ngModel)]="member.last_name" placeholder="กรอก ชื่อ-สกุล"
                                class="form-control">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">เบอร์โทร:<strong class="text-danger">*</strong> </label>
                            <input type="text" [(ngModel)]="member.phone" placeholder="กรอกเบอร์โทร"
                                class="form-control">
                        </div>
                        <div class="col-sm-6">
                            <label for="">อีเมล: <strong class="text-danger"></strong> </label>
                            <input type="email" [(ngModel)]="member.email" placeholder="กรอกเบอร์โทร"
                                class="form-control">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-9">
                            <label for="">หมายเลขผู้เสียภาษี:(ถ้ามี) </label>
                            <input type="text" [(ngModel)]="member.tax_id" placeholder="กรอก หมายเลขผู้เสียภาษี" class="form-control">
                        </div>
                       
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <label for="">ที่อยู่:<strong class="text-danger">*</strong> </label>
                            <textarea class="form-control" [(ngModel)]="member.address" name="" id=""
                                rows="3"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">อำเภอ: <strong class="text-danger">*</strong> </label>
                            <input type="text" [(ngModel)]="member.city" placeholder="กรอก อำเภอ" class="form-control">
                        </div>
                        <div class="col-sm-6">
                            <label for="">จังหวัด:<strong class="text-danger">*</strong> </label>
                            <input type="text" [(ngModel)]="member.state" (change)='selectstate($event)'
                                placeholder="กรอก จังหวัด" class="form-control">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">เลขที่ไปรษณี:<strong class="text-danger">*</strong> </label>
                            <input type="text" [(ngModel)]="member.postcode" placeholder="กรอก เลขที่ไปรษณี"
                                class="form-control">
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="member.ID === null " type="button" data-dismiss="modal" (click)="save()" class="btn btn-success">
                    บันทึก

                </button>
                <button *ngIf="member.ID != null " type="button" data-dismiss="modal" (click)="save()"
                    class="btn btn-warning">แก้ไข</button>



            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="lookupresturant" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{selectclass}}: {{resturant}} </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="d-flex justify-content-center">
                        <div class="row">
                            <div class="col-sm-9">
                                <input type="date" class="form-control" [(ngModel)]="searchDate" name="" id="">
                            </div>
                            <div class="col-sm-3">
                                <button type="button" (click)="nulldateseacrh()"
                                    class="btn btn-sm btn-primary">ยกเลิก</button>
                            </div>

                        </div>
                    </div>
                    <table class="table mt-2">
                        <thead>
                            <tr>
                                <th>วันที่</th>

                                <th>จำนวนสินค้า(ชิ้น)</th>
                                <th>ยอดสุทธิ(บาท)</th>
                                <th>ยอดส่วนลด(บาท)</th>
                                <th>ยอดรวม(บาท)</th>
                                <th>ต้นทุนรวม(บาท)</th>
                                <th>กำไร(บาท)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lookupresturants|filter:searchDate">
                                <td><a data-toggle="modal" href="#showdetail"
                                        (click)="showdetail(item)">{{item.date_string}}</a></td>

                                <td>{{item.total_qty|number:'1.2'}}</td>
                                <td>{{item.sumprice| number:'2.2'}}</td>
                                <td>{{item.discounts| number:'2.2'}}</td>
                                <td>{{item.sumprice - item.discounts | number:'2.2'}}</td>
                                <td>{{item.totalcost |number:'1.2'}}</td>
                                <th>{{(item.sumprice - item.discounts ) - item.totalcost | number:'2.2'}}</th>

                            </tr>

                        </tbody>

                    </table>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="showdetail" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog  modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ข้อมูลการซื้อสินค้าทั้งหมด</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>เวลา</th>
                                <th>รหัสสินค้า</th>
                                <th>ชื่อสินค้า</th>
                                <th>จำนวน</th>
                                <th>ราคา</th>
                                <th>ต้นทุน</th>
                                <th>ต้นทุนรวม</th>
                                <th>ราคารวม</th>

                                <th>กำไร</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of showdetails">
                                <td>{{item.date_input|date:'HH:mm':'UTC+7' }}</td>
                                <td>{{item.sku}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.quantity}}</td>
                                <td>{{item.price|number:'2.2'}}</td>
                                <td>{{item.cost |number:'2.2' }}</td>
                                <td>{{item.cost * item.quantity |number:'2.2' }}</td>
                                <td>{{item.price * item.quantity|number:'2.2'}}</td>

                                <td>{{(item.price * item.quantity) - (item.cost * item.quantity) |number:'2.2' }}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>
<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="paymentcredit" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ชำระยอดค้าง</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-6"><strong>รูปแบบการชำระ:<strong class="text-danger">*</strong></strong></div>
                    <div class="col-sm-6">

                        <select (change)="selectpaymentcredit($event)" class="form-control" name="" id="">
                            <option>---โปรดเลือกรูปแบบการชำระ</option>
                            <option value="cash">เงินสด</option>
                            <option value="bank">โอน</option>
                        </select>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-6"><strong>จำนวนเงิน:<strong class="text-danger">*</strong></strong></div>
                    <div class="col-sm-6"><input typr="number" [(ngModel)]="paymentcerdit.total_payment"
                            placeholder="กรอก จำนวนเงิน" min="0" class="form-control"></div>
                </div>
            </div>
            <div class="modal-footer">

                <button type="button" data-dismiss="modal" (click)="savepaymentcredit()"
                    class="btn btn-success">บันทึก</button>
            </div>
        </div>
    </div>
</div>
<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="AddclassMember" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ข้อมูลประเภทสมาชิก</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
            </div>
            <div class="modal-body">
                <a name="" id="" class="btn btn-primary" (click)="addclassmember()"  role="button">เพิ่มประเภท</a>
                <hr>
                <div class="row" *ngIf="addmember">
                    <input type="text" placeholder="กรอก ประเภทสมาชิก" name="" [(ngModel)]="classmember" class="form-control" id="">
                    <br>
                    <button type="button" class="btn btn-sm btn-success mt-2" (click)="saveclass()">บันทึก</button>
                </div>
                <table class="table table-responsive mt-2">
                    <thead>
                        <tr>
                            <th>ลำดับ</th>
                            <th>ประเภทสมาชิก</th>
                            <th></th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of mamberclass;let i=index">
                            <td scope="row">{{i+1}}</td>
                            <td>{{item.class}}</td>
                           
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
             
            </div>
        </div>
    </div>
</div>