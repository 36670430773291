import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadServices {
  [x: string]: any;
  
  constructor() { }
  
}
