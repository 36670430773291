import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
@Component({
  selector: 'app-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.css']
})
export class MonthComponent implements OnInit {

  constructor() { }
  lineChartData: ChartDataSets[] = [
    { data: [85, 72, 78, 75, 77, 75,72, 78, 75, 77,70, 78, 75, 77, 78, 75, 77, 75,72, 75, 77, 78, 75, 77, 75,72, 75, 77, 75,72], label: 'ยอดซื้อวันที่' },
  ];

  lineChartLabels: Label[] = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30',];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: 'Blue',
      backgroundColor: 'rgba(255,255,0,0.0)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType:any = 'line';
  ngOnInit(): void {
  }

}
