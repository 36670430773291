import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http'
import { ShareService } from '../../ShareService'
import { Router } from '@angular/router';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-data-admin',
  templateUrl: './data-admin.component.html',
  styleUrls: ['./data-admin.component.css']
})
export class DataAdminComponent implements OnInit {

  constructor(private http: HttpClient,
    private ShareService: ShareService,
    private router: Router,
    private Cookie: CookieService,) { }

  ngOnInit(): void {
    this.loadTh();
    this.loadCookie();
    this.loaddata()
   
  }
  pass = {
    password: null,
    CFpass: null
  }
  state:any = []
  users = {
    id: null,
    username: null,
    name: null,
    first_name: null,
    last_name: null,
    email: null,
    billing : {
      address_1: null,
      address_2: null,
      city: null,
      company: null,
      country: null,
      email: null,
      first_name: null,
      last_name: null,
      phone: null,
      postcode: null,
      state: null,
    },
    shipping: {
      first_name: null,
      last_name: null,
      company: null,
      address_1: null,
      address_2: null,
      city: null,
      postcode: null,
      country: null,
      state: null
    },
    url: null,
    description: null,
    locale: null,
    nickname: null,
    slug: null,
    roles: null,
    registered_date: null,
    avatar_urls: null
  }
  
  statusUser: any = {
    status: null,
    username: null,
    id: null,
    token: null
  }
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
  
    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }
  loadTh() {
    this.http.get(this.ShareService.serverPath + '/product/state').subscribe((res: any) => {
      if (res) {
        this.state = res

      }
    })
  }
  loaddata() {
    var params = {
      token: this.statusUser.token,
      id: this.statusUser.id
    }
   
    this.http.post(this.ShareService.serverPath + '/users/edits', params).subscribe((res: any) => {
      if (res) {
       
        this.users = res
      
        this.users.username = res.username
        this.state.billing = res.billing
        var filterth = this.state.filter((e:any)=>{
          return e.id == res.billing.state
        }) 
    
        this.users.billing.state = filterth[0].state
      }

    })
  }


  save() {
    this.users.billing.first_name = this.users.first_name
    this.users.billing.last_name = this.users.last_name
    var filterth = this.state.filter((e:any)=>{
      return e.state == this.users.billing.state
    }) 
    this.users.billing.state = filterth[0].id
    this.users.shipping = this.users.billing
    this.http.post(this.ShareService.serverPath + '/users/update', this.users).subscribe((res: any) => {
      if (res) {
       
        this.users = res
      
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'บันทึกข้อมูลเรียบร้อย \nโปรดรอสักครู่....',
          showConfirmButton: false,
          timer: 2000

        }).then(() => {

          this.router.navigate(['/', 'admin']);
        }).then(() => {
          this.loaddata()
        });

      }

    })
  }
 
}
