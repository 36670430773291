import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ShareService } from '../ShareService'
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  [x: string]: any;
  combinedCode:string = ""; 
 /* @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      console.log(event.key);
      var data =+this.combined
      console.log(data);
      var param = {
        data:this.combinedCode
      }
      console.log(param);
      
      this.http.post(this.ShareService.serverPath + '/product/insertcart', param.data).subscribe((res: any) => {
        console.log();
        
        /*if (res.data == 'NohaveData') {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'ไม่มีข้อมูลสินค้าที่คุณเลือก!',
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          }).then(() => {
            this.router.navigate(['/', 'cart']);
          });
        }
        if (res !== null) {
          this.myInputField.nativeElement.value = null
          console.log(res);
          location.href = '/cart'
        }
      })
    } else {
      if (this.combinedCode === null) {
        this.combinedCode = event.key;
      } else {
        this.combinedCode = this.combinedCode + event.key;
      }
    }
  }*/



 
  constructor(private http: HttpClient,
    private ShareService: ShareService,
    private router: Router,
    private Cookie: CookieService) { }

 
  statusUser: any = {
    username: null,
    name: null,
    token: null,
    email: null,
    shipping:null
  }
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
    
    this.statusUser = allCookies
    if (this.statusUser.username == null || this.statusUser.name == null || this.statusUser.token == null || this.statusUser.email == null) {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'การเชื่อมต่อล้มเหลว!! \n โปรดเข้าสู่ระบบ',
        showConfirmButton: true,
        timer: 2000

      }).then(() => {

        this.router.navigate(['/', '**']);
      });

    }
    
    
  }



  ngOnInit(): void {
    this.loadCookie();

  }
 

}
