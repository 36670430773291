import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ShareService } from '../../ShareService'
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common'
import * as moment from 'moment'; // เวลา
import 'moment-timezone';
import { Router } from '@angular/router';
@Component({
  selector: 'app-manageproduct',
  templateUrl: './manageproduct.component.html',
  styleUrls: ['./manageproduct.component.css']
})
export class ManageproductComponent implements OnInit {

  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService, public datepipe: DatePipe, private router: Router) { }
  Products: any = []
  wholesales: any = []
  costdata: any = []
  ngOnInit(): void {

    this.loadCookie()
    this.LoadDataProducts()
  }
  havedataproduct: boolean = !true
  p: number = 1
  search: any = null
  datasku: any = null;
  Productslt: any = []
  wholesale: any = []
  statusUser: any = {
    status: null,
    username: null,
    name: null,
    ID_branch: null,
    id: null
  }
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();

    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }
  qty_product: any = null
  price_product: any = null
  Product: any = {
    id: null,
    sku: null,
    stock_status: "instock",
    stock_quantity: null,
    regular_price: null,
    expiration_date: null,
    low_stock_quantity: null,
    status: "draft",
    manage_stock: true,
    total_sales: null,
    ID: null
  }
  wholesalesobj: any = {
    wholesale: 0,
    P3: 0,
    P4: 0,
    ID_product: null,
    ID: null,
    ID_branch: null
  }
  cost_product: any = {
    ID: null,
    ID_product: null,
    amount: null,
    cost_total: null,
    date_input: null,
    name: null,
    nameuser: null,
    new_cost: null,
    new_stock: null,
    old_cost: null,
    old_stock: null,
    remaining_stock: null,
    sku: null,
    stock_qty: null,
  }
  
  saveproduct() {
    if (this.Product.sku == null) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'กรุณา กรอกรหัสสินค้า \n โปรดลองใหม่อีกครั้ง  ',
        showConfirmButton: false,
        timer: 1500
      })
    } else if (this.Product.regular_price === null) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'กรุณา กรอกรหัสสินค้า \n โปรดลองใหม่อีกครั้ง  ',
        showConfirmButton: false,
        timer: 1500
      })
    } else if (this.Product.name === null) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'กรุณา กรอกชื่อสินค้า \n โปรดลองใหม่อีกครั้ง  ',
        showConfirmButton: false,
        timer: 1500
      })
    } else if (this.Product.stock_quantity === null) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'กรุณา กรอกจำนวนสินค้า \n โปรดลองใหม่อีกครั้ง  ',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      this.Product.price = this.Product.regular_price
      delete this.Product.regular_price
      delete this.Product.id
      this.Product.ID_branch = this.statusUser.ID_branch
      this.Product.ID_product = null
      console.log(this.Product);
      var path = this.ShareService.serverPath + '/product/saveproduct'
      this.http.post(path, this.Product).toPromise().then((res: any) => {
        console.log(res);

        if (res.data === "insertsucess") {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'เพิ่มข้อมูลสินค้าสำเร็จ  ',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.LoadDataProducts()
          })
        }
        if (res.data === "havesku") {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'เพิ่มข้อมูลสินค้าไม่สำเร็จ \n มีรหัสสินค้านี้แล้ว โปรดลองใหม่อีกครั้ง ',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.LoadDataProducts()
          })
        }
        return res
      }).then((res)=>{
        
        if (this.wholesalesobj.wholesale !== null) {
          
          this.wholesalesobj.ID_branch = this.statusUser.ID_branch
          this.wholesalesobj.ID_product = res.ID_product

          var path = this.ShareService.serverPath + '/product/insertproductwholesale'
          this.http.post(path, this.wholesalesobj).subscribe((res: any) => { })
        }
        return res ;
      }).then((res)=>{
        if (this.cost_product.new_cost) {
          this.cost_product.sku = this.Product.sku
          this.cost_product.name = this.Product.name
          this.cost_product.ID_product = res.ID_product
          this.cost_product.amount = this.qty_product
          this.cost_product.stock_qty = this.qty_product
          this.cost_product.ID_branch = this.statusUser.ID_branch
          this.cost_product.nameuser = this.statusUser.name
          var paths = this.ShareService.serverPath + '/cost/insertcost'
          this.http.post(paths, this.cost_product).subscribe((res: any) => {
            if (res) {

            }
          })
        }

      }).catch((err)=>{
        if(err){
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'เพิ่มข้อมูลสินค้าไม่สำเร็จ \nโปรดลองใหม่อีกครั้ง ',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.LoadDataProducts()
          })
        }
      })

    }
  }
  editproduct() {
    var param = {
      ID_product: this.Product.ID_product,
      name: this.Product.name,
      price: this.Product.regular_price,
      sku: this.Product.sku,
      stock_quantity: this.Product.stock_quantity,
      expiration_date: this.Product.expiration_date,
      low_stock_quantity: this.Product.low_stock_quantity,
      ID_branch: this.statusUser.ID_branch,
      url_img: this.Product.img,
      ID_users: this.statusUser.id
    }

    var path = this.ShareService.serverPath + '/product/updateproductstore'

    this.http.post(path, param).toPromise().then((res: any) => {
      console.log(res);
      if (res.data = "updateProduct") {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'แก้ไขข้อมูลสินค้าสำเร็จ  ',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.LoadDataProducts()
        })
      }
    })
  }
  select() {
    Swal.fire({
      title: 'คุณมีสินค้าแล้วหรือยัง?',
      text: "เพิ่มสินค้าใหม่หรือสินค้าที่มีอยู่แล้ว!\n",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      cancelButtonText: 'สินค้าที่มีอยู่แล้ว',
      confirmButtonText: 'สินค้าใหม่ '
    }).then((result) => {
      if (result.isConfirmed) {
        ('#editproductstore')

      }
    })
  }
  async  LoadDataProducts() {
   await  this.http.post(this.ShareService.serverPath + '/product/productsbranch', { data: this.statusUser.ID_branch }).toPromise().then(async(res: any) => {
      this.Products =  await res
    }).then(async() => {
      var param = await { ID_branch: this.statusUser.ID_branch }
      await  this.http.post(this.ShareService.serverPath + '/product/lookupproductwholesales', param).subscribe(async(res: any) => {
        this.wholesales = await res
      })
    }).then(async() => {
      var param = await { ID_branch: this.statusUser.ID_branch }
      await  this.http.post(this.ShareService.serverPath + '/product/lookupcost', param).subscribe(async(res: any) => {
        this.costdata = await res
      })
    })
  }
  selectproduct(item: any) {
    this.Productslt = item
    var filerwhole = this.wholesales.filter((e: any) => {
      return e.ID_product === item.ID_product
    })
    if (filerwhole.length !== 0) {
      this.wholesale = filerwhole[0]
    }
  }
  edit(item: any) {
    this.Product = item
    this.Product.regular_price = String(item.price)
    this.Product.expiration_date = moment(this.Product.expiration_date).format("yyyy-MM-DD")
  }

  savewholesale() {
    delete this.wholesalesobj.price;
    this.wholesalesobj.ID_branch = this.statusUser.ID_branch
    var path = this.ShareService.serverPath + '/product/insertproductwholesale'
    if (this.wholesalesobj.ID != null) {
      var path = this.ShareService.serverPath + '/product/editproductwholesale'
    }

    this.Product.total_sales = this.Product.regular_price
    this.http.post(path, this.wholesalesobj).subscribe((res: any) => {
      if (res.data == 'insertSuccess') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'กำหนดข้อมูลราคาส่ง สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.LoadDataProducts()
        });

      }
      if (res.data == 'updateProduct') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'แก้ไขข้อมูลราคาส่ง สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.LoadDataProducts()
        })

      }


    })

  }
  EditProductPrice(item: any) {
    this.Product.regular_price = item.price
    var filter = this.wholesales.filter((e: any) => {
      return e.ID_product == item.ID_product
    })
    console.log(filter);
    if (filter.length != 0) {
      this.wholesalesobj = filter[0]
    }
    this.wholesalesobj.ID_product = item.ID_product
  }
  EditProductcost(item: any) {

    var filtercost = this.costdata.filter((e: any) => {
      return e.ID_product === item.ID_product
    })
    console.log(filtercost);

    if (filtercost.length !== 0) {
      this.cost_product = filtercost[0]
    }
    if (filtercost.length == 0) {
      this.cost_product = {
        ID: null,
        ID_product: item.ID_product,
        cost_total: null,
        date_input: null,
        name: item.name,
        nameuser: this.statusUser.name,
        new_cost: null,
        new_stock: item.stock_quantity,
        old_cost: 0,
        old_stock: 0,
        remaining_stock: item.stock_quantity,
        sku: item.sku,
      }
    }
    var filterproduct = this.Products.filter((e: any) => {
      return e.ID === item.ID
    })
    console.log(filterproduct)
    this.Product.regular_price = filterproduct[0].price


  }
  savecost() {
    this.cost_product.ID_branch = this.statusUser.ID_branch
    console.log(this.cost_product);
    var paths = this.ShareService.serverPath + '/cost/insertcost'
    if (this.cost_product.ID !== null) {
      var paths = this.ShareService.serverPath + '/cost/updatecost'
      delete this.cost_product.date_input
    }
    this.http.post(paths, this.cost_product).subscribe((res: any) => {
      if (res.data == 'insertSuccess') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'กำหนดราคาต้นทุน สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.LoadDataProducts()
          this.nullProduct()
        })

      }
      if (res.data == 'updateSucess') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'กำหนดราคาต้นทุน สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {

          this.LoadDataProducts()
          this.nullProduct()
        })
      }
    })
  }
  nullProduct() {
    this.Product = {
      id: null,
      sku: null,
      stock_quantity: null,
      regular_price: null,
      expiration_date: null,
      low_stock_quantity: null,
      ID: null
    }
    this.cost_product = {
      ID: null,
      ID_product: null,
      amount: null,
      cost_total: null,
      date_input: null,
      name: null,
      nameuser: null,
      new_cost: null,
      new_stock: null,
      old_cost: null,
      old_stock: null,
      remaining_stock: null,
      sku: null,
      stock_qty: null,
    }
    this.wholesalesobj = {
      wholesale: null,
      P3: null,
      P4: null,
      ID_product: null,
      ID: null,
      ID_branch: null
    }
  }
  havedataclk() {
    this.havedata(this.datasku)
  }
  havedata(e: any) {
    console.log(e.target.value);
    var filterproduct = this.Products.filter((res: any) => {
      return res.sku === this.datasku && res.sku === e.target.value
    })
    console.log(filterproduct);

    if (this.datasku === null) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'โปรดกรอกรหสสินค้า',
        showConfirmButton: false,
        timer: 1500
      })
    } else if (filterproduct.length === 0) {
      console.log(this.datasku);
      var param = [this.datasku]
      var path = this.ShareService.serverPath + '/product/serchproduct';
      this.http.post(path, param).toPromise().then((res: any) => {
        if (res.length != 0) {
          this.Product = res[0]

          console.log(res);
          Swal.fire(
            'การค้นหาข้อมูลสินค้า!',
            'มีข้อมูลสินค้าที่คุณค้นหา.',
            'success'
          )
          this.havedataproduct = true
          this.datasku = null
        }
        if (res.length == 0) {
          Swal.fire(
            'การค้นหาข้อมูลสินค้า!',
            'ไม่มีข้อมูลสินค้าที่คุณค้นหา.',
            'error'
          )
          this.datasku = null
        }
      }).catch((err: any) => {
        if (err) {
          Swal.fire(
            'การค้นหาข้อมูลสินค้า!',
            'การค้นหาข้อมูลล้มเหลวโปรดลองใหม่อีกครั้ง.',
            'error'
          ).then(() => {
            this.datasku = null
          })
        }

      })
    } else {
      Swal.fire(
        'การค้นหาข้อมูลสินค้า!',
        'มีข้อมูลสินค้าที่คุณต้องการแล้วไม่สามารถเพิ่มได้อีก.',
        'error'
      ).then(() => {
        this.datasku = null
      })
    }
  }
  savehavedata() {
    var img = this.Product.images
    if (this.Product.images.length !== 0) {
      img = this.Product.images[0].src
    } else {
      img = ''
    }
    var expiration_date = moment(this.Product.expiration_date)
    expiration_date.tz('Asia/Bangkok');
    this.Product.expiration_date = expiration_date
    var param = {
      ID_product: this.Product.id,
      name: this.Product.name,
      price: this.price_product,
      sku: this.Product.sku,
      stock_quantity: this.qty_product,
      expiration_date: this.Product.expiration_date,
      low_stock_quantity: this.Product.low_stock_quantity,
      ID_branch: this.statusUser.ID_branch,
      url_img: img,
      ID_users: this.statusUser.id
    }
    var path = this.ShareService.serverPath + '/product/insertproductstore'
    this.http.post(path, param).toPromise().then((res: any) => {
    })
      .then(() => {
        //ราคาขายส่ง
        if (this.wholesalesobj.wholesale !== null) {
          this.wholesalesobj.ID_branch = this.statusUser.ID_branch
          this.wholesalesobj.ID_product = this.Product.ID_product

          var path = this.ShareService.serverPath + '/product/insertproductwholesale'
          this.http.post(path, this.wholesalesobj).subscribe((res: any) => {

          })
        }
      }).then(() => {
        if (this.cost_product.new_cost) {
          this.cost_product.sku = this.Product.sku
          this.cost_product.name = this.Product.name
          this.cost_product.ID_product = this.Product.id
          this.cost_product.amount = this.qty_product
          this.cost_product.stock_qty = this.qty_product
          this.cost_product.ID_branch = this.statusUser.ID_branch
          this.cost_product.nameuser = this.statusUser.name
          var paths = this.ShareService.serverPath + '/cost/insertcost'
          this.http.post(paths, this.cost_product).subscribe((res: any) => {
            if (res) {

            }
          })
        }
      }).then(() => {
        this.LoadDataProducts()
        this.nullProduct()
        //สินค้า
      }).then(() => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'เพิ่มข้อมูล สำเร็จ',
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          this.Product = []
          this.havedataproduct = !true
        })
      }).catch((err) => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'เพิ่มข้อมูล ไม่สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
  delproduct(item: any) {
    Swal.fire({
      title: 'คุณแน่ใจที่จะลบสินค้า?',
      text: "คุณแน่ใจที่จะลบสินค้า!\n" + item.name,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยันการลบสินค้า '
    }).then((result) => {
      if (result.isConfirmed) {
        var param = {
          ID_product: item.ID_product,
          ID_branch: this.statusUser.ID_branch
        }
        this.http.post(this.ShareService.serverPath + '/product/deleteproductstore', param).toPromise().then((res: any) => {
          console.log(res);
          return res
        }).then((res) => {
          if (res.data == 'deleteSuccess') {
            Swal.fire(
              'ลบข้อมูลสินค้า!',
              'ลบข้อมูลสินค้าสำเร็จ.',
              'success'
            )

          }
        }).then(() => {
          this.LoadDataProducts()
        })
          .catch((err) => {
            console.log(err)
          })

      }

    })
  }
  dataform:any = []
  productqtyform:any = null
  lookuproduct(e:any){
    console.log(e.target.value);
    var filterdataproduct = this.Products.filter((res:any)=>{
      return res.sku === String(e.target.value)
    })
    if(filterdataproduct.length === 0 ){
      Swal.fire(
        'กรค้นหาสินค้า',
        'ไม่มีสินค้าที่คุณเลือก.',
        'error'
      )
    }else{
      this.dataform = filterdataproduct
    }
  }
  datato:any = []
  productqtyto:any = null
  selectproducts(e:any){
    var filterdataproduct = this.Products.filter((res:any)=>{
      return res.sku === String(e.target.value)
    })
    if(filterdataproduct.length === 0 ){
      Swal.fire(
        'กรค้นหาสินค้า',
        'ไม่มีสินค้าที่คุณเลือก.',
        'error'
      )
    }else{
      this.datato = filterdataproduct
    }
  }
 async savedistributeproduct(){
    var param = await {
      ID_psto_form:this.dataform[0].ID,
      sku_form:this.dataform[0].sku,
      quantity_form:this.productqtyform,
      ID_psto_to:this.datato[0].ID,
      sku_to:this.datato[0].sku,
      quantity_to:this.productqtyto,
      ID_branch:this.statusUser.ID_branch,
      ID_users:this.statusUser.ID
    }
    console.log(param);
    await this.http.post(this.ShareService.serverPath + '/product/savedistribute', param).toPromise().then(async(res: any) => {
      console.log(res);
      if (res.data == 'insertsuccess') {
        await Swal.fire(
          'การแตกสินค้า',
          'เพิ่มข้อมุลการแตกสินค้าสำเร็จ.',
          'success'
        ).then(async()=>{
          this.datato = []
          this.dataform = []
          this.productqtyform = null
          this.productqtyto = null
          await this.loaddistribute()
        })

      }
   //   return res
    })
  }
  searchdistribute:any = null
  datadistribute:any = []
 async  loaddistribute(){
  this.http.post(this.ShareService.serverPath + '/product/showdistribute', {ID_branch:this.statusUser.ID_branch}).toPromise().then((res: any) => {
    this.datadistribute = res
    console.log(this.datadistribute);
  })
  }
  async distributeproduct(item:any){
    var filterdata = await this.Products.filter((res:any)=>{
      return res.ID === Number(item.ID_psto_form )
    })
  
    if(filterdata[0].stock_quantity === 0){
      Swal.fire(
        'การแตกสินค้า',
        'ไม่สามารถแตกสินค้าได้ \n เนื่องจากสินค้าหมด',
        'error'
      )
    }else {
      await  Swal.fire({
        title: 'คุณแน่ใจที่แตกสินค้า?',
        text: "" + item.nameform,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยันการแตกสินค้า '
      }).then(async(result) => {
        if (result.isConfirmed) {
          item.ID_branch = await this.statusUser.ID_branch
          await  this.http.post(this.ShareService.serverPath + '/product/distributeproducts', item).toPromise().then(async(res: any) => {
           
            return  await res
          }).then((res) => {
            if (res.data == 'distributeproductssuccess') {
              Swal.fire(
                'การแตกสินค้า!',
                'แตกสินค้าสำเร็จ.',
                'success'
              )
  
            }
          }).then(async() => {
            await this.LoadDataProducts()
            await this.loaddistribute()
          })
            .catch((err) => {
           //   console.log(err)
            })
  
        }
  
      })
    }
  }
}
