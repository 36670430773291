<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div id="demo">
            <h2 class="text-center"><strong>Pinsouq Store {{statusUser.billing_state}}</strong></h2>

            <small> {{statusUser.billing_address_1}}{{statusUser.billing_address_2}} อ.{{statusUser.billing_city}} จ.{{statusUser.state}} </small><br>
                        <small>Tel.{{statusUser.billing_phone}}</small>&nbsp;&nbsp;
            <small>No.{{Order.id_orderOnline}}</small> <br>
            <small>วันที่:{{Order.date_input|date:"dd-MM-yyy HH:mm"}}</small><br>
            <div></div>
            <div *ngIf="Order.phone != null">

              <small><strong>ชื่อ-สกุลลูกค้า:</strong> {{Order.first_name}}&nbsp;{{Order.last_name}}<br>
                <strong>หมายเลขโทรศัพท์:</strong> {{Order.phone}}
                <br>
                <strong>ที่อยู่:</strong> {{Order.address_1}}&nbsp;{{Order.address_2}}&nbsp;<br> <strong>อำเภอ.</strong>
                {{Order.city}}&nbsp; <strong>จังหวัด.</strong>{{newstate}}&nbsp; <strong>เลขที่ไปรษณีย์.</strong>
                {{Order.postcode}}</small>
            </div>


            <hr>
            <table class="table">
              <thead>
                <tr>
                  <th>รายการ</th>
                  <th>จำนวน</th>
                  <th>ราคา</th>
                  <th>รวม</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let items of listproduct">
                  <td><small>{{items.name}}</small></td>
                  <td><small>{{items.quantity}}</small></td>
                  <td><small>{{items.price}}</small></td>
                  <td><small>{{items.quantity * items.price |number}}</small></td>
                </tr>

              </tbody>
            </table>
            <hr>
            <table width="100%" class="table">
              <thead>
                <tr>
                  <th> รวมสุทธิ</th>
                  <th></th>
                  <th></th>
                  <th><u>{{Order.totalprice|number}}&nbsp;บาท</u> </th>
                </tr>
              </thead>

            </table>


            <hr>

            <div class="d-flex justify-content-center">
              <small>ซื้อสินค้าออนไลน์ได้ที่ &nbsp; <strong> www.pinsouqstore.com </strong> </small>
              <br>
              <small> หรือแอดไลน์&nbsp;<strong>@pinsouqstore</strong> </small>

            </div>
            <div class="d-flex justify-content-center">
              <img src="../assets/img/Line_QR.jpg" style="width: 30%;" class="brand-image img-circle elevation-3">
            </div>

          </div>



        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save</button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 col-sm-6 col-md-3">
    <div class="info-box">
      <span class="info-box-icon bg-info elevation-1"><i class="fas fa-shopping-cart"></i></span>

      <div class="info-box-content">
        <span class="info-box-text">จำนวนการสั่งซื้อวันนี้</span>
        <span class="info-box-number">
          {{OrdersTotal}}
          <small>order</small>
        </span>
      </div>
      <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
  </div>
  <!-- /.col -->
  <div class="col-12 col-sm-6 col-md-3">
    <div class="info-box mb-3">
      <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-truck text-light"></i></span>

      <div class="info-box-content">
        <span class="info-box-text">จำนวนสินค้าที่กำลังขนส่ง</span>
        <span class="info-box-number">{{orderprocessing}}</span>
      </div>
      <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
  </div>
  <!-- /.col -->

  <!-- fix for small devices only -->
  <div class="clearfix hidden-md-up"></div>

  <div class="col-12 col-sm-6 col-md-3">
    <div class="info-box mb-3">
      <span class="info-box-icon bg-success elevation-1"><i class="fab fa-shopify"></i></span>

      <div class="info-box-content">
        <span class="info-box-text">คำสั่งซื้อที่ได้รับสินค้าแล้ว</span>
        <span class="info-box-number">{{ordersuccess}}</span>
      </div>
      <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
  </div>
  <!-- /.col -->
  <div class="col-12 col-sm-6 col-md-3">
    <div class="info-box mb-3">
      <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-users"></i></span>

      <div class="info-box-content">
        <span class="info-box-text">คำสั่งซื้อที่ได้ทำการยกเลิกวันนี้</span>
        <span class="info-box-number">{{ordercancelled}}</span>
      </div>
      <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
  </div>
  <!-- /.col -->
</div>

<div>

  <div class="card card-primary">
    <div class="card-header">คำสั่งซื้อทั้งหมด</div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-3">
          <div class="input-group mb-3">
            <input type="search" class="form-control" [(ngModel)]="seacrhorder" placeholder="ค้นหาเลขคำสั่งซื้อ">
            <div class="input-group-append">
              <button class="btn btn-success" (click)="seacrhOrder()"><i class="fa fa-search"
                  aria-hidden="true"></i></button>
            </div>
          </div>
        </div>


        <div class="col-sm-3">
         <select  class="form-control" (change)="selectStatus($event)">
            <option *ngFor="let item of status" value="{{item.status}}">
              <div *ngIf="item.status == 'any'">เลือกทั้งหมด</div>
              <div *ngIf="item.status == 'on-hold'" >รอการชำระเงิน</div>
              <div *ngIf="item.status == 'panding'">รอยืนยันการชำระเงิน </div>
              <div *ngIf="item.status == 'processing'"> กำลังดำเนินการจัดส่ง </div>
              <div *ngIf="item.status == 'cancelled'">ยกเลิกการซื้อ</div>
              <div *ngIf="item.status == 'completed'">ได้รับสินค้าเรียบร้อย</div>
              <div *ngIf="item.status == 'failed'">การซื้อไม่สำเร็จ</div>
              <div *ngIf="item.status == 'refunded'">คืนเงินเรียบร้อย</div>
            </option>
          </select>
        </div>
        <hr>

        <div class="col-sm-6">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item"><a class="btn page-link" *ngIf="pageOfItems >= 2" (click)="backpages()">ก่อนหน้า</a>
              </li>
              <div *ngFor="let item of page">
                <li class="page-item"><a class="btn page-link" (click)="nextpage(item)">{{item}}</a></li>
              </div>
              <li class="page-item"><a class=" btn page-link" (click)="nextpages()">ต่อไป</a></li>
            </ul>
          </nav>
        </div>

      </div>
      <table class="table ">
        <thead>
          <tr>
            <th></th>
            <th>ลำดับที่</th>
            <th>เลขที่คำสั่งซื้อ</th>
            <th>ชื่อสกุล-ผู้สั่งซื้อ</th>
            <th>สถานะ</th>
            <th></th>
            <th>ยอดรวมทั้งสิ้น</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of orders ;let i = index">
            <td><button type="button" class="btn btn-info btn-sm" data-toggle="modal" data-target="#selectorder"
                (click)="selectorder(item)"><i class="fa fa-search" aria-hidden="true"></i></button></td>
            <td style="text-align: center;">{{i+1}}</td>
            <td class="text-info">#{{item.id}}</td>
            <td >{{item.billing.first_name}}&nbsp;{{item.billing.last_name}}</td>
            <td style="text-align:left ;">
              <div *ngIf="item.status == 'on-hold'" ><button type="button"
                  class="btn btn-warning text-light">รอการชำระเงิน</button></div>
              <div *ngIf="item.status == 'panding'" >
                <button type="button" class="btn btn-dark">รอยืนยันการชำระเงิน</button>
              </div>
              <div *ngIf="item.status == 'processing'">
                <button type="button" class="btn btn-primary" data-toggle="modal" (click)="selectorder(item)"
                  data-target="#sendproduct">กำลังดำเนินการจัดส่ง</button>
              </div>
              <div *ngIf="item.status == 'cancelled'"><button type="button"
                  class="btn btn-danger">ยกเลิกการซื้อ</button></div>
              <div *ngIf="item.status == 'completed'"><button type="button"
                  class="btn btn-success">ได้รับสินค้าเรียบร้อย</button></div>
              <div *ngIf="item.status == 'failed'"><button type="button"
                  class="btn btn-danger">การซื้อไม่สำเร็จ</button></div>
              <div *ngIf="item.status == 'refunded'"><button type="button"
                  class="btn btn-primary">คืนเงินเรียบร้อย</button></div>
            </td>
            <td>
              <div *ngFor="let items of ordersCf">
                <div *ngIf="items.order_id == item.id">
                  <button type="button" class="btn btn-sm btn-success">ออกใบเสร็จแล้ว</button>
                </div>
              </div>
            </td>
            <td style="text-align: center;">{{item.total|number:'2.2'}}</td>
            <td>{{item.date_created|date:'dd/MM/yyyy HH:mm'}}</td>
            <td *ngIf="statusUser.id == 32"><button type="button" (click)="del(item)" class="btn btn-sm btn-danger">ลบ</button></td>
          </tr>

        </tbody>

      </table>
    </div>
  </div>


</div>

<!-- Modal -->
<div class="modal fade" id="sendproduct" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">เลขที่คำสั่งซื้อ#{{Order.id}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <strong>ชื่อ-สกุลลูกค้า:</strong> {{Order.first_name}}&nbsp;{{Order.last_name}}<br>
          <strong>ที่อยู่:</strong> {{Order.address_1}}&nbsp;{{Order.address_2}}&nbsp;<br> <strong>อำเภอ.</strong>
          {{Order.city}}&nbsp; <strong>จังหวัด.</strong>{{newstate}}&nbsp; <strong>เลขที่ไปรษณีย์.</strong>
          {{Order.postcode}} <br>
          <strong>วันที่สั่งซื้อ:</strong> {{Order.date_created|date:"dd-MM-yyy HH:mm"}}
          <hr>
          <div *ngIf="Order.id_orderOnline !== null">
            <strong>เลขที่ใบเสร็จ:</strong>{{Order.id_orderOnline}}
          </div>

          <table class="table  table-inverse table-responsive">
            <thead class="thead-inverse">
              <tr>
                <th>รหัสสินค้า</th>
                <th>ชื่อ-สินค้า</th>
                <th>จำนวนสินค้า</th>
                <th>ราคาต่อหน่วย</th>
                <th>ราคารวมทั้งสิน</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of listproduct">
                <td>{{item.sku}}</td>
                <td>{{item.name}}</td>
                <td>{{item.quantity}}</td>
                <td>{{item.price|number}}</td>
                <td>{{item.total|number}}</td>
              </tr>

            </tbody>
          </table>

        </div>
        <hr>

      </div>
      <div class="modal-footer">
        <div class="d-flex justify-content-center">
          <div *ngFor="let item of ordersCf;let i = index">
            <button type="button" *ngIf="item.order_id == Order.id" printSectionId="demo" ngxPrint
              class="btn btn-success">
              <i class="fa fa-print" aria-hidden="true"></i> พิมพ์ใบเสร็จ</button>

          </div>

        </div>
        <div *ngIf="oknull == null ">
          <button type="button" class="btn btn-primary" (click)="CfOrder(Order)"><i class="fa fa-check-square"
              aria-hidden="true"></i> ยืนยันการจัดส่ง</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</div>

<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="selectorder" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">เลขที่คำสั่งซื้อ #{{Order.id}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <strong>ชื่อ-สกุลลูกค้า:</strong> {{Order.first_name}}&nbsp;{{Order.last_name}}&nbsp;
        <strong>หมายเลขโทรศัพท์:</strong> {{Order.phone}}
        <br>
        <strong>ที่อยู่:</strong> {{Order.address_1}}&nbsp;{{Order.address_2}}&nbsp; <strong>อำเภอ.</strong>
        {{Order.city}}&nbsp; <strong>จังหวัด.</strong>{{newstate}}&nbsp; <strong>เลขที่ไปรษณีย์.</strong>
        {{Order.postcode}}
        <hr>
        <div class="row">
          <div class="col-sm-6">
            <strong>วันที่สั่งซื้อ:</strong> {{Order.date_created|date:"dd-MM-yyy HH:ss"}}
          </div>
          <div *ngIf="Order.date_completed != null" class="col-sm-6">
            <strong>วันที่ได้รับสินค้า:</strong> {{Order.date_completed|date:"dd-MM-yyy HH:ss"}}
          </div>
        </div>
        <div *ngIf="Order.id_orderOnline != null">

          <strong>เลขที่ใบเสร็จ:</strong> {{Order.id_orderOnline}}

        </div>
        <table class="table  table-inverse table-responsive">
          <thead class="thead-inverse">
            <tr>
              <th>รหัสสินค้า</th>
              <th>ชื่อ-สินค้า</th>
              <th>จำนวนสินค้า</th>
              <th>ราคาต่อหน่วย</th>
              <th>ราคารวมทั้งสิน</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listproduct">
              <td>{{item.sku}}</td>
              <td>{{item.name}}</td>
              <td>{{item.quantity}}</td>
              <td>{{item.price|number}}</td>
              <td>{{item.total|number}}</td>
            </tr>

          </tbody>
        </table>

      </div>

    </div>
  </div>
</div>