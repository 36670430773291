import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ShareService } from '../ShareService'
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  managedrawer:boolean = true 
  constructor(private http: HttpClient,
    private ShareService: ShareService, 
    private router: Router,
    private Cookie: CookieService,
   ) { }
  employee:any = []
  employees:any = []
  billing:any = []
  emp:any = []
  drawers:any = []
  drawersusers:any = []
  editors:any = []
  cashier:any = []
  branchs:any = []
  role:any = null;
  branch:any = {
    id:null,
    store_name:null,
    email:null
  }
  statusUser :any = {
    status:null,
    username:null,
    token:null,
    id:null,
    email_branch:null
  }
  datadrawer:any = []
  remarkchangedrawers:any = null
  loadCookie(){
    const allCookies: {} = this.Cookie.getAll();
    
    this.statusUser = allCookies
    if ( this.statusUser.status == null || this.statusUser.username == null ) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }
    
  }
  loaddataemp(){
    if (this.statusUser.token != null) {
    
      this.http.get(this.ShareService.serverPath + '/users/alladmin' ).toPromise().then((res: any) => {
        if (res) {
          console.log(res);
          
          var filteremp  = res.filter((e:any)=>{
            return e.shipping.company ===  this.statusUser.name_branch
          }) 
          this.emp = filteremp
        }
      })
      .then(()=>{
        /*var param = { token: this.statusUser.token }
        this.http.post(this.ShareService.serverPath + '/branch/showbranch', param).subscribe((res: any) => {
          this.branchs = res
          
        })*/
      }).then(()=>{
        this.http.post(this.ShareService.serverPath + '/drawer/showdrawer',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
          this.drawers = res
          
    
        })
      }).then(()=>{
        this.http.get(this.ShareService.serverPath + '/drawer/showdrawerusers').subscribe((res: any) => {
          this.drawersusers = res
         
  
        })
      }).catch((err)=>{
        
        
      })
      
   
    
    }
  }
  alleditor(){
    this.http.post(this.ShareService.serverPath + '/users/alleditor',{data:'alleditor'} ).subscribe((res: any) => {
      if (res) {
        
        
        this.editors = res
     
        
      }
      
    })
  }
  loadbranch() {
    var param = { token: this.statusUser.token }
    this.http.post(this.ShareService.serverPath + '/branch/showbranch', param).subscribe((res: any) => {
      this.branchs = res
    
    })
  }
  allcashier(){
    if(this.cashier.length === 0){
      this.http.get(this.ShareService.serverPath + '/users/allcashier' ).subscribe((res: any) => {
        if (res) {
          var filteremp  = res.filter((e:any)=>{
            return e.shipping.company ===  this.statusUser.name_branch
          }) 
          this.cashier =filteremp
        }
      })
    }
   
  }
  ngOnInit(): void {
    this.loadCookie();
    this. loaddataemp()
    this.loadbranch()
  }
  save(){
    this.employee.company = this.branch.store_name
    this.employee.role = this.role
   
    const data = {
      email: this.employee.email,
      first_name: this.employee.first_name,
      last_name: this.employee.last_name,
      username: this.employee.username,
      roles:[this.employee.role],
      password:this.employee.password,
      billing: {
        first_name: this.employee.first_name,
        last_name: this.employee.last_name,
        company:  this.statusUser.name_branch,
        address_1: this.employee.address_1,
        address_2: this.employee.address_2,
        city: this.employee.city,
        state: this.employee.state,
        postcode: this.employee.postcode,
        country: this.employee.country,
        email: this.employee.email,
        phone: this.employee.phone
      },
      shipping: {
        first_name: this.employee.first_name,
        last_name: this.employee.last_name,
        company:   this.statusUser.name_branch,
        address_1: this.employee.address_1,
        address_2: this.employee.address_2,
        city: this.employee.city,
        state: this.employee.state,
        postcode: this.employee.postcode,
        country: this.employee.country
      },
      token : this.statusUser.token 
    };
    
    this.http.post(this.ShareService.serverPath + '/users/insertusers',data ).toPromise().then((res: any) => {
   
      return res
    }).then((res)=>{
      var param = {
        name_branch:this.statusUser.name_branch,
        ID_branch:this.statusUser.ID_branch,
        username:res.username,
        email_user:res.email,
        ID_users:res.id,
        email_branch:this.statusUser.email_branch}
      this.http.post(this.ShareService.serverPath + '/users/insertdatausers',param ).toPromise().then((res: any) => {
     
        if (res.data === 'insertsuccess') {
          Swal.fire({
            position: 'top',
            icon: 'success',
            title: 'เพิ่มข้อมบุคลากรสำเร็จ\nโปรดรอสักครู่',
            showConfirmButton: false,
            timer: 3000
          }).then(() => {
            this.employee = []
            this.loaddataemp()
            this.alleditor()
            this.allcashier()

          });
        }
        
      })
    }).catch((err)=>{

    })
    
  }
  nulldata(){
    this.employee = {
      id:null,
      role:null,
      username:null,
      email:null,
      password:null,
      address_1: null,
      address_2: '',
      city: null,
      company: null,
      country: 'TH',
      first_name: null,
      last_name: null,
      phone:null,
      postcode: null,
      state: null,
    }
  }
  selectbranchs(e:any){
   
    var filterbranchs = this.branchs.filter((res:any)=>{
      return res.id === Number(e.target.value)
    }) 
   
    this.branch.store_name = filterbranchs[0].dokan_profile_settings.store_name
    this.branch.id = filterbranchs[0].id
    this.branch.email = filterbranchs[0].email
  }
  selectroles(e:any){
    
    this.role = e.target.value
  }
  selectemp(item:any){
    this.billing = item.billing
    this.employees = item
  
    
  }
  changebranchs(e:any){
   
    var filterbranchs = this.branchs.filter((res:any)=>{
      return res.id === Number(e.target.value)
    }) 
   
    this.branch.store_name = filterbranchs[0].dokan_profile_settings.store_name
    this.branch.id = filterbranchs[0].id
    this.branch.email = filterbranchs[0].email
  }
  changebranch(item:any ){
   
    
    var data = {
      ID_branch:this.branch.id,
      name_branch:this.branch.store_name,
      email_branch:this.branch.email,
      ID_users:item.id,
      username:item.username,
      email_user:item.email,
    }
    item.billing.company = this.branch.store_name
    item.shipping.company = this.branch.store_name
    this.http.post(this.ShareService.serverPath + '/users/changebranch',data ).toPromise().then((res: any) => {
    
      return res
    }).then(()=>{
      this.http.post(this.ShareService.serverPath + '/users/changebranchs',item ).toPromise().then((res: any) => {
  
        if(res.data == "change"){
          Swal.fire({
            position: 'top',
            icon: 'success',
            title: 'กำหนดสาขาเรียบร้อย',
            showConfirmButton: false,
            timer: 3000
          })
        }
        return res
      })
    })
  }
  checkdrawer(item:any){
    var filterusers = this.drawersusers.filter((res:any)=>{
      return res.ID_user === item 
    })
    if (filterusers.length === 0 ) {
      // insert 
      this.managedrawer = true
    }
    if (filterusers.length !== 0 ) {
      // update 
      this.managedrawer = !true
      this.datadrawer = filterusers[0]
     
      var filter= this.drawers.filter((res:any)=>{
        return res.ID ===  this.datadrawer.ID_drw  
      })
      this.datadrawer.name = filter[0].name
    }
  }
  drwuser:any = null
  drawerselect(e:any){
 
  this.drwuser = e.target.value
  }
  changedrawers(item:any){
    var param = {
     ID_drw:this.drwuser,
     ID_user:this.employees.id,
     name_user:this.employees.first_name + ' ' + this.employees.last_name,
     username:this.employees.username,
     email_user:this.employees.email,
     role:this.employees.role,

    }
  
    this.http.post(this.ShareService.serverPath + '/drawer/inserttouserdrawer',param).toPromise().then((res: any) => {
    
     if(res){
      Swal.fire({
        position: 'top',
        icon: 'success',
        title: 'กำหนดการใช้ลิ้นชักเรียบบร้อย',
        showConfirmButton: false,
        timer: 3000
      })
    }

    })
  }
  changdrawersnew(){
    if (this.remarkchangedrawers === null ) {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'โปรดกรอกหมายเหตุ  ',
        showConfirmButton: false,
        timer: 3000
      })
    }else{
      
      var param = {
        ID_old:this.datadrawer.ID,
        ID_drw_old:this.datadrawer.ID_drw,
        ID_drw_new:this.drwuser,
        remark:this.remarkchangedrawers,
        ID_userchange:this.statusUser.id,
        username:this.statusUser.username,
        token:this.statusUser.token,
        date_olduse:this.datadrawer.date_update
      }
      this.http.post(this.ShareService.serverPath + '/drawer/changeuserdrawer',param).toPromise().then((res: any) => {
        
        if(res){
         Swal.fire({
           position: 'top',
           icon: 'success',
           title: 'เปลี่ยนการใช้ลิ้นชักเรียบบร้อย',
           showConfirmButton: false,
           timer: 3000
         }).then(()=>{
          this.remarkchangedrawers = null 
         })
       }
   
       })
    }
    
  }
}
