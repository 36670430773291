<div class="card card-primary">
    <div class="card-header">ข้อมูลลูกหนี้</div>
    <div class="card-body">
        <button type="button" (click)="historypaymentcredit()" data-toggle="modal" data-target="#historypaymentcredit"
            class="btn btn-info">ประวัติการชำระยอดค้างชำระ</button>
            &nbsp;
            <button type="button" (click)="historypaymentcreditsuccess()" data-toggle="modal" data-target="#historypaymentcreditsuccess"
            class="btn btn-success">ประวัติลูกหนี้ชำระครบแล้ว</button>
        <hr>
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th>ชื่อ</th>
                    <th>ร้าน</th>
                    <th>เบอร์โทร</th>
                    <th>จำนวนใบสั่งซื้อ</th>
                    <th>ยอดค้างชำระสะสม</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of customercredit">
                    <td><button type="button" data-toggle="modal" (click)="lookupallmembercredit(item)"
                            data-target="#lookupallmembercredit" class="btn btn-sm btn-primary"><i class="fa fa-search"
                                aria-hidden="true"></i></button></td>
                    <td scope="row">{{item.first_name}}&nbsp;{{item.last_name}}</td>
                    <td>{{item.company}}</td>
                    <td>{{item.phone}}</td>
                    <td>{{item.totalamount}}</td>
                    <td>{{item.totalcreditsum|number:'2.2'}}</td>
                </tr>

            </tbody>
        </table>
    </div>
</div>
<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="historypaymentcredit" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ประวัติการชำระยอดค้างชำระ</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-3">
                        <input type="search" [(ngModel)]="searchhitory" placeholder="ค้นหาข้อมูล" class="form-control"
                            name="" id="">
                    </div>
                    <div class="col-sm-3">
                        <input type="date" [(ngModel)]="searchhitory" placeholder="ค้นหาข้อมูล" class="form-control"
                            name="" id="">
                    </div>
                    
                </div>

                <div class="d-flex flex-row-reverse bd-highlight">
                    <div class="p-2 bd-highlight">
                        <pagination-controls (pageChange)="history = $event"></pagination-controls>
                    </div>
                </div>

                <table class="table mt-2">
                    <thead>
                        <tr>
                            <th>วันเดือนปี</th>
                            <th>เลขที่ใบเสร็จ</th>
                            <th>ประเภท</th>
                            <th>ยอดชำระ</th>
                            <th>บันทึกโดย</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of datahitory|filter:searchhitory| paginate: { itemsPerPage: 10, currentPage: history }">
                            <td scope="row">{{item.date_input|date:'dd/MM/yyyy'}}</td>
                            <td>#{{item.ID_order}}</td>
                            <td>
                                <div *ngIf="item.type_payment === 'bank'" class="text-info">
                                    ชำระผ่านการโอน</div>
                                <div *ngIf="item.type_payment === 'cash'" class="text-success">
                                    ชำระด้วยเงินสด</div>
                            </td>
                            <td>{{item.total_payment|number:'2.2'}}</td>
                            <td>{{item.ID_users}}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="lookupallmembercredit" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ข้อมูลการค้างค่าชำระสินค้าของสมาชิก ชื่อ :{{companycutomer}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>วัน-เดือน-ปี</th>
                            <th>เลขที่ใบเสร็จ</th>
                            <th>ยอดค้างชำระ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of customercreditdetail">
                            <td><button type="button" data-toggle="modal" data-target="#detailcreditcustomerlookup"
                                    (click)="detailcreditcustomerlookup(item)" class="btn btn-sm btn-primary"><i
                                        class="fa fa-search" aria-hidden="true"></i></button></td>
                            <td>{{item.date_creact|date:'dd/MM/yyyy'}}</td>
                            <td>#{{item.ID_order}}</td>
                            <td>{{item.total_credit|number:'2.2'}}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="detailcreditcustomerlookup" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><strong>เลขที่ใบเสร็จ:#{{orderID}}</strong> </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card ">
                            <div class="card-header"><strong>ข้อมูลสินค้า</strong></div>
                            <div class="card-body">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>รหัสสินค้า</th>
                                            <th>ชื่อสินค้า</th>
                                            <th>จำนวน</th>
                                            <th>ราคา</th>
                                            <th>ราคารวม</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of productdetail">
                                            <td>{{item.sku}}</td>
                                            <td>{{item.name}}</td>
                                            <td>{{item.quantity}}</td>
                                            <td>{{item.price|number:'2.2'}}</td>
                                            <td>{{item.quantity * item.price |number:'2.2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card ">
                            <div class="card-header"><strong>ข้อมูลประวัติการชำระ</strong></div>
                            <div class="card-body">
                                <button type="button" data-toggle="modal" data-target="#paymentcredit"
                                    class="btn btn-primary">ชำระยอดค้าง</button>
                                <hr>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>วัน-เดือน-ปี</th>
                                            <th>ประเภท</th>
                                            <th>จำนวนเงิน</th>
                                            <th>บันทึกโดย</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of creditdetail">
                                            <td>{{item.date_input|date:'dd/MM/yyyy'}}</td>
                                            <td>
                                                <div *ngIf="item.type_payment === 'bank'" class="text-info">
                                                    ชำระผ่านการโอน</div>
                                                <div *ngIf="item.type_payment === 'cash'" class="text-success">
                                                    ชำระด้วยเงินสด</div>
                                            </td>
                                            <td>{{item.total_payment|number:'2.2'}}</td>
                                            <td>{{item.ID_users}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="paymentcredit" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ชำระยอดค้าง</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-6"><strong>รูปแบบการชำระ:<strong class="text-danger">*</strong></strong></div>
                    <div class="col-sm-6">

                        <select (change)="selectpaymentcredit($event)" class="form-control" name="" id="">
                            <option>---โปรดเลือกรูปแบบการชำระ</option>
                            <option value="cash">เงินสด</option>
                            <option value="bank">โอน</option>
                        </select>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-6"><strong>จำนวนเงิน:<strong class="text-danger">*</strong></strong></div>
                    <div class="col-sm-6"><input typr="number" [(ngModel)]="paymentcerdit.total_payment"
                            placeholder="กรอก จำนวนเงิน" min="0" class="form-control"></div>
                </div>
            </div>
            <div class="modal-footer">

                <button type="button" data-dismiss="modal" (click)="savepaymentcredit()"
                    class="btn btn-success">บันทึก</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="historypaymentcreditsuccess" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ลูกหนี้ที่ชำระครบแล้ว</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="p-2 bd-highlight">
                    <pagination-controls (pageChange)="successhistory = $event"></pagination-controls>
                </div>
              <table class="table table-responsive">
                  <thead>
                      <tr>
                          <th>ลำดับที่</th>
                          <th>ชื่อ</th>
                          <th>ร้าน</th>
                          <th>เบอร์โทร</th>
                          <th>เลขที่ใบเสร็จ</th>
                          <th>ยอดรวม</th>
                          <th>วันที่ออกใบเสร็จ</th>
                          <th>วันที่ชำระยอดครบ</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of datahitorysuccess| paginate: { itemsPerPage: 10, currentPage: successhistory };let i=index">
                            <td>{{i+1}}</td>  
                        <td scope="row">{{item.first_name}}&nbsp;{{item.last_name}}</td>
                          <td>{{item.company}}</td>
                          <td>{{item.phone}}</td>
                          <td>#{{item.ID_order}}</td>
                          <td>{{item.totalprice|number:'2.2'}}</td>
                          <td class="text-info">{{item.date_update|date:'dd/MM/yyyy HH:mm'}}</td>
                          <td class="text-success">{{item.date_creact|date:'dd/MM/yyyy HH:mm'}}</td>
                      </tr>
                     
                  </tbody>
              </table>
              
            </div>
            <div class="modal-footer">

               
            </div>
        </div>
    </div>
</div>