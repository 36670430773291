<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">

    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>

<body>
   
    <ngx-loading-bar color="#2471A3 " height="7px" ></ngx-loading-bar>
    <app-menuadmin></app-menuadmin>
    
    
    <div class="content">
       
        <div class="container-fluid mt-2">
            <router-outlet></router-outlet>
        </div>
    </div>
</body>


</html>