<div class="container" >
    <div class="row justify-content-center align-items-center" style="height:95vh">
        <div class="col-4">
            <div class="card">
                <br>
                <div class="d-flex justify-content-around"> <img src="assets/img/AdminLTELogo.png" alt="AdminLTE Logo" style='width:100px' class="brand-image img-circle elevation-3 "></div>
                <div class="card-body">
                   
                    <div class="d-flex justify-content-around"> <h5>Login Pos-system-pinsouq</h5></div>
                    <form action="" (ngSubmit)="logins()" autocomplete="off">
                        <div class="form-group">
                            <input type="text" class="form-control" [(ngModel)]="username" placeholder="โปรดกรอกชื่อผู้ใช้" name="username">
                        </div>
                        <div class="form-group">
                            <input type="password" class="form-control" [(ngModel)]="password" placeholder="โปรดกรอกรหัสผ่าน" name="password">
                        </div>
                        <div class="d-flex justify-content-around"><button type="submit"   id="sendlogin" class="btn btn-primary">เข้าสู่ระบบ</button></div>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>