import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ShareService } from '../ShareService'
import { Auth } from "../auth";
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { api } from '../api';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  [x: string]: any;

  username: string = ''
  password: string = ''

  constructor(private http: HttpClient,
    private ShareService: ShareService,
    private router: Router,
    private Cookie: CookieService,
    private auth: Auth,
    private api: api) {

  }

  ngOnInit(): void {
    this.chack()
  }
  user: any = {
    token: null,
    id: null,
    status: null
  }
  data: any = {
    id: null
  }
  chack() {
    var check: boolean = this.Cookie.check('token')
  
    if (check == true) {
      this.router.navigateByUrl('/Dashboard')
    }
  }
async  logins() {
    if (this.username == '' && this.password == '') {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'โปรดกรอกชื่อผู้ใช้หรือรหัสผ่าน\nของคุณด้วยค่ะ',
        showConfirmButton: false,
        timer: 3000
      }).then(() => {
        this.router.navigate(['/', 'login']);
      });
    } else {
      var param = {
        username: this.username,
        password: this.password

      }
     await this.http.post(this.auth.serverPath, param).toPromise().then((res: any) => {
        if (res) {
          this.Cookie.set('username', res.user_email);
          this.Cookie.set('name', res.user_display_name);
          this.Cookie.set('token', res.token)
          this.Cookie.set('email', res.user_email)
          this.user = res
        } return res
      }).then(async() => {
        await this.http.post(this.ShareService.serverPath + '/login/login', { token: this.user.token }).toPromise().then((res: any) => {
          if (res) {
            //this.cookieService.set( 'user', JSON.stringify(result))
            this.Cookie.set('id', res.id)
            this.Cookie.set('status', res.roles[0])
            this.user.status = res.roles[0]
            this.user = res
          }
        }).then(async() => {
          //lookupdrawer
          await  this.http.post(this.ShareService.serverPath + '/login/lookupdrawer', { iduser: this.user.id }).toPromise().then((res: any) => {
            if (res.data !== "nodata") {
              //this.cookieService.set( 'user', JSON.stringify(result))
              this.Cookie.set('ID_drw', res.ID_drw)
              this.Cookie.set('ID_formdrwusr', res.ID)
            } 
          })
        })

      })
        .then(async() => {
          await  this.http.post(this.ShareService.serverPath + '/login/lookupbranch', this.user).toPromise().then((res: any) => {
         
              console.log(res);
              
            if (res.status === '1') {
              this.Cookie.set('ID_branch', res.ID_branch)
              this.Cookie.set('name_branch', res.name_branch)
              this.Cookie.set('email_branch', res.email_branch)
              this.Cookie.set('billing_address_1', res.billing_address_1)
              this.Cookie.set('billing_address_2', res.billing_address_2)
              this.Cookie.set('billing_city', res.billing_city)
              this.Cookie.set('billing_company', res.billing_company)
              this.Cookie.set('billing_country', res.billing_country)
              this.Cookie.set('billing_email', res.email_branch)
              this.Cookie.set('billing_phone', res.billing_phone)
              this.Cookie.set('billing_postcode', res.billing_postcode)
              this.Cookie.set('billing_state', res.billing_state)
              this.data = res
            }else         if (res.data === "Noconectdata") {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'ชื่อผู้ใช้หรือรหัสผ่าน\nของคุณไม่ถูกต้อง\nโปรดลองอีกครั้ง',
                showConfirmButton: false,
                timer: 3000
              }).then(() => {
                this.router.navigate(['/', 'login']);
              })
            }  
            
            return res
          }).then((res) => {
            if (res.status === '1') {
              Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'กำลังเข้าสู่ระบบ \nโปรดรอสักครู่....',
                showConfirmButton: false,
                timer: 3000
              }).then(() => {
                location.href = '/Dashboard'
              })
            } else {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'ชื่อผู้ใช้หรือรหัสผ่าน\nของคุณไม่ถูกต้อง\nโปรดลองอีกครั้ง',
                showConfirmButton: false,
                timer: 3000
              }).then(() => {
                this.router.navigate(['/', 'login']);
              });
            }
          })
            .catch((err) => {


              if (err) {

                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: 'ชื่อผู้ใช้หรือรหัสผ่าน\nของคุณไม่ถูกต้อง\nโปรดลองอีกครั้ง',
                  showConfirmButton: false,
                  timer: 3000
                }).then(() => {
                  this.router.navigate(['/', 'login']);
                });
              }
            })

        })

        .catch((err) => {

          if (err.status === 403) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'ชื่อผู้ใช้หรือรหัสผ่าน\nของคุณไม่ถูกต้อง\nโปรดลองอีกครั้ง',
              showConfirmButton: false,
              timer: 3000
            }).then(() => {
              this.router.navigate(['/', 'login']);
            });
          }
        });
    }



  }



}

