import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ShareService } from '../ShareService'
import Swal from 'sweetalert2';
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService) { }
  accounts: any = null
  IDacc: any = null
  Nameacc: any = null
  totalpricetoday: number = 0
  buyforbanktoday: number = 0
  buyforcard1: number = 0
  buyforcard2: number = 0

  revenuedata: number = 0
  expendituredata: number = 0
  drawers: any = []
  datalistaccounts: any = []
  p: any = 1
  datalist: any = []
  search: any = null
  cashincome: number = 0
  transferincome: number = 0
  cashexpenditure: any = 0
  transferexpenditure: number = 0
  ngOnInit(): void {
    this.loadCookie();
    this.loadaccount();

  }
  dataapprove: any = []
  manageapprove: boolean = true
  statusUser: any = {
    status: null,
    username: null,
    id: null,
    name: null,
    ID_branch: null,
    ID_drw:null
  }
  listacc: any = {
    ID_drw: null,
    ID_branch: null,
    ID_acc: null,
    name_list: null,
    detail_list: null,
    totalprice: null,
    type_list: null,
    status: 1,
    name_users: null,
    ID:null
  }
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }
  loadaccount() {
    this.http.post(this.ShareService.serverPath + '/account/showaccountbrancch', { ID_branch: this.statusUser.ID_branch }).toPromise().then((res: any) => {

      if (res.length !== 0) {
        this.accounts = res
        
        this.IDacc = res[0].ID
        this.Nameacc = res[0].name_acc
      }
    })
      .then(() => {
        this.http.post(this.ShareService.serverPath + '/drawer/selecrdrawer', { acc: this.accounts[0], user: this.statusUser }).subscribe((res: any) => {

          if (res.length !== 0) {
           
            this.drawers = res
          } 
        })
      }).then(() => {
        this.http.post(this.ShareService.serverPath + '/sale/totalpricetoday', { ID_branch: this.statusUser.ID_branch }).subscribe((res: any) => {
      
          this.totalpricetoday = res[0].total
          if (this.totalpricetoday === null) {
            this.totalpricetoday = 0
          }
        })
      }).then(() => {
        this.http.post(this.ShareService.serverPath + '/sale/buyforbanktoday', { ID_branch: this.statusUser.ID_branch }).subscribe((res: any) => {
        
          this.buyforbanktoday = res[0].total
          if (this.buyforbanktoday === null) {
            this.buyforbanktoday = 0
          }
        })
      }).then(() => {
        this.http.post(this.ShareService.serverPath + '/account/datalistaccounttoday', { ID_branch: this.statusUser.ID_branch }).subscribe((res: any) => {
     
          this.datalistaccounts = res
        })
      }).then(() => {
        this.http.post(this.ShareService.serverPath + '/account/cashincome', { ID_branch: this.statusUser.ID_branch }).subscribe((res: any) => {
          //ข้อมูลรายรับสด
        
          this.cashincome = res[0].cashincome
  
          if (res[0].cashincome == null) {
            this.cashincome = 0

          }
        


        })
      }).then(() => {
        this.http.post(this.ShareService.serverPath + '/account/transferincome', { ID_branch: this.statusUser.ID_branch }).toPromise().then((res: any) => {
          //ข้อมูลรายรับโอน
        
          this.transferincome = res[0].transferincome
          if (res[0].transferincome === null) {
            this.transferincome = 0

          }
        
        }).then(() => {
          const income = this.cashincome + this.transferincome;
         
          this.revenuedata = income
        })
      }).then(() => {
        this.http.post(this.ShareService.serverPath + '/account/cashexpenditure', { ID_branch: this.statusUser.ID_branch }).toPromise().then((res: any) => {
          //รายจ่ายเงินสด
        
          this.cashexpenditure = res[0].cashexpenditure
          if (res[0].cashexpenditure === null) {
            this.cashexpenditure = 0

          }
        })
      }).then(() => {
        this.http.post(this.ShareService.serverPath + '/account/transferexpenditure', { ID_branch: this.statusUser.ID_branch }).toPromise().then((res: any) => {
          //รายจ่ายเงินโอน
          console.log(res);
          
          this.transferexpenditure = res[0].transferexpenditure
          if (res[0].transferexpenditure === null) {
            this.transferexpenditure = 0

          }
        })

      }).then(()=>{
        this.listacc.ID_drw = this.statusUser.ID_drw
      })
  }

  savelist() {
    this.listacc.ID_acc = this.IDacc
    this.listacc.ID_branch = this.statusUser.ID_branch
    this.listacc.name_users = this.statusUser.name
    if (this.listacc.detail_list === null && this.listacc.detail_list ===  '') {
      this.listacc.detail_list = 'ไม่มีข้อมูล'
    }
    console.log();
    
    if( this.listacc.type_list === '3'   ){
      this.listacc.ID_drw = '001'
    }else if(  this.listacc.type_list === '4'   ){
      this.listacc.ID_drw = '002'
    }else{
      this.listacc.ID_drw = this.statusUser.ID_drw
    }
    if(this.listacc.ID_drw !== null && this.listacc.totalprice !== null ){
      this.http.post(this.ShareService.serverPath + '/account/insertlistaccount', this.listacc).toPromise().then((res: any) => {
        if (res) {
          Swal.fire({
            position: 'top',
            icon: 'success',
            title: 'เพิ่มรายการเรียบร้อย \nโปรดรอสักครู่',
            showConfirmButton: false,
            timer: 3000
          }).then(() => {
            this.loadaccount()
            this.nulldata()
          })
        }
      }).catch((err) => {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'เพิ่มรายการไม่สำเร็จ \nโปรดรอลองใหม่ภายหลัง',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          this.loadaccount()
  
        })
      })
    }else{
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'เพิ่มรายการไม่สำเร็จ \nโปรดลองใหม่อีกครั้ง',
        showConfirmButton: false,
        timer: 3000
      }).then(()=>{
        this.listacc.ID_drw = null 
        this.listacc.ID = null
      })
    }
   
  }
  selectdrawer(e: any) {
    this.listacc.ID_drw = e.target.value

  }
  selecttypelist(e: any) {
    this.listacc.type_list = e.target.value
  }
  lookuplist(item: any) {
 
    this.datalist = item
    this.http.post(this.ShareService.serverPath + '/account/lookupdataapprove', item).toPromise().then((res: any) => {
      if (res) {
        

        if (res.length === 0) {
          this.manageapprove = true
        }
        if (res.length !== 0) {
          this.manageapprove = !true
          this.dataapprove = res[0]
        }
      }
    })
  }
  approve: any = null
  selectapprove(e: any) {

    this.approve = e.target.value
  }
  approvesave() {
    var param = {
      ID_acclist: this.datalist.ID,
      approve: this.approve,
      name_approve: this.statusUser.name,
      email_approve: this.statusUser.email,
      username_approve: this.statusUser.username,
      ID_branch_approve: this.statusUser.ID_branch
    }
    this.http.post(this.ShareService.serverPath + '/account/insertapprove', param).toPromise().then((res: any) => {
      if (res) {
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'บันทึกข้อมูลเรียบร้อย \nโปรดรอสักครู่',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          this.loadaccount()
          this.nulldata()
        })
      }
    })
  }
  nulldata() {
    this.listacc = {
      ID_drw: null,
      ID_branch: null,
      ID_acc: null,
      name_list: null,
      detail_list: null,
      totalprice: null,
      type_list: null,
      status: 1,
      name_users: null,
      ID:null
    }
  }
  approveagein(item: any) {
    if (item) {
      Swal.fire({
        title: 'ตัดสินใจได้แล้วใช่ไหม?',
        text: "คุณแน่ใจแล้วหรือยังที่\n จะดำเนินการรายการนี้?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: ' ยืนยัน'
      }).then((result: any) => {
        if (result.isConfirmed) {
       
          this.manageapprove = true


        } else if (result.isDismissed) {
          this.manageapprove = !true
        }

      })
    }
  }
  editlist(item: any) {
 
    this.listacc = item
  }
  saveeditlist() {
    if( this.listacc.type_list === '3'   ){
      this.listacc.ID_drw = '001'
    }else if(  this.listacc.type_list === '4'   ){
      this.listacc.ID_drw = '002'
    }else{
      this.listacc.ID_drw = this.statusUser.ID_drw
    }
    var param = {
      ID: this.listacc.ID,
      ID_acc: this.listacc.ID_acc,
      ID_drw: this.listacc.ID_drw,
      detail_list: this.listacc.detail_list,
      name_list: this.listacc.name_list,
      name_users: this.listacc.name_users,
      totalprice: this.listacc.totalprice,
      type_list: this.listacc.type_list,
    }

      this.http.post(this.ShareService.serverPath + '/account/editaccountlist', param).toPromise().then((res: any) => {
        return res
      }).then((res) => {
        if (res) {
          var newdata = param 
        var olddata = this.listacc 
        var data = {newdata,olddata }
        this.http.post(this.ShareService.serverPath + '/account/accountlisthisotory', data).toPromise().then((res: any) => {
          if (res.data == 'ok') {
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'แก้ไขข้อมูลเรียบร้อย \nโปรดรอสักครู่',
              showConfirmButton: false,
              timer: 3000
            }).then(()=>{
              this.loadaccount()
              this.nulldata()
            })
          }
        }) 
        }
       
      })

    
  }
  selecttypedate(e:any){
   
    if(e.target.value === 'day'){
      this.http.post(this.ShareService.serverPath + '/account/accountlisttoday',{ ID_branch: this.statusUser.ID_branch } ).toPromise().then((res: any) => {
       this.datalistaccounts = res
      }) 
    }else if(e.target.value === 'week'){
      this.http.post(this.ShareService.serverPath + '/account/accountlisttoweek', { ID_branch: this.statusUser.ID_branch }).toPromise().then((res: any) => {
        this.datalistaccounts = res
      }) 
    }else  if(e.target.value === 'month'){
      this.http.post(this.ShareService.serverPath + '/account/accountlisttomonth', { ID_branch: this.statusUser.ID_branch }).toPromise().then((res: any) => {
        this.datalistaccounts = res
      }) 
    }/*else if(e.target.value === 'year'){
      this.http.post(this.ShareService.serverPath + '/account/accountlisttoyear',{ ID_branch: this.statusUser.ID_branch } ).toPromise().then((res: any) => {
        this.datalistaccounts = res
      }) 
    }*/
  }
  statuslist:any = null
  selectstatus(e:any){

    this.statuslist = e.target.value
  }
  searchlistaccount(){
    console.log(this.search)
    this.http.post(this.ShareService.serverPath + '/account/searchlistaccount',{ data:this.search } ).toPromise().then((res: any) => {
     if(res.data !== 'nohavedata'){
      this.datalistaccounts = res
      this.search = null 
     }else{
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'ไม่มีข้อมูล',
        showConfirmButton: false,
        timer: 3000
      })
      this.search = null 
     }

      
    }) 
  }
}