<div class="container">
    <div class="d-flex justify-content-center">
        <div class="row">
    
            <div class="col-sm-12">
                <label for="">รูปแบบ</label>
                <select name="" id="" class="form-control">
                    <option value="">---โปรดเลือกรูปแบบ---</option>
                    <option value="">การนำเข้าบุคลากร</option>
                    <option value="">บุคลากรที่ออกไปแล้ว</option>
                    <option value="">บุคลากรซื้อสินค้ามากที่สุด</option>
                    <option value="">บุคลากรที่มียอดขายเยอะที่สุด</option>
                    <option value="">....</option>
                </select>
            </div>
    
            <br>
    
    
            <div class="col-sm-6">
                <label for="">จากวันที่</label>
                <input type="date" class="form-control">
            </div>
            <div class="col-sm-6">
                <label for="">ถึงวันที่</label>
                <input type="date" class="form-control">
            </div>
    
    
        </div>
    
    </div>
    <hr>
    <table class="table table-bordered table-inverse table-responsive">
        <thead class="thead-inverse">
            <tr>
                <th>ลำดับที่</th>
                <th>ชื่อ-สกุล</th>
                <th>เบอร์โทรศัพท์</th>
                <th>email</th>
                <th>วัน-เดือน-ปี</th>
               
            </tr>
        </thead>
        <tbody>
            <tr>
                <td scope="row"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
    
        </tbody>
    </table>
    <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-info"><i class="fa fa-print" aria-hidden="true"></i>
            พิมพ์ข้อมูล</button>
    </div>
</div>
