import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShareService } from '../../ShareService';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-successbuy',
  templateUrl: './successbuy.component.html',
  styleUrls: ['./successbuy.component.css']
})
export class SuccessbuyComponent implements OnInit {

  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService) { }
  listOrder: any = []
  stateTH:any = []
  Detail = {
   ID: null,
changemoney: null,
date_input: null,
discount: null,
getmoney: null,
id_coupon: null,
id_customer: null,
payment:null,
totalprice: null,
totalquantity: null,
users:null
  }
  billing = {
    address_1: null,
    address_2: null,
    city: null,
    company: null,
    country: null,
    email: null,
    first_name: null,
    last_name: null,
    phone: null,
    postcode: null,
    state: null,
    member_id:null
  }
  statusUser: any = {
    username: null,
    name: null,
    token: null,
    email: null,
    id: null,
    status: null,
    billing_state:null,
    billing_address_1:null,
    billing_address_2:null,
    billing_city:null,
    billing_postcode:null,
    billing_phone:null,
    stete:null,
    ID_branch:null,
    name_branch:null
  }
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
   
    this.statusUser = allCookies
 
    if (this.statusUser.status == null || this.statusUser.username == null || this.statusUser.token == null) {

      location.href = '/login'
    }
  }
  ngOnInit(): void {
     this.loadstateEn()
     this.loadth()
    this.loadCookie()
    this.loadfirstOrder();
  }
  loadfirstOrder() {
   
    this.http.post(this.ShareService.serverPath + '/order/firstOrder',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
   
      this.listOrder = res[1].detail
      this.Detail = res[0].order[0]
      if (res[0].order[0].id_customer != 0) {
        var param = {id:res[0].order[0].id_customer}
        this.http.post(this.ShareService.serverPath + '/customer/selectcustomer',param).subscribe((res: any) => {
        
          this.billing = res[0]
          if (res[0].first_name == "") {
            this.billing.first_name = res[0].company
          }
          this.billing.address_1 = res[0].address
          if(res[0].state ! == ""){
            var filterstate  = this.stateTH.filter((e:any)=>{
              return e.id == res[0].state 
            })
            this.billing.state = filterstate[0].state
          }
        })
        
      }

    })
      
     
  }
  loadstateEn(){
    this.http.get(this.ShareService.serverPath + '/product/stateEn').toPromise().then((res: any) => {
  
      var filterstate  = res.filter((e:any)=>{
        return e.id === this.statusUser.billing_state
      })
      
      return filterstate
       
    }).then((filterstate)=>{
  
      this.statusUser.billing_state = filterstate[0].state
      this.http.get(this.ShareService.serverPath + '/product/state').subscribe((res: any) => {
         var filterstates = res.filter((e:any)=>{
          return e.id === filterstate[0].id
        })
        this.statusUser.state = filterstates[0].state
      
          
      })  
    })
  }
  loadth(){
    this.http.get(this.ShareService.serverPath + '/product/state').subscribe((res: any) => {
      
     this.stateTH = res
        
    })  
  }
  goto(){
    location.href ="/forntsales"
  }
}
