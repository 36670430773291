<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div id="demo">
                        <h2 class="text-center"><strong>{{statusUser.name_branch}}</strong></h2>

                        <small> {{statusUser.billing_address_1}}{{statusUser.billing_address_2}} อ.{{statusUser.billing_city}} จ.{{statusUser.state}} </small><br>
                        <small>Tel.{{statusUser.billing_phone}}</small>&nbsp;&nbsp;
                        <small>No.{{Detail.ID}}</small> <br>
                        <small>วันที่:{{Detail.date_input|date:"dd-MM-yyy"}}</small><br>
                        <div></div>
                        <div *ngIf="billing.member_id != null">
                            ชื่อลูกค้า {{billing.first_name}}&nbsp;{{billing.last_name}} ( {{billing.phone}} ) <br>
                            ที่อยู่ {{billing.address_1}}&nbsp;{{billing.address_2}}&nbsp;{{billing.city}} &nbsp;{{billing.state}} &nbsp;
                            {{billing.postcode}}
                        </div>


                        <hr>
                        <table id="t01" class="table">
                            <thead>
                                <tr>
                                    <th>รายการ</th>
                                    <th>จำนวน</th>
                                    <th>ราคา</th>
                                    <th>รวม</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let items of listOrder">
                                    <td><small>{{items.name}}</small></td>
                                    <td style="text-align: right;"><small>{{items.quantity}}</small></td>
                                    <td><small>{{items.price}}</small></td>
                                    <td><small>{{items.quantity * items.price |number}}</small></td>
                                </tr>

                            </tbody>
                        </table>
                        <hr>
                        <table width="100%" class="table">
                            <thead>
                                <tr>
                                    <th> รวมสุทธิ</th>
                                    <th></th>
                                    <th></th>
                                    <th><u>{{Detail.totalprice|number}}&nbsp;บาท</u> </th>
                                </tr>
                            </thead>

                        </table>

                       <!-- <div *ngIf="Detail.payment == 'credit'">

                        <hr>
                        <small>
                            กรุณาโอนเงินเข้าบัญชี
                        </small> <br>
                        <small>
                            พร้อมเพย์: 0955561000243
                        </small><br>
                        <small>
                            บริษัท แฮส ออเดอร์ จำกัด
                        </small>
                    </div>-->
                        <hr>
                        <div class="text-center" *ngIf="Detail.payment !== 'credit'">
                            รับเงิน: {{Detail.getmoney|number}} |เงินทอน: {{Detail.changemoney|number}} <br>
                            ผู้รับเงิน:{{Detail.users}}|ขอบคุณค่ะ/ครับ
                        </div>
                        <div class="text-center" *ngIf="Detail.payment === 'credit'">
                            รับเงิน: {{Detail.getmoney|number}} |ยอดค้างชำระ: {{Detail.changemoney|number}} <br>
                            ผู้รับเงิน:{{Detail.users}}|ขอบคุณค่ะ/ครับ
                        </div>
                        <div class="d-flex justify-content-center">
                           <small>ซื้อสินค้าออนไลน์ได้ที่ &nbsp; <strong> www.pinsouqstore.com </strong> </small> 
                            <br>
                            <small> หรือแอดไลน์&nbsp;<strong>@pinsouqstore</strong> </small>
                          
                        </div>
                        <div class="d-flex justify-content-center">
                            <img src="../assets/img/Line_QR.jpg" style="width: 30%;" class="brand-image img-circle elevation-3">
                        </div>

                    </div>
                   
                    
                
                </div>
                
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="container-lg" >
    <div class="card card-primary">
        <div class="card-header">
            <h2 class="text-center"><strong>{{statusUser.name_branch}}</strong></h2>
            <div class="d-flex justify-content-center">
                <h4 class="card-title text-center">ขอบคุณที่ใช้บริการนะคะ</h4>
            </div>
        </div>
        
        <div class="card-body">
            <h3>เลขที่ใบเสร็จ#{{Detail.ID}}</h3>
            <div>
                <div class="row ">
                    <div class="col-sm-6">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>รายการ</th>
                                    <th>จำนวน</th>
                                    <th>ราคา</th>
                                    <th>รวม</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of listOrder">
                                 <td>{{item.name}}</td>
                                 <td>{{item.quantity|number}}</td>
                                 <td>{{item.price|number}}</td>
                                 <td>{{item.quantity * item.price |number }} </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="col-sm-6">
                        <hr>
                        <div class="row">
                           
                            <div class="col-sm-6">
                                <h2>ราคารวมสุทธิ:</h2>
                            </div>
                            <div class="col-sm-6">
                                <h2 class="text-between"> <u> {{Detail.totalprice|number}}&nbsp;บาท</u>  </h2>
                            </div>
                          
                        </div>
                        <hr>
                        <hr>
                        <div class="row">
                         
                            <div class="col-sm-6">
                                <h2>รับเงิน:</h2>
                            </div>
                            <div class="col-sm-6">
                                <h2  class="text-between"> <u> {{Detail.getmoney|number}}&nbsp;บาท</u>  </h2>
                            </div>
                         
                        </div>
                        <hr>
                        <hr>
                        <div class="row">
                           
                            <div class="col-sm-6" *ngIf="Detail.payment !== 'credit'">
                                <h2>เงินทอน:</h2>
                            </div>
                            <div class="col-sm-6" *ngIf="Detail.payment == 'credit'">
                                <h2>ยอดค้างชำระ:</h2>
                            </div>
                            <div class="col-sm-6">
                                <h2> <u> {{Detail.changemoney |number }}&nbsp;บาท</u>  </h2>
                            </div>
                           
                        </div>
                        <hr>
                        <div class="card-footer">
                            <div class="d-flex justify-content-center"><button type="button"
                                    class="btn btn-warning"  printSectionId="demo" ngxPrint> <i class="fa fa-print" aria-hidden="true"></i> พิมพ์ใบเสร็จ</button>&nbsp;&nbsp;&nbsp;<button type="button"
                                    class="btn btn-success" (click)="goto()" > <i class="fa fa-check-square" aria-hidden="true"></i> เสร็จสิ้น</button></div>
                        </div>
                    </div>
                </div>
                
            </div>



        </div>
    </div>
</div>