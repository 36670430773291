<div class="row">
    <div class="col-sm-2">
        <div class="card card-info">
            <div class="card-header"><strong>ยอดรวมสุทธิ(บาท)</strong> </div>
            <div class="card-body">
                <strong>{{totalpricetoday|number:'2.2'}}฿</strong>

            </div>
        </div>
    </div>
    <div class="col-sm-2">
        <div class="card card-info">
            <div class="card-header"><strong>จ่ายผ่านบัตร</strong> </div>
            <div class="card-body">
                <strong>{{buyforcard1|number:'2.2'}}฿</strong>
            </div>
        </div>
    </div>
    <div class="col-sm-2">
        <div class="card card-info">
            <div class="card-header"><strong>ยอดจ่ายผ่านการโอน</strong> </div>
            <div class="card-body">
                <strong>{{buyforbanktoday|number:'2.2'}}฿</strong>
            </div>
        </div>
    </div>
    <div class="col-sm-2">
        <div class="card card-info">
            <div class="card-header"><strong>บัตรรัฐ</strong> </div>
            <div class="card-body">
                <strong>{{buyforcard2|number:'2.2'}}฿</strong>
            </div>
        </div>
    </div>
    <div class="col-sm-2">
        <div class="card card-success">
            <div class="card-header"><strong>รายรับสด|รายรับโอน</strong> </div>
            <div class="card-body">
                <strong>{{ cashincome  |number:'2.2'}}฿ | {{transferincome |number:'2.2'}}</strong>
            </div>
        </div>
    </div>
    <div class="col-sm-2">
        <div class="card card-danger">
            <div class="card-header"><strong>รายจ่ายสด|รายจ่ายโอน</strong> </div>
            <div class="card-body">
                <strong>{{ cashexpenditure  |number:'2.2'}}฿ |{{transferexpenditure|number:'2.2'}}</strong>
            </div>
        </div>
    </div>

</div>
<div class="alert alert-default-primary">
    <div class="row">
        <div class="col-sm-6">
            <div class="d-flex justify-content-center">
                <h3> <strong>เงินสดคงเหลือ</strong> </h3>

            </div>
        </div>
        <div class="col-sm-30">
            <div class="d-flex justify-content-center">
                <h3>{{(totalpricetoday  + cashincome) - cashexpenditure
                    |number:'2.2'}}฿</h3>
                &nbsp;

            </div>
        </div>
    </div>


</div>
<div class="card card-primary">
    <div class="card-header"><strong>บัญชี เลขที่</strong><strong>-{{IDacc}}-<small>{{Nameacc}}</small></strong> </div>
    <div class="card-body">
        <button class="btn btn-primary" data-toggle="modal" data-target="#AddList">เพิ่มรายการ</button>
        <div class="d-flex flex-row-reverse bd-highlight">
          <!--   <div class="p-2 bd-highlight">
               <select  class="form-control" (change)="selectstatus($event)" name="" id="">
                    <option value="">---สถานะรายการ---</option>
                    <option value="1">อนุมัติ</option>
                   <option value="4" >ไม่อนุมัติ</option>
                  <option value="2" >รอดำเนินการ</option>
                  <option value="3">รออนุมัติ</option>
                </select>
           </div>-->
            <div class="p-2 bd-highlight">
                 <select  class="form-control" name="" (change)="selecttypedate($event)" id="">
                     <option value="day">รายวัน</option>
                    <option value="week" >รายสัปดาห์</option>
                   <option value="month" >รายเดือน</option>
                
                 </select>
            </div>
            <div class="p-2 bd-highlight"><input type="search" (change)="searchlistaccount()" [(ngModel)]="search" placeholder="ค้นหา รายการ"
                    class="form-control" name="" id=""></div>
            <div class="p-2 bd-highlight">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>

        </div>

        &nbsp;
        <table class="table">
            <thead class="thead-inverse">
                <tr>
                    <th></th>
                    <th>วันที่-เวลา</th>
                    <th>ชื่อรายการ</th>
                    <th>ประเภท</th>
                    <th>ลิ้นชัก(ID)</th>
                    <th>สถานะ</th>
                    <th>บันทึกโดย</th>


                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let item of datalistaccounts|filter:search |paginate: { itemsPerPage: 10, currentPage: p };let i=index">
                    <td ><button type="button" data-toggle="modal" (click)="lookuplist(item)" data-target="#lookupacc"
                            class="btn btn-sm btn-info"><i class="fas fa-file"></i></button></td>
                    <td>{{item.date_input|date:'dd/MM/yyyy'}}</td>
                    <td>{{item.name_list}}</td>
                    <td>
                        <div *ngIf="item.type_list === '1'" class="text-success">รายรับ-สด &nbsp; <strong
                                class="text-dark">{{item.totalprice|number:'2.2'}} ฿ </strong> </div>
                        <div *ngIf="item.type_list === '2'" class="text-danger">รายจ่าย-สด &nbsp; <strong
                                class="text-dark">{{item.totalprice|number:'2.2'}} ฿ </strong> </div>
                        <div *ngIf="item.type_list === '3'" class="text-success">รายรับ-โอน &nbsp;<strong
                                class="text-dark">{{item.totalprice|number:'2.2'}} ฿ </strong></div>
                        <div *ngIf="item.type_list === '4' " class="text-danger">รายจ่าย-โอน &nbsp;<strong
                                class="text-dark">{{item.totalprice|number:'2.2'}} ฿ </strong> </div>

                    </td>
                    <td>
                        <div *ngIf="item.ID_drw === 1 || item.ID_drw === 2 " class="text-info" >ผ่านการโอน</div>
                        <div *ngIf=" item.ID_drw > 2 " >{{item.ID_drw}}</div>
                    </td>
                    <td>
                        <div *ngIf="item.status === '1'" class="text-warning">รอดำเนินการ</div>
                        <div *ngIf="item.status === '2'" class="text-info">รออนุมัติ</div>
                        <div *ngIf="item.status === '3'" class="text-success">อนุมัติ</div>
                        <div *ngIf="item.status === '4' " class="text-danger">ไม่อนุมัติ</div>

                    </td>
                    <td>{{item.name_users}}</td>
                    <td><button type="button" *ngIf="item.status === '1'" class="btn btn-sm btn-warning" data-toggle="modal" data-target="#AddList"
                            (click)="editlist(item)"><small><i class="fa fa-pen"
                                    aria-hidden="true"></i></small></button></td>

                </tr>

            </tbody>
        </table>
        <div class="d-flex flex-row-reverse bd-highlight">
            <div class="p-2 bd-highlight">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>
    </div>
</div>



<!-- Modal -->
<div class="modal fade" id="AddList" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="listacc.ID === null">เพิ่มรายการ</h5>
                <h5 class="modal-title" *ngIf="listacc.ID !== null">แก้ไขรายการ</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="contrainer">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">ชื่อรายการ:</label>
                            <input type="text" [(ngModel)]="listacc.name_list" placeholder="กรอก ชื่อรายการ"
                                class="form-control">
                        </div>
                        <div class="col-sm-6">
                            <label for="">ประเภท:</label>
                            <select name="" class="form-control" (change)="selecttypelist($event)" id="">
                               
                                <option *ngIf="listacc.type_list !== null " value="listacc.type_list">
                                    <div *ngIf="listacc.type_list == '1'">รายรับ-สด</div>
                                    <div *ngIf="listacc.type_list == '2'">รายจ่าย-สด</div>
                                    <div *ngIf="listacc.type_list == '3'">รายรับ-โอน</div>
                                    <div *ngIf="listacc.type_list == '4'">รายจ่าย-โอน</div>

                                </option>
                                <option *ngIf="listacc.type_list === null " value="">---โปรดเลือกประเภท---</option>
                                <option *ngIf="listacc.type_list !== '1'" value="1">รายรับ-สด</option>
                                <option *ngIf="listacc.type_list !== '2'" value="2">รายจ่าย-สด</option>
                                <option *ngIf="listacc.type_list !== '3'" value="3">รายรับ-โอน</option>
                                <option *ngIf="listacc.type_list !== '4'" value="4">รายจ่าย-โอน</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <label for="">รายละเอียด:</label>
                            <ckeditor [(ngModel)]="listacc.detail_list"></ckeditor>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">ลิ้นชัก(ID):</label>
                           <h3 *ngIf="listacc.ID_drw > 2">{{listacc.ID_drw}}</h3>
                           <h3 *ngIf="listacc.ID_drw === 1  " class="text-info" >ผ่านการโอน</h3>
                           <h3 *ngIf="listacc.ID_drw === 2  " class="text-info" >ผ่านการโอน</h3>
                        </div>
                        <div class="col-sm-6">
                            <label for="">จำนวนเงิน:</label>
                            <input type="number" [(ngModel)]="listacc.totalprice" class="form-control"
                                placeholder="กรอก จำนวนเงิน">
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">

                <button type="button" (click)="savelist()"  *ngIf="listacc.ID == null" data-dismiss="modal" class="btn btn-success">บันทึก</button>
                <button type="button" (click)="saveeditlist()" *ngIf="listacc.ID !== null"  data-dismiss="modal" class="btn btn-warning">แก้ไข</button>
            </div>
        </div>
    </div>
</div>
<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="lookupacc" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><strong>ชื่อรายการ :{{datalist.name_list}}</strong></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-9">
                            <div class="card ">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-6"> <strong>ประเภท:</strong> </div>
                                        <div class="col-sm-6">

                                            <div *ngIf="datalist.type_list === '1'" class="text-success">รายรับ-สด
                                                &nbsp; </div>
                                            <div *ngIf="datalist.type_list === '2'" class="text-danger">รายจ่าย-สด
                                                &nbsp; </div>
                                            <div *ngIf="datalist.type_list === '3'" class="text-success">รายรับ-โอน
                                                &nbsp;</div>
                                            <div *ngIf="datalist.type_list === '4' " class="text-danger">รายจ่าย-โอน
                                                &nbsp;</div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-6"><strong>ลิ้นชัก(ID):</strong></div>
                                        <div class="col-sm-6"><div *ngIf="datalist.ID_drw === 1 || datalist.ID_drw === 2 " class="text-info" >ผ่านการโอน</div>
                                        <div *ngIf=" datalist.ID_drw > 2 " >{{datalist.ID_drw}}</div></div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-6"><strong>เป็นจำนวนเงิน:</strong></div>
                                        <div class="col-sm-6"><strong>{{datalist.totalprice|number:'2.2'}} ฿</strong>
                                        </div>
                                    </div>
                                    <hr>
                                    <div *ngIf="datalist.ID !== null" class="row">
                                        <div class="col-sm-6"><strong>บันทึกโดย:</strong></div>
                                        <div class="col-sm-6">{{datalist.name_users}}</div>
                                    </div>
                                    <hr *ngIf="datalist.ID !== null"  >
                                    <div class="row" *ngIf="datalist.ID !== null"  >
                                        <div class="col-sm-6"><strong>วันที่ทำรายการนี้:</strong></div>
                                        <div class="col-sm-6">{{datalist.date_input|date:'dd/MM/yyyy'}}</div>
                                    </div>
                                    <hr *ngIf="datalist.ID !== null" >
                                   






                                </div>
                            </div>
                        </div>

                        <div class="col-sm-3">


                            <div class="card ">
                                <div class="card-header"><strong>การยินยอม</strong> </div>
                                <div class="card-body" *ngIf="manageapprove">


                                    <select name="" class="form-control" (change)="selectapprove($event)" id="">
                                        <option value="">---โปรดเลือกการยืนยอม---</option>
                                        <option value="2">รอการอนุมัติ</option>
                                        <option value="3">อนุมัติ</option>
                                        <option value="4">ไม่อนุมัติ</option>
                                    </select>
                                    <button type="button" data-dismiss="modal" (click)="approvesave()"
                                        class="btn btn-block btn-success mt-2">ยืนยัน</button>
                                </div>
                                <div class="card-body" *ngIf="!manageapprove">
                                    <div *ngIf="dataapprove.approve === '2'">
                                        <h2 class="text-center text-info"><i (click)="approveagein(dataapprove)"
                                                class="fas fa-exclamation-circle"></i>
                                        </h2>
                                        <small class="text-center">รายการนี้ <br> อยู่ในช่วงการตัดสินใจ </small>

                                    </div>
                                    <div *ngIf="dataapprove.approve === '3'">
                                        <h2 class="text-center text-success"><i class="fa fa-check-circle"
                                                aria-hidden="true"></i> </h2>
                                        <small class="text-center">รายการนี้ <br> ได้ทำการอนุมัติเป็นที่เรียบร้อยแล้ว
                                        </small>

                                    </div>
                                    <div *ngIf="dataapprove.approve === '4'">
                                        <h2 class="text-center text-danger"><i class="fas fa-times-circle"></i>
                                        </h2>
                                        <small class="text-center">รายการนี้ <br> ไม่ผ่านการอนุมัติ </small>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-6"><strong>ยินยอมโดย:</strong></div>
                                        <div class="col-sm-6">{{dataapprove.name_approve}}</div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-6"><strong>วันที่ยินยอมรายการนี้:</strong></div>
                                        <div class="col-sm-6">{{dataapprove.date_input|date:'dd/MM/yyyy'}}</div>
                                    </div>
                                 
                                </div>

                            </div>
                        </div>


                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card ">
                                <div class="card-header"><strong>รายละเอียด-รายการ</strong></div>
                                <div class="card-body">
                                    <p class="card-text" [innerHTML]="datalist.detail_list"></p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>