import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ShareService } from '../../ShareService'
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { UploadServices } from '../../services/upload.service';
import * as AWS from 'aws-sdk/global';
import * as moment from 'moment'; // เวลา
import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {


  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService, private uploadService: UploadServices) {

  }
  statusUser: any = {
    status: null,
    username: null,
    name: null,
    id: null,
  }
  Dates = new Date();
  year = this.Dates.getFullYear()
  month = this.Dates.getMonth() + 1;
  Day = String(moment().format('DD'))
  hour = String(this.Dates.getHours())
  min = String(this.Dates.getMinutes())
  Seconds = String(this.Dates.getSeconds())
  mili = String(this.Dates.getMilliseconds())
  categories: any = [{}]//หมวดหมู่สินค้า
  FOLDER: string = 'wp-content/uploads'/*+ '/'+ this.year +'/'+ '0'+this.month  +'/'+String(this.Day) + String(this.hour) + String(this.min) + String(this.Seconds)+ String(this.mili)  +'/'
  //'+  this.year + '/' + this.Day+ this.hour + this.min + this.Seconds+ this.mili  +'/'*/
  media: any = []//รูปภาพ
  medias: any = {
    title: {
      rendered: null
    },
    caption: {
      rendered: null

    },
    description: {
      rendered: ''
    },
    media_type: "image",
    mime_type: null,
    author: this.statusUser.id,
    slug: null,
    source_url: null,
    status: 'draft',
    id:null
  }
  page:any = 1
  imageURL: any = null
  categorie: any = {
    name: null,
    image: {
      src: null,
      id:null,
    },
    slug: null,
    parent: null,
    id: null,
    display: null,
    description: null,
    count: null
  }

  selectedFiles: any = FileList;
  save() {
 
    var path = this.ShareService.serverPath + '/product/insertcategories'
    if (this.categorie.id == null) {
      delete this.categorie.image.id 
    }
    if (this.categorie.id !== null) {
      var path = this.ShareService.serverPath + '/product/updatecategories'
    }
    this.http.post(path, this.categorie).subscribe((res: any) => {

      if (res.data == 'success') {
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'บันทึกข้อมูลเรียบร้อย',
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          this.LoadDatacategories();
        })
      } if (res.data == 'updatasuccess') {
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'แก้ไขข้อมูลเรียบร้อย',
          showConfirmButton: false,
          timer: 2000

        })
      }
      if (res.data == 'err') {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'เกิดข้อผิดพลาด\nไม่สามารถเพิ่มหมวดหมู่ได้',
          showConfirmButton: false,
          timer: 2000

        })

      }
      if (res.data == 'updataerr') {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'เกิดข้อผิดพลาด\nไม่สามารถเพิ่มหมวดหมู่ได้',
          showConfirmButton: false,
          timer: 2000

        })
      }
    })
  }
  ngOnInit(): void {
    this.LoadDatacategories()
    this.loadCookie()
  }
  LoadDatacategories() {
    this.http.get(this.ShareService.serverPath + '/product/categories').subscribe((res: any) => {
      this.categories = res
      console.log(this.categories);
      

    })
  }

  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }
  getdata() {
    var param = {
      token: this.statusUser.token
    }
    if (this.media.length < 1) {
      this.http.post(this.ShareService.serverPath + '/media/mediaimg', param).subscribe((res: any) => {
        this.media = res
   
      })
    }

  }
  selectimg(item: any) {

    this.medias = item
  }
  selectdataimg(item: any) {
   this.categorie.image.src = item.source_url
   this.categorie.image.id = item.id
  }
  selectcate(item: any) {
    this.categorie = item
    if (this.categorie.image == null) {
      this.categorie.image = {
        src: null,
        id: null
      }

    }
   


  }
  del() {
   
    Swal.fire({
      title: 'คุณแน่ใจหรอที่จะลบหมวดหมู่นี้?',
      text: "กดปุ่มตกกลงเพื่อลบ!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ตกลง'
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.post(this.ShareService.serverPath + '/product/delcategories', { id: this.categorie.id }).subscribe((res: any) => {
          if (res.data == 'delsuccess') {
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'ลบข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 2000

            }).then(() => {
              this.LoadDatacategories()
            })
          }
          if (res.data == "err") {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'ลบข้อมูลไม่สำเร็จ\nโปรดลองใหม่อีกครั้ล',
              showConfirmButton: false,
              timer: 2000

            }).then(() => {
              this.LoadDatacategories()
            })
          }
        })
      }
    })

  }
  selectparent(e: any) {

    var parent: Number = e.target.value
    this.categorie.parent = parent
    
  }
  uploads() {
    const file = this.selectedFiles.item(0);
    this.onSelectFile(file);
  }
  nextpagesmedie(){
    this.page++ 
    console.log(this.page);
    
    var param = {
      token: this.statusUser.token,
      page:this.page
    }
    
      this.http.post(this.ShareService.serverPath + '/media/mediaimgpage', param).subscribe((res: any) => {
        this.media = res
   
      })
    
  }
  backpagesmedie(){
    this.page-- 
    console.log(this.page);
    
    var param = {
      token: this.statusUser.token,
      page:this.page
    }
    
      this.http.post(this.ShareService.serverPath + '/media/mediaimgpage', param).subscribe((res: any) => {
        this.media = res
   
      })
    
  }
  savemedia() {

    this.http.post(this.ShareService.serverPath + '/media/insertmedia', { data: this.medias, token: this.statusUser.token }).subscribe((res: any) => {
  

    })
  }
  selectFile(event: any) {
    this.selectedFiles = event.target.files;
    this.imageURL = event.target.files;
 
  }
  nulldata(){
    this.categorie = {
      name: null,
      image: {
        src: null,
        id:null,
      },
      slug: null,
      parent: null,
      id: null,
      display: null,
      description: null,
      count: null
    }
  }
  onSelectFile(file: any) {
    

  }
  uploadedFiles: any
  fileChange(element: any) {
    this.uploadedFiles = element.target.files;
  }
  /*upload() {
    var file = this.uploadedFiles[0]
     console.log(file);
    const contentType = file.mime_type;
    console.log(contentType);
    const bucket = new S3(
      {
        accessKeyId: 'AKIA36JLHUAJXQHVIYOS',
        secretAccessKey: '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',
        region: 'ap-southeast-1'
      }
    );
    const params = {
      Bucket: 's3.pinsouqstore',
      Key: this.FOLDER + file.name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params,  (err: any, data: any) => {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      console.log('Successfully uploaded file.', data);
      var param = {
        title:file.name,
        caption:'',
        description:'',
        media_type: "image",
        mime_type: file.type,
        author:this.statusUser.id,
        slug:null,
        source_url: data.Location,
        follow:this.mili
      }
      console.log(param);
      this.http.post(this.ShareService.serverPath + '/media/creatmedia', {param,token:this.statusUser.token}).subscribe((res: any) => {
      
    
      })
      return true;
    });
}*/
  upload() {
    var file = this.uploadedFiles[0]
    var param = {
      title: file.name,
      caption: '',
      description: '',
      media_type: "image",
      mime_type: file.type,
      author: this.statusUser.id,
      slug: null,
      
    }
   this.http.post('http://pinsouqstore.com/wp-json/wp/v2/media',file,{
    
      headers: {
        "Content-Disposition": 'attachment; filename=' + param.title,
        "Authorization": 'Bearer' + this.statusUser.token,
    
      },
    }).subscribe((res: any) => {
      if(res.source_url !== null){
        this.imageURL  = res.source_url
        this.getdata()
        this.selectdataimg(res)
      }
    
    })
    /*this.http.post(this.ShareService.serverPath + '/media/creatmedia', { param,files:file,token: this.statusUser.token }).subscribe((res: any) => {
      console.log(res);*/
    /*  var path = res.source_url.split('uploads')\1].replace(param.title, '')
      console.log(path);
      const contentType = file.mime_type;
      console.log(contentType);
      const bucket = new S3(
        {
          accessKeyId: 'AKIA36JLHUAJXQHVIYOS',
          secretAccessKey: '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',
          region: 'ap-southeast-1'
        }
      );
      const params = {
        Bucket: 's3.pinsouqstore',
        Key: this.FOLDER + path +file.name,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType
      };
      bucket.upload(params, (err: any, data: any) => {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          return false;
        }
        console.log(data);
        return true;
      })
    })*/ 

  }
}
