<div class="card card-primary">
    <div class="card-header">ข้อมุลโอน/ย้ายสินค้า</div>
  <div class="card-body">
    
      
            <button type="button" class="btn btn-primary">โอนสินค้า</button>
            &nbsp;
            <button type="button" class="btn btn-primary">ประวัติการรับโอนสินค้า</button>
            &nbsp;
            <button type="button" class="btn btn-primary">ย้ายสินค้า</button>
       <hr>
   <table class="table">
       <thead>
           <tr>
               <th></th>
               <th>วัน-เดือน-ปี</th>
               <th>รหัส-สินค้า</th>
               <th>ชื่อ-สินค้า</th>
               <th>จำนวน</th>
               <th>หมายเหตุ</th>
               <th></th>
           </tr>
       </thead>
       <tbody>
           <tr>
               <td scope="row"><button type="button" class="btn btn-sm btn-success"><i class="fa fa-check" aria-hidden="true"></i></button></td>
               <td></td>
               <td></td>
               <td></td>
               <td></td>
               <td></td>
               <td>         <a  data-toggle="dropdown" href="#"  aria-haspopup="true" aria-expanded="false"><button type="button" class="btn btn-sm "><i class="fas fa-bars"></i></button></a>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#">สินค้าผิดประเภท</a>
                      <a class="dropdown-item" href="#">คืนสินค้ากลับ</a>
                      <a class="dropdown-item" href="#">เปลี่ยนการรับสินค้า</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item text-danger" href="#">ยกเลิกรับสินค้า</a>
                    </div>
         
                  </td>

           </tr>
           
       </tbody>
   </table>
  </div>
</div>