<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="statusUser.username != null">

    <a [routerLink]="['/Dashboard']" routerLinkActive="router-link-active" class="brand-link">
        <img src="assets/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3">
        
        <span class="brand-text font-weight-light"></span>
    </a>
    <!-- SEARCH FORM -->
    <form class="form-inline "   >
        <div class="input-group input-group-lm">
            <input class="form-control form-control-navbar" name="product.sku" [(ngModel)]="product.sku" (keyup.enter)="Lookup($event)" #myinput placeholder="สแกนบาร์โค้ด"
                aria-label="Search">
            <div class="input-group-append">
                <button class="btn btn-navbar"   >
                    <i class="fas fa-barcode" (click)="lookupproduct()"></i>
                </button>
            </div>
        </div>
    </form>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    &nbsp; &nbsp;
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav mr-auto ">
         
            &nbsp;
            <li class="nav-item active" >
                <a class="nav-link btn btn-info text-light " *ngIf="statusUser.status == 'administrator'" [routerLink]="'/manageproduct'"><i
                        class="fa fa-box-open">จัดการสินค้า</i></a>
                 <a class="nav-link btn btn-info text-light " *ngIf="statusUser.status !== 'administrator'" [routerLink]="'/manageproduct'"><i
                            class="fa fa-box-open">จัดการสินค้า</i></a>
            </li>
            &nbsp;
            <li class="nav-item active">
                <a class="nav-link btn btn-success text-light" [routerLink]="'/member'"> <i class="fa fa-users "></i>
                   <strong>จัดการสมาชิก</strong> </a>
            </li>
            &nbsp;
            
            <li class="nav-item active">
                <a class="nav-link btn btn-warning text-light " text-light [routerLink]="'/forntsales'" > <i class="fas fa-cash-register"></i>
                    <strong>หน้าร้าน</strong> </a>
            </li>
            &nbsp;
            <li class="nav-item active">
                <a class="nav-link btn btn-primary text-light" (click)="checkdrw()"> <i
                        class="fas fa-file-invoice-dollar"></i><strong>บัญชี</strong> </a>
            </li>
        </ul>

       

       
        
        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">
            <!-- ผู้ใช้ -->
            <li class="nav-item dropdown">
                <a class="nav-link"   [routerLink]="'/cart'">
                   <i class="fa fa-shopping-cart text-info " aria-hidden="true"></i>
                </a>
            </li>
            <li class="nav-item dropdown">

                <a class="nav-link" data-toggle="dropdown" href="#">
                    <i class="fas fa-user text-success"></i>

                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a class="dropdown-item " [routerLink]="'/admin'">
                        <i class="fas fa-user " [routerLink]="'/admin'"></i> &nbsp; <strong>ชื่อผู้ใช้:</strong>
                        <span class="float-right text-muted text-sm">{{statusUser.name}}</span>
                    </a>
                  <div class="dropdown-divider"></div>
                    <a [routerLink]="'/emp'" class="dropdown-item">
                        <i class="fas fa-users" [routerLink]="'/emp'"></i> &nbsp; <strong>บุคลากร</strong>
                        <span class="float-right text-muted text-sm"> </span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a [routerLink]="'/report'" class="dropdown-item">
                        <i class="fas fa-file"></i>
                        &nbsp; <strong>รายงาน</strong>
                        <span class="float-right text-muted text-sm"> </span>
                    </a>

                 <!---     <div class="dropdown-divider"  *ngIf="statusUser.status == 'administrator'" ></div>
                    <a   *ngIf="statusUser.status == 'administrator'" [routerLink]="'/orders'" class="dropdown-item">
                        <i class="fas fa-store"></i>
                        &nbsp; <strong>คำสั่งซื้อ</strong>
                        <span class="float-right text-muted text-sm"> </span>
                    </a>--> 
                <!---    <div class="dropdown-divider"></div>
                    <a [routerLink]="'/debtor'"  *ngIf="statusUser.status == 'administrator'"  class="dropdown-item">
                        <i class="fas fa-address-book"></i>
                        &nbsp; <strong>รายการลูกหนี้</strong>
                        <span class="float-right text-muted text-sm"> </span>
                    </a>--> 
                    <div *ngIf="statusUser.username  == 'hisam1875@gmail.com'">
                        <div  class="dropdown-divider" ></div>
                        <a   [routerLink]="'/branch'" class="dropdown-item" >
                            <i class="fas fa-building"></i>
                            &nbsp; <strong>สาขา</strong>
                            <span class="float-right text-muted text-sm"> </span>
                        </a>
                    </div>
                    <div *ngIf="statusUser.status  == 'administrator'">
                        <div  class="dropdown-divider" ></div>
                        <a   [routerLink]="'/config'" class="dropdown-item" >
                            <i class="fas fa-cogs"></i>
                            &nbsp; <strong>ตั้งค่า</strong>
                            <span class="float-right text-muted text-sm"> </span>
                        </a>
                    </div>
                    <div class="dropdown-divider"></div>
                    <a [routerLink]="'/help'" class="dropdown-item">
                        <i class="fas fa-info-circle"></i>
                        &nbsp; <strong>ความช่วยเหลือ</strong>
                        <span class="float-right text-muted text-sm"> </span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a (click)="logout()" class="dropdown-item">
                        <i class="fas fa-power-off text-danger"></i>
                        &nbsp; <strong>ออกจากระบบ</strong>
                        <span class="float-right text-muted text-sm"> </span>
                    </a>
                   
                   
                </div>
            </li>


            &nbsp;

        </ul>

    </div>

</nav>