<div class="container">
    <div class="card card-dark ">
    <div class=" card-header">ข้อมูลการขายวันนี้</div>
      <div class="card-body">
        <div class="chart-wrapper">
            <canvas baseChart 
            [data]="doughnutChartData"
            [labels]="doughnutChartLabels"
            [chartType]="doughnutChartType">
          </canvas>
        </div>
        <hr>
        <table class="table table-hover table-inverse table-responsive">
            <thead class="thead-inverse">
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><button type="button" data-toggle="modal" data-target="#billdetaill" class="btn btn-info btn-sm"><i class="fa fa-search" aria-hidden="true"></i></button></td>
                        <td scope="row">ลำดับที่</td>
                        <td>รหัสใบเสร็จ</td>
                        <td>จำนวนสินค้า</td>
                        <td>ราคารวมทั้งสิ้น</td>
                        <td>วัน-เดือน-ปี</td>
                        
                    </tr>
                    <tr>
                        <td scope="row"></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
        </table>
      </div>
    </div>
</div>
<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="billdetaill" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
                <div class="modal-header">
                        <h5 class="modal-title">ข้อมูลใบเสร็จ</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
            <div class="modal-body">
                <div class="container-fluid">
                  <h3>ใบเสร็จเลยที่: ...</h3>
                  <table class="table">
                      <thead>
                          <tr>
                              <th>ลำดับที่</th>
                              <th>รหัสสินค้า</th>
                              <th>ชื่อสินค้า</th>
                              <th>จำนวน</th>
                              <th>ราคาต่อหน่วย</th>
                              <th>ราคารวม</th>
                              
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                             
                          </tr>
                        
                      </tbody>
                  </table>
                  <h3>ราคารวมทั้งสิ้น: ...</h3>
                </div>
            </div>
            
        </div>
    </div>
</div>

