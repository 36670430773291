import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
@Component({
  selector: 'app-year',
  templateUrl: './year.component.html',
  styleUrls: ['./year.component.css']
})
export class YearComponent implements OnInit {
 
  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels: Label[] = ['1/2020','2/2020','3/2020','4/2020','5/2020','6/2020','7/2020','8/2020','9/2020','10/2020','11/2020','12/2020',];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData: ChartDataSets[] = [
    
    { data: [50000, 30000, 100000, 80000, 50000, 30000,200000,100000,130000,100000,80000,30000], label: 'ยอดในปี 2000' },
    { data: [50000, 30000, 100000, 80000, 60000, 30000,80000,100000,230000,10000,80000,30000], label: ' ยอดในปี 2019' }
    
  ];
  constructor() {
    
   }

  ngOnInit(): void {
  }

}
