<div class="container-md ">
    <div class="card card-primary">
        <div class="card-header">ข้อมูลส่วนตัว</div>
        
       <div class="card-body">
        <div class="d-flex justify-content-center">
         
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label for="">ชื่อผู้ใช้</label>
                <input type="text" class="form-control" readonly="readonly" [(ngModel)]="users.username" name="" id="">
            </div>
           
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label for="">ชื่อ</label>
                <input type="text" class="form-control" [(ngModel)]="users.first_name" name="" id="">
            </div>
            <div class="col-sm-3">
                <label for="">นามสกุล</label>
                <input type="text" class="form-control" [(ngModel)]="users.last_name" name="" id="">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label for="">email</label>
                <input type="text" class="form-control"[(ngModel)]="users.email" name="" id="">
            </div>
            <div class="col-sm-3">
                <label for="">เบอร์โทรศัพท์</label>
                <input type="text" class="form-control"[(ngModel)]="users.billing.phone" name="" id="">
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-6">
                <label for="">ที่อยู่</label>
               <textarea name="" id=""  [(ngModel)]="users.billing.address_1 " class="form-control" ></textarea>
            </div>
           
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label for="">บริษัท</label>
                <input type="text" class="form-control"[(ngModel)]="users.billing.company" name="" id="">
            </div>
            <div class="col-sm-3">
                <label for="">จังหวัด</label>
                <input type="text" class="form-control"[(ngModel)]="users.billing.state" name="" id="">
            </div>
           
            
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label for="">อำเภอ</label>
                <input type="text" class="form-control"[(ngModel)]="users.billing.city" name="" id="">
            </div> 
            <div class="col-sm-3">
                <label for="">เลขที่ไปรษณี</label>
                <input type="text" class="form-control"[(ngModel)]="users.billing.postcode" name="" id="">
            </div>
        </div>
     
        <div class="row">
            <div class="col-sm-6">
                <label for="">คำอธิบายผู้ใช้</label>
               <textarea name="" id="" cols="30" [(ngModel)]="users.description" class="form-control" ></textarea>
            </div>
           
        </div>
       </div>
        <div class="card-footer">
            <button class="btn  btn-success" (click)="save()"> บันทึกข้อมูล</button>
            
        </div>
    </div>
</div>




