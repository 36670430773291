import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShareService } from '../ShareService';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {


  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService, private router: Router) { }

  Products: any = []
  TelMember: any = null
  Countsum: any = 0
  TotalPrice: any = 0
  datenow: any = new Date;
  seles: any = null;
  valuePriceSell: any = 0;
  pricesele: any = null;
  GrandTotal: any = 0;
  getmoney: any = null;
  ID_order: any = null;
  wholesales: any = [];
  billing = {
    address_1: 'ซื้อสินค้าหน้าร้าน',
    address_2: '',
    city: '',
    company: '',
    country: '',
    email: 'services.hasorder@gmail.com',
    first_name: 'ลูกค้าทั่วไป',
    last_name: '',
    phone: '',
    postcode: '',
    state: 'TH-94',
  }
  productprice: any = []
  idMember: any = 0
  ID_coupon: any = 0;
  coupon: any = null
  detailpayment = [
    "cash", "bank", 
  ]
  line_items: any = []
  Datenow: any = null
  payment: any = null
  changemoney: any = null
  totallist: any = 0
  statusUser: any = {
    username: null,
    name: null,
    token: null,
    email: null,
    id: null,
    status: null,
    billing_address_1: null,
    billing_address_2: null,
    billing_city: null,
    billing_company: null,
    billing_country: null,
    billing_first_name: null,
    billing_last_name: null,
    billing_postcode: null,
    billing_state: null,
    billing_phone: null,
    billing_email: null,
    ID_branch: null,
    email_branch: null,
    name_branch: null,
    address: null,
    address_1: null,
  }
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
 
    this.statusUser = allCookies
    

    if (this.statusUser.status == null || this.statusUser.username == null || this.statusUser.token == null) {

      location.href = '/login'
    }

  }
  ngOnInit(): void {
    this.loadCookie();
    this.FristID()
    this.DateNows();

 
    this.LoadOrder();
  }
  LoadOrder() {
    var param = {
      iduser: this.statusUser.id
    }
    this.http.post(this.ShareService.serverPath + '/product/cart', param).subscribe((res: any) => {
    
      if (res.length == 0) {
        this.Products = null
      } else if (res.length != 0) {
        this.Products = res
        this.pushitem()
        this.sumprice()
      }
      this.loaddatawhlsele();
      this.FristID();

    })

  }
  loaddatawhlsele() {

    this.Products.forEach((e: any) => {
 
      var filter = this.wholesales.filter((r: any) => {
        return r.ID_product == e.id
      })
  
      if (filter.length > 1) {
        this.wholesales.forEach((e: any, index: any) => {
          this.Products.forEach((s: any) => {
            if (e.ID_product !== s.id) {
              this.wholesales.splice(index, 1);
            }
          });

        });
      }
      if (filter.length === 0) {
        var param = {
          ID_product: e.id,
          ID_branch: this.statusUser.ID_branch
        }
        this.http.post(this.ShareService.serverPath + '/product/lookupproductwholesale', param).subscribe((res: any) => {
          this.wholesales.push(res)

        })
      }



    });



  }
  sumprice() {
    let sum = this.Products.map((p: { price: number; quantity: number; }) => p.price * p.quantity)
      .reduce((a: any, b: any) => a + b, 0);
    let sumQ = this.Products.map((o: { quantity: any; }) => o.quantity).reduce((a: any, c: any) => { return a + c });
   
    this.Countsum = sumQ
    this.TotalPrice = sum
    this.GrandTotal = sum
    this.totallist = this.Products.length
  }
  pushitem() {
    this.Products.forEach((e: any) => {
      if (e.iduser == this.statusUser.id) {
       
        var processing = this.line_items.filter((res: any) => {
          return res.product_id == e.id;
        });
        if (processing.length == 0) {

          var param = {
            product_id: e.id,
            quantity: e.quantity,
            price: e.price,
            total: String(e.price * e.quantity)
          }
          this.line_items.push(param)
        }

        if (processing.length == 1) {
          const updatedCo = this.line_items.map((res: any) => {
            var qty = {
              product_id: res.product_id,
              quantity: e.quantity,
              price: e.price,
              total: String(e.price * e.quantity)
            }
            return res = qty
          });
          this.line_items = updatedCo
          this.line_items.forEach((e: any, index: any) => {
            this.Products.forEach((s: any) => {
              if (e.product_id !== s.id) {
                this.line_items.splice(index, 1);
              }
            });

          });

        }

      }
    });
   
  }

  seleprice(e: any) {
    

    this.valuePriceSell = e.target.value
    this.pricesele = this.TotalPrice - e.target.value
    this.GrandTotal = this.pricesele
  }

  selectmember(e: any) {
  
    this.TelMember = e.target.value
    this.http.post(this.ShareService.serverPath + '/customer/selectmember', [e.target.value]).subscribe((res: any) => {
    

      if (res.length !== 0) {
        Swal.fire({
          title: 'สมาชิกที่เลือกคือ' + res[0].company,
          text: "กดปุ่มตกกลงเพื่อยืนยัน",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then((result) => {
          if (result.isConfirmed) {
            this.billing = res[0]
           
            this.idMember = res[0].ID
            this.billing.address_1 = res[0].address
          } else if (result.isDismissed) {
            this.TelMember = null
          }
        })

      }

      if (res.length == 0) {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'ไม่มีข้อมูลสมาชิก ',
          showConfirmButton: false,
          timer: 1000
        }).then(() => {
          this.TelMember = null
        })
      }
    })
  }
  upqty(e: any) {

    var param = {
      ID_cart: e.target.id,
      quantity: e.target.value
    }
  
    this.http.post(this.ShareService.serverPath + '/product/editqty', param).subscribe((res: any) => {
      if (res) {

        this.router.navigate(['/', 'cart']).then(nav => {
          this.LoadOrder();
          this.pushitem()
        }, err => {
       
        });
      };


    })
  }
  getmoneys(e: any) {
   
    if (this.payment !== "credit" && this.GrandTotal !== e.target.value && this.GrandTotal > e.target.value) {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'ยอดชำระ กับ ยอดรับเงิน \n ไม่เท่ากัน หรือ น้อยกว่า \n โปรดลองใหม่อีกครั้ง',
        showConfirmButton: true,

      }).then(() => {
        this.getmoney = null
      })
    }
    if (e.target.value) {
      this.changemoney = e.target.value - this.GrandTotal
      return;
    }

  }
  delProduct(item: any) {

    Swal.fire({
      title: 'คุณแน่ใจหรอว่าจะลบ สินค้านี้ !',
      text: "กดปุ่มตกกลงเพื่อลบ!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ตกลง'
    }).then((result) => {
      if (result.isConfirmed) {
        var path = this.ShareService.serverPath + '/product/daleteproductCart'
        this.http.post(path, item).subscribe((res: any) => {
     
          if (res) {
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'ลบข้อมูลเรียบร้อย ',
              showConfirmButton: false,
              timer: 1000

            }).then(() => {
              this.router.navigate(['/cart']).then(() => {
                this.loaddatawhlsele();
                this.LoadOrder();
                this.pushitem()
              }, err => {
            
              });
            });

          }
        })

      }
    })

  }
  save() {
  
    var payment = {
      payment_method: '',
      payment_method_title: '',
      set_paid: true,
    }
    if (this.payment == "bank") {
      payment.payment_method = "bacs"
      payment.payment_method_title = "ชำระด้วยการโอน"
    }
    if (this.payment == "cash") {
      payment.payment_method = "cop"
      payment.payment_method_title = "ชำระด้วยเงินสด"
    }
    if (this.TelMember === null) {
      this.billing = {
        address_1: this.statusUser.billing_address_1,
        address_2: this.statusUser.billing_address_2,
        city: this.statusUser.billing_city,
        company: this.statusUser.name_branch,
        country: this.statusUser.billing_country,
        email: this.statusUser.email_branch,
        first_name: 'ลูกค้าทั่วไป',
        last_name: 'ซื้อหน้าร้าน',
        phone: '',
        postcode: this.statusUser.billing_postcode,
        state: this.statusUser.billing_state,
      }

    } else {
      this.billing.email = this.statusUser.email_branch
      this.billing.address_1 == this.billing.address_1

    }
    if (this.billing.first_name == '') {
      this.billing.first_name = this.billing.company
    }
    var params = {
      db: this.Products,
      ID_order: this.ID_order,
      ID_branch: this.statusUser.ID_branch
    }

    var param = {
      woo: {
        payment: payment,
        line_items: this.line_items,
        shipping: {
          first_name: this.statusUser.name,
          last_name: "",
          address_1: this.statusUser.billing_address_1,
          address_2: this.statusUser.billing_address_2,
          city: this.statusUser.billing_city,
          state: this.statusUser.billing_state,
          postcode: this.statusUser.billing_postcode,
          country: "TH"
        },
        billing: this.billing,
        shipping_lines: [
          {
            method_id: "local_pickup",
            method_title: "Local pickup",
            total: '0'
          }
        ],
        status: 'completed',
        total: this.GrandTotal,
        token:this.statusUser.token
      }
    }
    if (this.payment !== "credit") {
  
        this.http.post(this.ShareService.serverPath + '/order/offlineinsertdetail', params).toPromise().then((res: any) => {
        

        })
     .then(() => {
        var param = [{
          'id_customer': this.idMember,
          'discount': this.valuePriceSell,
          'id_coupon': this.ID_coupon,
          'totalprice': this.GrandTotal,
          'totalquantity': this.Countsum,
          'payment': this.payment,
          'getmoney': this.getmoney,
          'changemoney': this.changemoney,
          'ID': this.ID_order,
          'users': this.statusUser.name,
          'ID_branch': this.statusUser.ID_branch
        }]
        this.http.post(this.ShareService.serverPath + '/order/checkuotOrderOfflinedetail', param).toPromise().then((res: any) => {
        
        })
      }).then(() => {
        this.http.post(this.ShareService.serverPath + '/product/deleteDatacart', { data: this.statusUser.id }).subscribe((res: any) => {
       
          if (res.data == 'Success') {
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'การขายเสร็จสิ้น',
              showConfirmButton: false,
              timer: 1000,

            }).then(() => {

              this.router.navigate(['/', 'successbuy']);
            });

          }
        })
      }).then(() => {
    
      })

        .catch((err) => {
          if (err) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'การขายไม่สำเร็จ \nโปรดลองใหม่อีกครั้ง!!',
              showConfirmButton: false,
              timer: 2000

            })
          }
        })
    }


    if (this.payment === "credit" ) {
      if (this.TelMember !== null) {
        var data = {
          Products: this.Products,
          ID_order: this.ID_order,
          ID_branch: this.statusUser.ID_branch,
  
        }
        this.http.post(this.ShareService.serverPath + '/order/checkoutcredit', data).toPromise().then((res: any) => {
          
          if (res) {
        
  
          }
        }).then(() => {
         var param = [{
            'id_customer': this.idMember,
            'discount': this.valuePriceSell,
            'id_coupon': this.ID_coupon,
            'totalprice': this.GrandTotal,
            'totalquantity': this.Countsum,
            'payment': this.payment,
            'getmoney': this.getmoney,
            'changemoney': this.changemoney,
            'ID': this.ID_order,
            'users': this.statusUser.name,
            'ID_branch': this.statusUser.ID_branch
          }]
          this.http.post(this.ShareService.serverPath + '/order/checkuotOrderOfflinedetailcredit', param).toPromise().then((res: any) => {
      
          })
        })
          .then(() => {
            this.http.post(this.ShareService.serverPath + '/product/deleteDatacart', { data: this.statusUser.id }).subscribe((res: any) => {
            
              if (res.data == 'Success') {
                Swal.fire({
                  position: 'top',
                  icon: 'success',
                  title: 'การขายเสร็จสิ้น \nโปรดรอสักครู่....',
                  showConfirmButton: false,
                  timer: 1000
  
                }).then(() => {
  
                  this.router.navigate(['/', 'successbuy']);
                });
  
              }
            })
          }).then(() => {
           /* this.http.post(this.ShareService.serverPath + '/order/checkoutonline', param.woo).toPromise().then((res: any) => {

              if (res) {
            
  
              }
            })*/
          })
      }else{
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'โปรดกรอกข้อมูลเลขสมาชิก \n หรือเบอร์โทรสมาชิก',
          showConfirmButton: true,

        })
      }
      
    } 
    
  }
  getDetail() {
    this.http.get(this.ShareService.serverPath + '/product/cartdatail').subscribe((res: any) => {
      
      if (res) {


      }

    })

  }
  selectpay(item: any) {

    this.payment = item

  }
  FristID() {
    this.http.post(this.ShareService.serverPath + '/product/FirstID', { ID_branch: this.statusUser.ID_branch }).subscribe((res: any) => {

      var id: number = res
      this.ID_order = id
 
    })
  }
  DateNows() {
    var day = new Date
    var date = new Date(day);
    var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
      .toISOString()


    

  }
  closeSell() {
    Swal.fire({
      title: 'คุณแน่ใจหรอที่จะยกเลิกการขาย?',
      text: "กดปุ่มตกกลงเพื่อยกเลิก!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ตกลง'
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.post(this.ShareService.serverPath + '/product/deleteDatacart', { data: this.statusUser.id }).subscribe((res: any) => {
        
          if (res.data == 'Success') {
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'การขายยกเลิกเสร็จสิ้น \nโปรดรอสักครู่....',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              this.router.navigateByUrl('/cart').then(() => {
                this.LoadOrder();
                this.TotalPrice = 0
                this.totallist = 0
                this.sumprice()
                this.pushitem()
                this.loaddatawhlsele();
                this.GrandTotal = 0
                this.Countsum = 0
              }, err => {
             
              });
            });

          }
        })

      }
    })

  }
  selectMoreprice(e: any) {
   

    var data = e.target.value
    var price: number = +data
   
    if (price != 0) {
      var processing = this.wholesales.filter(function (res: any) {
        return res.ID_product == e.target.id
      });
    

      var param = {
        ID_product: e.target.id,
        price: price
      }
      

      this.http.post(this.ShareService.serverPath + '/product/editpricecart', param).subscribe((res: any) => {
        if (res) {
          this.router.navigate(['/', 'cart']).then(() => {
            this.LoadOrder();
            this.pushitem();

          }, err => {
           
          });;
        }
      })
    } else {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'ไม่มีราคานี้ในระบบ \n โปรดตรวจสอบอีกครั้ง ',
        showConfirmButton: true,

      }).then(() => {
        this.router.navigate(['/', 'cart']).then(() => {
          this.LoadOrder();
          this.pushitem()
        }, err => {
         
        });;
      });
    }

  }
}

