import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ShareService } from '../../ShareService'
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {

  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService, public datepipe: DatePipe) { }
  tags: any = [] //ป้ายกำกับสินค้า
  tag: any = {
    name: null,
    slug: null,
    description: null,
    id: null
  }
  ngOnInit(): void {
    this.LoadDataTags()
  }
  save() {
    if (this.tag.name == null) {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'กรุณากรอกชื่อป้ายกำกับสินค้า\nโปรดลองใหม่อีกครั้ง',
        showConfirmButton: false,
        timer: 2000

      })
    } else {
      var path = this.ShareService.serverPath + '/product/inserttags'
      if (this.tag.id != null) {
        path = this.ShareService.serverPath + '/product/updatetags'
      }
      this.http.post(path, this.tag).subscribe((res: any) => {
        if (res.data == 'insertsuccess') {
          Swal.fire({
            position: 'top',
            icon: 'success',
            title: 'บันทึกข้อมูลเรีบร้อย',
            showConfirmButton: false,
            timer: 2000

          }).then(() => {
            this.LoadDataTags()
            this.nulldata()
          })
        }
        if (res.data == 'ubdatesuccess') {
          Swal.fire({
            position: 'top',
            icon: 'success',
            title: 'แก้ไขข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 2000

          }).then(() => {
            this.LoadDataTags()
            this.nulldata()
          })
        }
        if (res.data == "err") {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'เกิดข้อผิดพลาด\nโปรดลองอีกครั้ง',
            showConfirmButton: false,
            timer: 2000

          }).then(() => {
            this.LoadDataTags()
          })
        }

      })
    }
  }
  LoadDataTags() {

    this.http.get(this.ShareService.serverPath + '/product/tags').subscribe((res: any) => {
      this.tags = res
      console.log(this.tags);

    })
  }
  selecttag(item: any) {
    console.log(item);
    this.tag = item


  }
  nulldata() {
    this.tag = {
      name: null,
      slug: null,
      description: null,
      id: null
    }
  }
  deldata(){
    Swal.fire({
      title: 'คุณแน่ใจหรอที่จะ\nลบป้ายกำกับสินค้านี้?',
      text: "กดปุ่มตกกลงเพื่อลบ!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ตกลง'
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.post(this.ShareService.serverPath + '/product/deltags', { id: this.tag.id }).subscribe((res: any) => {
          if (res.data == 'delsuccess') {
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'ลบข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 2000

            }).then(()=>{
              this.LoadDataTags()
              this.nulldata()
            })
          }
          if (res.data == "err") {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'ลบข้อมูลไม่สำเร็จ\nโปรดลองใหม่อีกครั้ล',
              showConfirmButton: false,
              timer: 2000

            }).then(()=>{
              this.LoadDataTags
              this.nulldata()
            })
          }
        })
      }
    })
  }
}
