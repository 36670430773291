<div class="card card-primary">
    <div class="card-header">ข้อมูลป้ายกำกับสินค้า</div>
    <div class="card-body">

        <div class="row">
            <div class="col-sm-4">
                <div class="card card-primary">
                    <div class="card-header">เพิ่มป้ายกำกับสินค้า</div>
                    <div class="card-body">
                        <div class="row">
                            <label>ชื่อ:<strong class="text-danger">*</strong></label>
                            <input type="text" [(ngModel)]="tag.name" placeholder="กรอก ชื่อ" class="form-control" name="" id="">
                        </div>
                        <div class="row">
                            <label>slug:</label>
                            <input type="text" [(ngModel)]="tag.slug"   placeholder="กรอก slug" class="form-control" name="" id="">
                        </div>
                        <div class="row">
                            <label for="">รายละเอียด:</label>
                            <textarea class="form-control"  [(ngModel)]="tag.description"  placeholder="กรอก รายละเอียด" name="" id=""
                                rows="3"></textarea>
                        </div>
                    </div>
                    
                    <div class="card-footer">
                        <button type="button"  (click)="save()" *ngIf="tag.id == null " class="btn btn-success" >บันทึก</button>
                        <button type="button"  (click)="save()"  *ngIf="tag.id != null " class="btn btn-warning" >แก้ไข</button>
                        &nbsp;
                        <button type="button" (click)="deldata()" *ngIf="tag.id != null " class="btn btn-danger" >ลบ</button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button type="button" (click)="nulldata()" *ngIf="tag.id != null " class="btn btn-info" >ยกเลิก</button>
                    </div>



                </div>
            </div>
            <div class="col-sm-8">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>ชื่อ</th>
                            <th>รายละเอียด</th>
                            <th>จำนวน</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tags">
                            <td><button type="button" (click)="selecttag(item)" class="btn btn-sm btn-info"><i class="fa fa-search" aria-hidden="true"></i></button></td>
                            <td>{{item.name}}</td>
                            <td>{{item.description}}</td>
                            <td>{{item.count}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</div>