import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ShareService } from '../../ShareService'
import Swal from 'sweetalert2';
import { Auth } from "../../auth";
@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {

  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService, private auth: Auth,) { }
  branchs: any = []
  states: any = [];
  ngOnInit(): void {
    this.loadCookie();
    this.loadbranch();
  }
  statusUser: any = {
    status: null,
    username: null,
    token: null,
    tokenvender: null
  }
  branch: any = []
  loadstate() {
    this.http.get(this.ShareService.serverPath + '/branch/state').subscribe((res: any) => {
      this.states = res
  

    })
  }
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
   
    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }
  loadbranch() {
    var param = { token: this.statusUser.token }
    this.http.post(this.ShareService.serverPath + '/branch/showbranch', param).subscribe((res: any) => {
      this.branchs = res
  
    })
  }
  lookupbrah(item: any) {
    this.branch = item


  }
  openbranch(item: any) {
    Swal.fire({
      title: 'โปรดกรอกรหัสผ่านสาขา \n เพื่อเปิดการใช้งาน',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: !true,
      confirmButtonText: 'ตกลง',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        var param = {
          username: item.username,
          password: login
        }
  
        this.http.post(this.auth.serverPath, param).toPromise().then((result: any) => {
      
          this.statusUser.tokenvender = result.token
        }).then(() => {
          var param = {
            id: item.id,
            dokan_enable_selling: 'yes',
            token: this.statusUser.tokenvender
          }
          this.http.post(this.ShareService.serverPath + '/branch/openbranch', param).subscribe((res: any) => {
           

          })
        })
          .catch((err) => {
            if (err.status === 403) {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'รหัสผ่าน\nของคุณไม่ถูกต้อง\nโปรดลองอีกครั้ง',
                showConfirmButton: false,
                timer: 3000
              })
            }
         
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {
     
      /*   if (result.isConfirmed) {
           Swal.fire({
             title: `${result.value.login}'s avatar`,
             imageUrl: result.value.avatar_url
           })
         }*/
    })
    /* var param = {
       id:item.id,
       dokan_enable_selling:'yes'
     }
     this.http.post(this.ShareService.serverPath + '/branch/openbranch',param).subscribe((res: any) => {
       this.branchs = res

     })*/
  }
  closebranch(item: any) {
    var param = {
      id: item.id,
      dokan_enable_selling: 'no',
      token: this.statusUser.tokenvender
    }
    this.http.post(this.ShareService.serverPath + '/branch/closebranch', param).subscribe((res: any) => {
      this.branchs = res
      
    })
  }
}
