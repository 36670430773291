<div class="card card-primary">
    <div class="card-header">จัดการคลังสินค้า</div>
    <div class="card-body">
        <div class="row">
            <div class="d-flex justify-content-start">
                <button class="btn  btn-info" (click)="nullProduct()" data-toggle="modal" data-target="#addproduct">
                    <i class="fa fa-inbox" aria-hidden="true"></i> เพิ่มสินค้า <span class="badge badge-primary"></span>
                </button> &nbsp;
                <a class="btn btn-info" [routerLink]="'/GetProduct'">
                    <i class="fa fa-box" aria-hidden="true"></i> รับสินค้า <span class="badge badge-primary"></span>
                </a>
                &nbsp;
                <button class="btn  btn-info" data-toggle="modal" (click)="loaddistribute()" data-target="#excproduct">
                    <i class="fa fa-box" aria-hidden="true"></i> แตกสินค้า <span class="badge badge-primary"></span>
                </button>
                <!--  <a class="btn btn-info" [routerLink]="'/transfer'">
                    <i class="fas fa-exchange-alt"></i> โอน/ย้ายสินค้า <span class="badge badge-primary"></span>
                </a>&nbsp;-->

            </div>

        </div>
        <div class="d-flex flex-row-reverse bd-highlight">

            <div class="p-2 bd-highlight"><input type="search" [(ngModel)]="search" placeholder="ค้นหาสินค้า"
                    class="form-control" name="" id=""></div>
            <div class="p-2 bd-highlight">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>


        <table id="table1" class="table">
            <thead class="thead-inverse">
                <tr>
                    <th>#</th>
                    <th>ลำดับ</th>
                    <th>รูป-สินค้า</th>
                    <th>ชื่อ-สินค้า</th>
                    <th>รหัส-สินค้า</th>
                    <th>จำนวน</th>
                    <th>ราคา <br>ต้นทุน</th>
                    <th>ราคา <br> สินค้าปกติ</th>
                    <th>ราคา <br> สินค้าส่ง</th>
                    <th>สถานะ</th>


                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let item of Products|filter:search| paginate: { itemsPerPage: 10, currentPage: p };let i=index">
                    <td><button type="button" data-toggle="modal" data-target="#selectproduct"
                            (click)="selectproduct(item)" class="btn btn-primary btn-sm"><i class="fa fa-search"
                                aria-hidden="true"></i></button></td>
                    <td>{{i+1}}</td>
                    <td>
                        <div *ngIf="item.url_img == ''">
                            <a data-toggle="modal" data-target="#selectproduct" (click)="selectproduct(item)">
                                <img src="assets/img/noimg.png" style="width:35px;">
                            </a>
                        </div>
                        <div *ngIf="item.url_img !== ''">
                            <a data-toggle="modal" data-target="#selectproduct" (click)="selectproduct(item)"> <img
                                    [src]="item.url_img" style="width: 40px;" /></a>
                        </div>
                    </td>
                    <td><a data-toggle="modal" data-target="#selectproduct" (click)="selectproduct(item)"
                            class="text-primary">{{item.name}}</a></td>
                    <td>{{item.sku}}</td>
                    <td>{{item.stock_quantity}}</td>
                    <td>
                        <div *ngFor="let items of costdata">
                            <div *ngIf="items.ID_product == item.ID_product">
                                {{items.new_cost|number:"2.2"}}
                            </div>
                        </div>
                    </td>
                    <td>{{item.price|number:"2.2"}}</td>
                    <td>
                        <div *ngFor="let items of wholesales">
                            <div *ngIf="items.ID_product == item.ID_product">
                                {{items.wholesale|number:"2.2"}}
                            </div>
                        </div>
                    </td>
                    <th>
                        <div *ngIf="item.low_stock_quantity >  item.stock_quantity ">
                            <div *ngIf="item.stock_quantity  > 0" class="text-warning">สินค้าใก้ลหมด</div>
                            <div *ngIf="item.stock_quantity  === 0" class="text-danger">สินค้าหมดแล้ว</div>
                        </div>
                        <div *ngIf=" item.stock_quantity ===  item.low_stock_quantity   " class="text-info">
                            จำนวนถึงจุดน้อยสุด</div>
                        <div *ngIf=" item.stock_quantity >  item.low_stock_quantity   " class="text-success">จำนวนปกติ
                        </div>

                    </th>
                </tr>
            </tbody>
        </table>
        <hr>
        <div class="d-flex flex-row-reverse bd-highlight">
            <div class="p-2 bd-highlight">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>

    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="selectproduct" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ชื่อ-สินค้า:<strong>{{Productslt.name|uppercase}}</strong></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="d-flex justify-content-center" *ngIf="Productslt.url_img == ''"> <img
                            src="assets/img/noimg.png" class="brand-image img-circle elevation-5" style="width: 20%;">
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="Productslt.url_img != ''">
                        <img [src]="Productslt.url_img" class="brand-image img-circle elevation-5" style="width: 10%;">
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-sm-3">รหัส-สินค้า</div>
                        <div class="col-sm-3">{{Productslt.sku}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-3">จำนวน-สินค้าในคลัง</div>
                        <div class="col-sm-3">{{Productslt.stock_quantity}}</div>
                        <div class="col-sm-3">จำนวน-สินค้าจุดน้อยสุด</div>
                        <div class="col-sm-3">{{Productslt.low_stock_quantity}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-3">ราคา-สินค้า: {{Productslt.price}} </div>

                        <div class="col-sm-3">ราคา-สินค้าP2: {{wholesale.wholesale}}</div>
                        <div class="col-sm-3">ราคา-สินค้าP3: {{wholesale.P3}}</div>
                        <div class="col-sm-3">ราคา-สินค้าP4: {{wholesale.P4}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-3">สถานะ</div>
                        <div class="col-sm-3">
                            <div *ngIf="Productslt.low_stock_quantity >  Productslt.stock_quantity">
                                <div *ngIf="Productslt.stock_quantity  > 0" class="text-warning">สินค้าใก้ลหมด</div>
                                <div *ngIf="Productslt.stock_quantity  === 0" class="text-danger">สินค้าหมดแล้ว</div>
                            </div>
                            <div *ngIf=" Productslt.stock_quantity ===  Productslt.low_stock_quantity   "
                                class="text-info">
                                จำนวนถึงจุดน้อยสุด</div>
                            <div *ngIf=" Productslt.stock_quantity >  Productslt.low_stock_quantity   "
                                class="text-success">จำนวนปกติ
                            </div>
                            <div *ngIf=" Productslt.stock_quantity === 0  " class="text-danger">สินค้าหมดแล้ว
                            </div>

                        </div>
                        <div class="col-sm-3">วันหมดอายุ</div>
                        <div class="col-sm-3">{{Productslt.expiration_date|date:'dd/MM/yyyy'}}</div>
                    </div>


                </div>

            </div>
            <div class="card-footer">
                <button data-toggle="modal" (click)="edit(Productslt)" class="btn btn-sm btn-warning"
                    data-target="#addproduct"><i class="fa fa-edit" aria-hidden="true"></i>แก้ไข
                </button>
                &nbsp;
                <button *ngIf="statusUser.status == 'administrator' " class="btn btn-sm btn-danger"
                    (click)="delproduct(Productslt)" data-dismiss="modal"><i class="fa fa-trash"
                        aria-hidden="true"></i>ลบ
                </button>
                &nbsp;
                <button *ngIf="statusUser.status == 'administrator' " class="btn btn-sm btn-info" data-dismiss="modal"
                    data-toggle="modal" data-target="#EditProductPrice" (click)="EditProductPrice(Productslt)"><i
                        class="fa fa-marker" aria-hidden="true"></i>กำหนดราคา
                </button>
                &nbsp;
                <button *ngIf="statusUser.status == 'administrator' " class="btn btn-sm btn-info" data-dismiss="modal"
                    data-toggle="modal" data-target="#EditProductcost" (click)="EditProductcost(Productslt)"> <i
                        class="fa fa-cogs" aria-hidden="true"></i> กำหนดราคาต้นทุน
                </button>
            </div>
        </div>
    </div>
</div>



<!-- Modal -->
<div class="modal fade" id="addproduct" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="Product.id === null && havedataproduct !== true ">เพิ่มสินค้า</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-5">
                        <label for="">รหัส-สินค้า:<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="กรอก รหัส-สินค้า" [(ngModel)]="Product.sku"
                            name="" id="">
                    </div>
                    <div class="col-sm-6">
                        <label for="">ชื่อ-สินค้า:<strong class="text-danger">*</strong></label>
                        <input type="text" [(ngModel)]="Product.name" placeholder="กรอก ชื่อ-สินค้า"
                            class="form-control" name="" id="">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <label for="">จำนวน:<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="กรอก จำนวน"
                            [(ngModel)]="Product.stock_quantity" name="" id="">
                    </div>
                    <div class="col-sm-3">
                        <label for="">จำนวนน้อยที่สุด</label>
                        <input type="text" class="form-control" placeholder="กรอก จำนวนน้อยที่สุด"
                            [(ngModel)]="Product.low_stock_quantity" name="" id="">
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <label for="">ราคา:<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="กรอก ราคา"
                            [(ngModel)]="Product.regular_price" name="" id="">
                    </div>
                    <div class="col-sm-3" *ngIf="Product.id === null ">
                        <label for="">ต้นทุนต่อหน่วย:<strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" placeholder="กรอก ต้นทุนต่อหน่วย"
                            [(ngModel)]="cost_product.new_cost" name="" id="">
                    </div>
                </div>
                <div class="row" *ngIf="Product.id === null ">
                    <div class="col-sm-4">
                        <label for="">ราคาขายส่งP2</label>
                        <input type="text" class="form-control" placeholder="กรอก ราคาขายส่งP2"
                            [(ngModel)]="wholesalesobj.wholesale" name="" id="">
                    </div>
                    <div class="col-sm-4">
                        <label for="">ราคาขายส่งP3</label>
                        <input type="text" class="form-control" placeholder="กรอก ราคาขายส่งP3"
                            [(ngModel)]="wholesalesobj.P3" name="" id="">
                    </div>
                    <div class="col-sm-4">
                        <label for="">ราคาขายส่งP4</label>
                        <input type="text" class="form-control" placeholder="กรอก ราคาขายส่งP4"
                            [(ngModel)]="wholesalesobj.P4" name="" id="">
                    </div>
                </div>
                <div class="row">

                    <div class="col-sm-6">
                        <label for="">วันหมดอายุ:<strong class="text-danger">*</strong></label>
                        <input type="date" [(ngModel)]="Product.expiration_date" placeholder="กรอก  วันหมดอายุ"
                            class="form-control" name="" id="">
                    </div>
                </div>
                <hr>
                <div *ngIf="Product.id === null"><a data-toggle="modal" data-target="#havedata" data-dismiss="modal"
                        class="text-primary"><u>มีข้อมูลสินค้าแล้ว</u></a></div>
            </div>

            <div class="modal-footer">

                <button type="button" data-dismiss="modal" *ngIf="Product.ID === null" class="btn btn-success"
                    (click)="saveproduct()">บันทึก</button>
                <button type="button" data-dismiss="modal" *ngIf="Product.ID !== null" class="btn btn-warning"
                    data-dismiss="modal" (click)="editproduct()">แก้ไข</button>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="havedata" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="havedataproduct !== true">
                    <div class='col-sm-6'>
                        <label for="">โปรดหรอกรหัสสินค้า</label>
                        <div class="input-group mb-3">
                            <input type="search"  placeholder="กรอก รหัสสินค้าแล้ว กด Enter" (keyup.enter)="havedata($event)"
                            [(ngModel)]="datasku" name="" class="form-control" id="">
                            <div class="input-group-append">
                              
                            </div>
                        </div>
        
                 
                    </div>
                </div>
                <div class="container-fluid" *ngIf="havedataproduct === true">


                    <div class="row">
                        <div class="col-sm-5">
                            <label for="">รหัส-สินค้า:<strong class="text-danger">*</strong></label>
                            <input type="text" readonly="readonly" class="form-control" placeholder="กรอก รหัส-สินค้า"
                                [(ngModel)]="Product.sku" name="" id="">
                        </div>
                        <div class="col-sm-6">
                            <label for="">ชื่อ-สินค้า:<strong class="text-danger">*</strong></label>
                            <input type="text" readonly="readonly" [(ngModel)]="Product.name"
                                placeholder="กรอก ชื่อ-สินค้า" class="form-control" name="" id="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <label for="">จำนวน:<strong class="text-danger">*</strong></label>
                            <input type="text" class="form-control" placeholder="กรอก จำนวน" [(ngModel)]="qty_product"
                                name="" id="">
                        </div>
                        <div class="col-sm-3">
                            <label for="">จำนวนน้อยที่สุด</label>
                            <input type="text" class="form-control" placeholder="กรอก จำนวนน้อยที่สุด"
                                [(ngModel)]="Product.low_stock_quantity" name="" id="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <label for="">ราคา:<strong class="text-danger">*</strong></label>
                            <input type="text" class="form-control" placeholder="กรอก ราคา" [(ngModel)]="price_product"
                                name="" id="">
                        </div>
                        <div class="col-sm-3">
                            <label for="">ต้นทุนต่อหน่วย:<strong class="text-danger">*</strong></label>
                            <input type="text" class="form-control" placeholder="กรอก ต้นทุนต่อหน่วย"
                                [(ngModel)]="cost_product.new_cost" name="" id="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label for="">ราคาขายส่งP2</label>
                            <input type="text" class="form-control" placeholder="กรอก ราคาขายส่งP2"
                                [(ngModel)]="wholesalesobj.wholesale" name="" id="">
                        </div>
                        <div class="col-sm-4">
                            <label for="">ราคาขายส่งP3</label>
                            <input type="text" class="form-control" placeholder="กรอก ราคาขายส่งP3"
                                [(ngModel)]="wholesalesobj.P3" name="" id="">
                        </div>
                        <div class="col-sm-4">
                            <label for="">ราคาขายส่งP4</label>
                            <input type="text" class="form-control" placeholder="กรอก ราคาขายส่งP4"
                                [(ngModel)]="wholesalesobj.P4" name="" id="">
                        </div>


                    </div>
                    <div class="row">

                        <div class="col-sm-6">
                            <label for="">วันหมดอายุ:<strong class="text-danger">*</strong></label>
                            <input type="date" [(ngModel)]="Product.expiration_date" placeholder="กรอก  วันหมดอายุ"
                                class="form-control" name="" id="">
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">

                <button type="button" data-dismiss="modal" *ngIf="Product.id !==  null" class="btn btn-success"
                    data-dismiss="modal" (click)="savehavedata()">บันทึก</button>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="EditProductcost" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">กำหนดราคาต้นทุน</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <label>ราคาสินค้า:</label>
                        <input type="text" placeholder="" class="form-control" readonly="readonly"
                            [(ngModel)]="Product.regular_price" name="" id="">

                    </div>
                    <div class="row">
                        <label>ราคาต้นทุน:</label>
                        <input type="number" placeholder="" class="form-control" [(ngModel)]="cost_product.new_cost"
                            name="" id="">

                    </div>
                </div>
            </div>
            <div class="modal-footer">

                <button type="button" class="btn btn-success" data-dismiss="modal" (click)="savecost()">บันทึก</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="EditProductPrice" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">กำหนดราคาสินค้าขายส่ง</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">ราคา-สินค้าปกติ</label>
                            <input type="text" [(ngModel)]="Product.regular_price" class="form-control" readonly>
                        </div>

                    </div>
                    <div class="col-sm-6">
                        <label for="">ราคาส่ง P2:</label>
                        <input type="text" [(ngModel)]="wholesalesobj.wholesale" placeholder="กรอก ราคาส่ง P2"
                            class="form-control">
                    </div>
                    <div class="col-sm-6">
                        <label for="">ราคาส่ง P3:</label>
                        <input type="text" [(ngModel)]="wholesalesobj.P3" placeholder="กรอก ราคาส่ง P3"
                            class="form-control">
                    </div>
                    <div class="col-sm-6">
                        <label for="">ราคาส่ง P4:</label>
                        <input type="text" [(ngModel)]="wholesalesobj.P4" placeholder="กรอก ราคาส่ง P4"
                            class="form-control">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div *ngIf="wholesalesobj.ID == null">
                    <button type="button" data-dismiss="modal" (click)="savewholesale()"
                        class="btn btn-success">บันทึก</button>
                </div>
                <div *ngIf="wholesalesobj.ID != null">
                    <button type="button" data-dismiss="modal" (click)="savewholesale()"
                        class="btn btn-warning">แก้ไข</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="excproduct" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
            </div>
            <div class="modal-body">
              <button type="button" class="btn btn-primary"  data-toggle="modal" data-target="#lookupdata" >เพิ่มข้อมูลการแตกสินค้า</button>
                <hr>
                <div >
                    <div class="card ">
                      <div class="card-body">
                          <div class="row">
                              <div class="col-sm-3">
                                  <input type="text" placeholder="ค้นหาสินค้า" class="form-control" [(ngModel)]="searchdistribute" name="" id="">
                              </div>
                          </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>ชื่อสินค้า</th>
                                        <th>จำนวน</th>
                                        <th></th>
                                        
                                        <th>ชื่อสินค้า</th>
                                        <th>จำนวน</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of datadistribute |filter:searchdistribute" >
                                        <td scope="row">{{item.nameform}}</td>
                                        <td>{{item.qty_form}}</td>
                                        <td><strong class="text-dark"><i class="fas fa-long-arrow-alt-right"></i></strong></td>
                                        <td>{{item.nameto}}</td>
                                        <td>{{item.qty_to}}</td>
                                        <td><button type="button" (click)="distributeproduct(item)" class="btn  btn-sm  btn-primary"><i class="fa fa-check" aria-hidden="true"></i> แตกสินค้า</button></td>
                                    </tr>
                                  
                                </tbody>
                            </table>
                           
                           
                          
                      </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
               
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="lookupdata" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="">จากสินค้า:</label>
                        <input type="text" (keyup.enter)="lookuproduct($event)" placeholder="กรอก รหัสสินค้า" class="form-control" name="" id="">
                    </div>
                </div>
                <div *ngFor="let item of dataform" >
                    <hr>
                    <div class="row">
                        <div class="col-sm-12"><strong>ชื่อสินค้า : {{item.name}}</strong></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12"><strong>จำนวนสินค้าปัจจุบัน : {{item.stock_quantity}}</strong></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6"><strong>จากจำนวน : </strong><input type="number" [(ngModel)]="productqtyform" class='form-control' ></div>
                    </div>
                    <hr>
                </div>
                <div class="col-sm-6" *ngIf="productqtyform !== null" >
                    <label for="">เป็นสินค้า:</label>
                    <input type="text" (keyup.enter)="selectproducts($event)" placeholder="กรอก รหัสสินค้า"  class="form-control" name="" id="">
                </div>
                <div *ngFor="let item of datato" >
                    <hr>
                    <div class="row">
                        <div class="col-sm-12"><strong>ชื่อสินค้า : {{item.name}}</strong></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12"><strong>จำนวนสินค้าปัจจุบัน : {{item.stock_quantity}}</strong></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6"><strong>เป็นจำนวน : </strong><input type="number" [(ngModel)]="productqtyto" class='form-control' ></div>
                    </div>
                    <hr>
                </div>
            </div>
            <div class="modal-footer">
               
                <button type="button" *ngIf=" productqtyto !== null" (click)="savedistributeproduct()" data-dismiss="modal" class="btn btn-success">บันทึกข้อมูล</button>
            </div>
        </div>
    </div>
</div>