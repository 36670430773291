import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {enableProdMode} from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import {Routes, RouterModule } from '@angular/router';
import { ProductsComponent } from './product/products/products.component';
import { MemberComponent } from './member/member/member.component';

import { CustomerComponent } from './report/customer/customer.component';

import { ReportComponent } from './report/report/report.component';
import { AccountComponent } from './account/account.component';
import { CartComponent } from './cart/cart.component';
import { EmployeeComponent } from './employee/employee.component';
import { CommonModule } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MenuadminComponent } from './menuadmin/menuadmin.component';
import { JwPaginationComponent } from 'jw-angular-pagination';
import { PersonalComponent } from './report/personal/personal.component';
import { AccountsComponent } from './report/accounts/accounts.component';
import { YearComponent } from './sales/year/year.component';
import { MonthComponent } from './sales/month/month.component';
/*--- Server ------- */
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { ShareService } from './ShareService';
import {Auth} from './auth';
import { api } from './api';
import { DataAdminComponent } from './admin/data-admin/data-admin.component';
import { ConfigComponent } from './admin/config/config.component';

/*-----------------------------------------*/
import { ChartsModule } from 'ng2-charts';
import { DayComponent } from './sales/day/day.component';
import { SelegoodComponent } from './sales/selegood/selegood.component';

import { GetProductComponent } from './product/get-product/get-product.component';
import { OrderComponent } from './orders/order/order.component';
/*---------------- pipe  -------------------------*/
import { TimeAgoThaiPipe } from './pipe/TimeAgoThaiPipe';
import { DatePipe } from '@angular/common'

/*----------------   -------------------------*/
import {NgxPrintModule} from 'ngx-print';
import { NgxBarcodeModule } from 'ngx-barcode';
import { OrderModule } from 'ngx-order-pipe';
import { ForntsaleComponent } from './orders/forntsale/forntsale.component';
import { NgBarcodeDetectorModule } from 'ng-barcode-detector';
import { LoginComponent } from './login/login.component';
import { CookieService } from 'ngx-cookie-service';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { SuccessbuyComponent } from './cart/successbuy/successbuy.component';

import { CategoriesComponent } from './product/categories/categories.component';
import { TagsComponent } from './product/tags/tags.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
import { BranchComponent } from './branchs/branch/branch.component';
import { ManageproductComponent } from './manage/manageproduct/manageproduct.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { GetproductandcostComponent } from './manage/getproductandcost/getproductandcost.component';
import { TransferproductComponent } from './transfer/transferproduct/transferproduct.component';
import { DebtorComponent } from './debtor/debtor/debtor.component'
const routes: Routes = [
  {path:'',redirectTo:'/login' ,pathMatch:'full'},
  {path: 'login' , component: LoginComponent},
  {path: 'Dashboard' , component: DashboardComponent},
  //{path: 'products' , component: ProductsComponent},
  {path: 'member' , component: MemberComponent},
  {path: 'report' , component: ReportComponent},
  {path: 'account' , component: AccountComponent},
  {path: 'cart' , component: CartComponent},
  {path: 'emp' , component: EmployeeComponent},
  {path: 'admin' , component: DataAdminComponent},
  {path: 'selesyear' , component: YearComponent},
  {path: 'selesmonth' , component: MonthComponent},
  {path: 'Menu' , component: MenuadminComponent},
  {path: 'selesday' , component: DayComponent},
  {path: 'GetProduct' , component: GetProductComponent},
  //{path: 'orders' , component: OrderComponent},
  {path: 'forntsales' , component: ForntsaleComponent},
  {path: 'successbuy' , component: SuccessbuyComponent},
  {path: 'categories' , component: CategoriesComponent},
  {path: 'tags' , component: TagsComponent},
  {path: 'config' , component: ConfigComponent},
  {path: 'branch' , component: BranchComponent},
  {path: 'manageproduct' , component: ManageproductComponent},
  {path: 'transfer' , component: TransferproductComponent},
  {path: 'debtor' , component: DebtorComponent},
  {path: '**' , redirectTo:'/login'},
]
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ProductsComponent,
    MemberComponent,
    CustomerComponent,
    
    ReportComponent,
    AccountComponent,
    CartComponent,
    EmployeeComponent,
    MenuadminComponent,
    PersonalComponent,
    AccountsComponent,
    DataAdminComponent,
    ConfigComponent,
    YearComponent,
    MonthComponent,
    DayComponent,
    SelegoodComponent,
    
    GetProductComponent,
    OrderComponent,
    TimeAgoThaiPipe,
    ForntsaleComponent,
    LoginComponent,
    SuccessbuyComponent,
    TagsComponent,
    CategoriesComponent,
    BranchComponent,
    ManageproductComponent,
    GetproductandcostComponent,
    TransferproductComponent,
    DebtorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(routes),
    FormsModule ,
    HttpClientModule,
    CommonModule,
    Ng2SearchPipeModule,
    ChartsModule,
    NgxPrintModule,
    NgBarcodeDetectorModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    NgxBarcodeModule,
    ReactiveFormsModule,
    OrderModule,
    CKEditorModule,
    NgxPaginationModule
  ],
  
  providers: [ShareService,
    CookieService,
    Auth,
    api,
    DatePipe,
    
    ],
  bootstrap: [AppComponent]
})
export class AppModule { } 
enableProdMode();