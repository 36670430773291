
<!--
<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header border-0">
                <div class="d-flex justify-content-between">
                    <h3 class="card-title">ยอดการขายเดือนนี้</h3>
                    <a [routerLink]="['/selesmonth']">ดูยอดทั้งหมด</a>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex">
                    <p class="d-flex flex-column">
                        <span class="text-bold text-lg">820</span>
                        <span>รวมสินค้าที่ขายทั้งหมด</span>
                    </p>
                    <p class="ml-auto d-flex flex-column text-right">
                        <span class="text-success">
                            <i class="fas fa-arrow-up"></i> 12.5%
                        </span>
                        <span class="text-muted">Since last week</span>
                    </p>
                </div>
               

                <div class="position-relative mb-4">
                    <canvas id="visitors-chart" height="200"></canvas>
                </div>

                <div class="d-flex flex-row justify-content-end">
                    <span class="mr-2">
                        <i class="fas fa-square text-primary"></i> เดือนนี้
                    </span>

                    <span>
                        <i class="fas fa-square text-gray"></i> เดือนที่แล้ว
                    </span>
                </div>
            </div>
        </div>
      

        <div class="card">
            <div class="card-header border-0">
                <h3 class="card-title">สินค้าขายดี</h3>
                <div class="card-tools">
                    <a [routerLink]="['/selesday']" class="btn btn-tool btn-sm">
                        <i class="fas fa-search">&nbsp;ดูทั้งหมด</i>
                    </a>
                    <a href="#" class="btn btn-tool btn-sm">
                        <i class="fas fa-bars"></i>
                    </a>
                </div>
            </div>
            <div class="card-body table-responsive p-0">
                <table class="table table-striped table-valign-middle">
                    <thead>
                        <tr>
                            <th>ชื่อสินค้า</th>
                            <th>ราคา</th>
                            <th>จำนวน</th>
                            <th>เวลา</th>
                            <th>หมายเหตุ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr></tr>
                    </tbody>
                </table>
            </div>
        </div>

      
    </div>
   
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header border-0">
                <div class="d-flex justify-content-between">
                    <h3 class="card-title">ยอดการขายทั้งปี</h3>
                    <a [routerLink]="['/selesyear']">ดูยอดทั้งหมด</a>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex">
                    <p class="d-flex flex-column">
                        <span class="text-bold text-lg">฿18,500,230.00</span>
                        <span>กำไรทั้งหมดปีนี้</span>
                    </p>
                    <p class="ml-auto d-flex flex-column text-right">
                        <span class="text-success">
                            <i class="fas fa-arrow-up"></i> 33.1%
                        </span>
                        <span class="text-muted">ตั้งแต่เดือนที่แล้ว</span>
                    </p>
                </div>
               

                <div class="position-relative mb-4">
                    <canvas id="sales-chart" height="200"></canvas>
                </div>

                <div class="d-flex flex-row justify-content-end">
                    <span class="mr-2">
                        <i class="fas fa-square text-primary"></i> ปีนี้
                    </span>

                    <span>
                        <i class="fas fa-square text-gray"></i> ปีที่แล้ว
                    </span>
                </div>
            </div>
        </div>
      

        <div class="card">
            <div class="card-header border-0">
                <h3 class="card-title">ข้อมูลการขายออนไลน์</h3>
                <div class="card-tools">
                    <a href="#" class="btn btn-sm btn-tool">
                        <i class="fas fa-download"></i>
                    </a>
                    <a href="#" class="btn btn-sm btn-tool">
                        <i class="fas fa-bars"></i>
                    </a>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
                    <p class="text-success text-xl">
                        <i class="ion ion-ios-refresh-empty"></i>
                    </p>
                    <p class="d-flex flex-column text-right">
                        <span class="font-weight-bold">
                            123
                        </span>
                        <span class="text-muted">คำสั่งซื้อวันนี้</span>
                    </p>
                </div>
               
                <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
                    <p class="text-warning text-xl">
                        <i class="ion ion-ios-cart-outline"></i>
                    </p>
                    <p class="d-flex flex-column text-right">
                        <span class="font-weight-bold">
                            0.8%
                        </span>
                        <span class="text-muted">จำนวนสินค้าที่อยู่ในตะกร้า</span>
                    </p>
                </div>
               
                <div class="d-flex justify-content-between align-items-center mb-0">
                    <p class="text-danger text-xl">
                        <i class="ion ion-ios-people-outline"></i>
                    </p>
                    <p class="d-flex flex-column text-right">
                        <span class="font-weight-bold">
                            <i class="ion ion-android-arrow-down text-danger"></i> 1%
                        </span>
                        <span class="text-muted">จำนวนผู้เข้าชมเว็บไซต์</span>
                    </p>
                </div>
              
            </div>
        </div>
        <div class="card">
            <div class="card-header border-0">
                <h3 class="card-title">ลำดับสมาชิกที่มียอดซื้อมากที่สุด</h3>
                <div class="card-tools">
                    <a href="#" class="btn btn-tool btn-sm">
                        <i class="fas fa-search"></i> ดูทั้งหมด
                    </a>
                    <a href="#" class="btn btn-tool btn-sm">
                        <i class="fas fa-table"></i>
                    </a>
                </div>
            </div>
            <div class="card-body table-responsive p-0">
                <table class="table table-striped table-valign-middle">
                    <thead>
                        <tr>
                            <th>ชื่อ-สกุล</th>

                            <th>จำนวน</th>
                            <th>ยอดซื้อ</th>
                            <th>เวลา</th>
                            <th>หมายเหตุ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
   
</div>-->
<!-- /.row -->
<div class="card ">
  
  <div class="card-body">  
     
    <div class="d-flex justify-content-center">
        <img src="assets/img/AdminLTELogo.png"  alt="AdminLTE Logo" class="brand-image img-circle elevation-1">
       
    </div>
    
    
  </div>
</div>