<div class="card card-primary">
    <div class="card-header"> <i class="fa fa-users" aria-hidden="true"></i>&nbsp; ข้อมูลบุคลากร </div>
    <div class="card-body">

        <button class="btn btn-success" data-toggle="modal" data-target="#AddEMP" (click)="nulldata()"> <i
                class="fa fa-user-plus" aria-hidden="true"></i>เพิ่มบุคลลากร </button>

        <hr>
        <div class="row">

        </div>

        <div class="row">
            <div class="col-12">
                <!-- Custom Tabs -->
                <div class="card ">
                    <div class="card-header ">
                        <h3 class="card-title "></h3>
                        <ul class="nav nav-pills ">
                            <li class="nav-item"><a class="btn btn-md btn-success " href="#tab_0" data-toggle="tab"><i
                                        class="fas fa-users-cog"></i>&nbsp; superAdmin</a></li>&nbsp;
                            <!-- <li class="nav-item"><a class="btn btn-md btn-success " (click)="alleditor()" href="#tab_1"
                                    data-toggle="tab"><i class="fas fa-users"></i>&nbsp; keyman </a></li>&nbsp;-->
                            <li class="nav-item"><a class="btn btn-md btn-success " (click)="allcashier()" href="#tab_2"
                                    data-toggle="tab"><i class="fas fa-users"></i>&nbsp; cashier </a></li>
                            &nbsp;


                        </ul>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab_0">
                                <div class="row">
                                    <div *ngFor="let item of emp">
                                        <!-- /.เอาไว้loop -->
                                        <div class="col-sm-12">
                                            <div class="info-box ">

                                                <span class="info-box-icon bg-success elevation-1 btn "
                                                    data-toggle="modal" data-target="#lookupAdmin"
                                                    (click)="selectemp(item)"><i class="fas fa-user "> </i></span>
                                                <br>
                                                <div class="info-box-content ">

                                                    <span class="info-box-text "></span>
                                                    <strong>ชื่อ-สกุล:{{item.first_name}}&nbsp;{{item.last_name}}</strong>
                                                    <span class="info-box-text "></span>
                                                    <strong>บทบาท:ผู้จัดการร้าน</strong>


                                                  <!--   <span class="info-box-number ">&nbsp;<button
                                                            (click)="selectemp(item)" class="btn btn-sm btn-warning">
                                                            <i class="fas fa-edit"></i> </button>&nbsp;<button
                                                            class="btn btn-sm btn-danger">
                                                            <i class="fas fa-trash"></i></button>
                                                    </span>-->
                                                </div>
                                                <!-- /.info-box-content -->
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                          <!--    <div class="tab-pane active" id="tab_1">
                                <div class="row">
                                    <div *ngFor="let item of editors">
                                         /.เอาไว้loop
                                        <div class="col-sm-12">
                                            <div class="info-box ">
                                                <span class="info-box-icon bg-success elevation-1 btn "
                                                    data-toggle="modal" data-target="#lookupAdmin"><i
                                                        (click)="selectemp(item)" class="fas fa-user "> </i></span>
                                                <br>
                                                <div class="info-box-content ">

                                                    <span class="info-box-text "></span>
                                                    <strong>ชื่อ-สกุล:{{item.first_name}}&nbsp;{{item.last_name}}</strong>
                                                    <span class="info-box-text "></span>
                                                    <strong>บทบาท:ผู้จัดการร้าน</strong>


                                                    <span class="info-box-number ">&nbsp;<button
                                                            class="btn btn-sm btn-warning">
                                                            <i class="fas fa-edit"></i> </button>&nbsp;<button
                                                            class="btn btn-sm btn-danger">
                                                            <i class="fas fa-trash"></i></button>
                                                    </span>
                                                </div>
                                                .info-box-content
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div> -->
                            <div class="tab-pane active" id="tab_2">
                                <div class="row">
                                    <div *ngFor="let item of cashier">
                                        <!-- /.เอาไว้loop -->
                                        <div class="col-sm-12">
                                            <div class="info-box ">
                                                <span class="info-box-icon bg-success elevation-1 btn "
                                                    data-toggle="modal" (click)="selectemp(item)"
                                                    data-target="#lookupAdmin"><i class="fas fa-user "> </i></span>
                                                <br>
                                                <div class="info-box-content ">

                                                    <span class="info-box-text "></span>
                                                    <strong>ชื่อ-สกุล:{{item.first_name}}&nbsp;{{item.last_name}}</strong>
                                                    <span class="info-box-text "></span>
                                                    <strong>บทบาท:เจ้าหน้าขายสินค้า</strong>


                                                 <!--   <span class="info-box-number ">&nbsp;<button
                                                            class="btn btn-sm btn-warning">
                                                            <i class="fas fa-edit"></i> </button>&nbsp;<button
                                                            class="btn btn-sm btn-danger">
                                                            <i class="fas fa-trash"></i></button>
                                                    </span>-->
                                                </div>
                                                <!-- /.info-box-content -->
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <!-- /.tab-pane -->
                        </div>
                        <!-- /.tab-content -->
                    </div>
                </div>
                <!-- ./card -->
            </div>
            <!-- /.col -->
        </div>
    </div>

</div>


<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="AddEMP" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="contrainer">
                    <div class="row">

                        <div class="col-sm-3">
                            <label for="">อีเมล:</label>
                            <input type="text" class="form-control" [(ngModel)]="employee.email"
                                placeholder="กรอก อีเมล">
                        </div>
                        <div class="col-sm-3">
                            <label for="">เบอร์โทร:</label>
                            <input type="text" class="form-control" [(ngModel)]="employee.phone"
                                placeholder="กรอก ชื่อ">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <label for="">ชื่อ:</label>
                            <input type="text" class="form-control" [(ngModel)]="employee.first_name"
                                placeholder="กรอก ชื่อ">
                        </div>
                        <div class="col-sm-3">
                            <label for="">สกุล:</label>
                            <input type="text" class="form-control" [(ngModel)]="employee.last_name"
                                placeholder="กรอก สกุล">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-3">
                            <label for="">ชื่อผู้ใช้:</label>
                            <input type="text" class="form-control" [(ngModel)]="employee.username"
                                placeholder="กรอก ชื่อผู้ใช้">
                        </div>
                        <div class="col-sm-3">
                            <label for="">รหัสผ่าน:</label>
                            <input type="password" class="form-control" [(ngModel)]="employee.password"
                                placeholder="กรอก รหัสผ่าน">
                        </div>

                    </div>
                    <hr>
                   <!-- <div class="row">
                        <div class="col-sm-6">
                            <label for="">สาขา:</label>
                            <select name="" class='form-control' (change)="selectbranchs($event)" id="">
                                <option value="">---โปรดเลือกสาขา---</option>
                                <option *ngFor="let item of branchs" value="{{item.id}}">
                                    {{item.dokan_profile_settings.store_name}}</option>
                            </select>
                        </div>
                    </div>-->
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">ที่อยู่:</label>
                            <textarea class="form-control" [(ngModel)]="employee.address_1" placeholder="กรอก ที่อยู่"
                                name="" id="" rows="3"></textarea>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <label for="">อำเภอ</label>
                            <input type="text" class="form-control" [(ngModel)]="employee.city"
                                placeholder="กรอก อำเภอ">
                        </div>
                        <div class="col-sm-3">
                            <label for="">จังหวัด:</label>
                            <input type="text" class="form-control" [(ngModel)]="employee.state"
                                placeholder="กรอก จังหวัด">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <label for="">เลขที่ไปรษณี:</label>
                            <input type="text" class="form-control" [(ngModel)]="employee.postcode"
                                placeholder="กรอก เลขที่ไปรษณี">

                        </div>
                        <div class="col-sm-3">
                            <label for="">บทบาท:</label>
                            <select name="" id="" (change)='selectroles($event)' class="form-control">
                                <option value="">---โปรดเลือกบทบาท---</option>
                                <option value="administrator">ผู้ควบคุม&ดูแลระบบ</option>
                               <!-- <option value="editor">ผู้เขียนข้อมุล</option>-->
                                <option value="shop_manager">เจ้าหน้าขายสินค้า</option>

                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">

                <button type="button" class="btn btn-success" (click)="save()">บันทึก</button>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="lookupAdmin" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ข้อมูลบุคลากร</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-3">
                        <strong>ชื่อ-สกุล:</strong>
                    </div>
                    <div class="col-sm-3">
                        {{employees.first_name}} &nbsp;{{employees.last_name}}
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-3">
                        <strong>email:</strong>
                    </div>
                    <div class="col-sm-3">
                        {{employees.email}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <strong>เบอร์โทรศัพท์:</strong>
                    </div>
                    <div class="col-sm-3">
                        {{billing.phone}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <strong>ที่อยู่:</strong>
                    </div>
                    <div class="col-sm-3">
                        {{billing.address_1}}{{billing.address_2}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <strong>สาขา:</strong>
                    </div>
                    <div class="col-sm-3">
                        {{billing.company}}
                    </div>
                </div>

            </div>
            <div class="modal-footer">
             <!--   <button type="button" data-toggle="modal" data-target="#selectbranchs" class="btn btn-primary btn-sm"><i
                        class="fa fa-cogs" aria-hidden="true"></i>กำหนดสาขา</button>&nbsp;-->
                <button type="button" data-toggle="modal" (click)="checkdrawer(employees.id)"
                    data-target="#selectuserdrw" class="btn btn-warning btn-sm"><i class="fa fa-cogs"
                        aria-hidden="true"></i>กำหนดการใช้ลิ้นชัก</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal 
<div class="modal fade" id="selectbranchs" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">กำหนดสาขาสำหรับบุคลากร</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <label for="">ชื่อผู้ใช้</label>
                    <input type="text" name="" id="" readonly="readonly" class="form-control"
                        [(ngModel)]="employees.username">
                    <div class="row">
                        <label for="">เลือกสาขา</label>

                        <select class="form-control" (change)="changebranchs($event)">
                            <option value="">---โปรดเลือกสาขา---</option>
                            <option *ngFor="let item of branchs" value="{{item.id}}">
                                {{item.dokan_profile_settings.store_name}}</option>
                        </select>
                    </div>
                </div>

            </div>
            <div class="modal-footer">

                <button type="button" (click)="changebranch(employees)" data-dismiss="modal"
                    class="btn btn-primary">ตกลง</button>
            </div>
        </div>
    </div>
</div> -->
<div class="modal fade" id="selectuserdrw" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">กำหนดการใช้ลิ้นชักสำหรับบุคลากร</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <label for="">ชื่อผู้ใช้</label>
                    <input type="text" name="" id="" readonly="readonly" class="form-control"
                        [(ngModel)]="employees.username">
                    <div class="row" *ngIf="managedrawer">
                        <label for="">เลือกลิ้นชัก</label>

                        <select class="form-control" (change)="drawerselect($event)">
                            <option value="">---โปรดเลือกลิ้นชัก---</option>
                            <option *ngFor="let items of drawers" value="{{items.ID}}">
                                รหัสลิ้นชัก:{{items.ID}}-{{items.name}}</option>
                        </select>
                    </div>
                </div>
                <hr>
                <div *ngIf="!managedrawer" class="alert alert-primary" role="alert">
                    <strong>รหัสลิ้นชัก: {{datadrawer.ID_drw}}-{{datadrawer.name}} </strong>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!managedrawer" data-dismiss="modal" data-toggle="modal"
                    data-target="#changedrewar" class="btn btn-warning">เปลี่ยนการใช้ลิ้นชัก</button>
                <button type="button" *ngIf="managedrawer" (click)="changedrawers(employees)" data-dismiss="modal"
                    class="btn btn-primary">ตกลง</button>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="changedrewar" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">การเปลี่ยนลิ้นชัก</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <h3><strong>ข้อมูลลิ้นชักเดิม</strong></h3>
                    <div class="row">
                        <div class="col-sm-3"><strong>รหัสลิ้นชัก</strong></div>
                        <div class="col-sm-3">{{datadrawer.ID_drw}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6"><strong>กำหนดการใช้งานล่าสุด</strong></div>
                        <div class="col-sm-6">{{datadrawer.date_update|date:'dd/MM/yyyy HH:ss'}}</div>
                       
                    </div>
                    <hr>
                </div>
                <h3><strong>เปลี่ยนลิ้นชัก</strong></h3>
                <div class="row">
                    <select class="form-control" (change)="drawerselect($event)">
                        <option value="">---โปรดเลือกลิ้นชัก---</option>
                        <option *ngFor="let items of drawers" value="{{items.ID}}">
                            รหัสลิ้นชัก:{{items.ID}}-{{items.name_acc}}</option>
                    </select>
                   
                  
                    <label for="">หมายเหตุ <strong class="text-danger">*</strong></label>
                    <textarea class="form-control" name="" placeholder="โปรดกรอกหมายเหตุ การเปลี่ยนลิ้นชัก" [(ngModel)]="remarkchangedrawers" row="5" id="" ></textarea>
                  
                </div>
            </div>
            <div class="modal-footer">

                <button type="button" (click)="changdrawersnew()" data-dismiss="modal" class="btn btn-success">ยืนยันการเปลี่ยน</button>
            </div>
        </div>
    </div>
</div>

