<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div id="prinbarcode">
                        <div class="d-flex justify-content-center">
                            <ngx-barcode *ngFor="let item of printBarcode;let i =index" [bc-value]="item.sku"
                                [bc-display-value]="true"></ngx-barcode>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>


<div class="card card-info">
    <div class="card-header"> <i class="fa fa-box-open" aria-hidden="true"></i> จัดการสินค้า</div>

    <div class="card-body">
       

     
        <div class="row">
            <div class="d-flex justify-content-start">
                <button class="btn btn-info" data-toggle="modal" data-target="#AddProduct" (click)="nullProduct()">
                    <i class="fa fa-inbox" aria-hidden="true"></i> เพิ่มสินค้า <span class="badge badge-primary"></span>
                </button> &nbsp;
                <a class="btn btn-info" [routerLink]="'/GetProduct'">
                    <i class="fa fa-box" aria-hidden="true"></i> รับสินค้า <span class="badge badge-primary"></span>
                </a>&nbsp;
                <a class="btn btn-info" [routerLink]="'/manageproduct'">
                    <i class="fa fa-box" aria-hidden="true"></i> จัดการคลังสินค้า <span class="badge badge-primary"></span>
                </a>&nbsp;
            </div>
            <div class="d-flex justify-content-between">
                <a class="btn btn-info" [routerLink]="'/categories'">
                    <i class="fas fa-tasks"></i> หมวดหมู่สินค้า <span class="badge badge-primary"></span>
                </a>&nbsp;
                <a class="btn btn-info" [routerLink]="'/tags'">
                    <i class="fas fa-tags"></i> ป้ายกำกับสินค้า <span class="badge badge-primary"></span>
                </a>
            </div>

        </div>
        <hr>
        <div class="row ">
            <div class="col-sm-3">


                <div class="input-group mb-3">
                    <input type="search" class="form-control" [(ngModel)]="seacrhSKU" placeholder="ค้นหาสินค้า">
                    <div class="input-group-append">
                        <button class="btn btn-success" (click)="seacrhSKUs()"><i class="fa fa-search"
                                aria-hidden="true"></i></button>
                    </div>
                </div>

            </div>
            <div class="col-sm-3">




            </div>
            <div class="col-sm-6">
                <strong class="text-info">หน้าที่:{{pageOfItems}}</strong>
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item"><a class="btn page-link" *ngIf="pageOfItems > 1" (click)="backpage()">ก่อนหน้า</a>
                      </li>
                      <div *ngFor="let item of pages">
                        <li class="page-item"><a class="btn page-link" (click)="nextpagenumber(item)">{{item}}</a></li>
                      </div>
                      <li class="page-item"><a class=" btn page-link"  (click)="nextpage()">ถัดไป</a></li>
                    </ul>
                  </nav>
             
            </div>



        </div>

        <table class="table  table-inverse table-responsive ">
            <thead class="thead-inverse">
                <tr>
                    <th>#</th>
                    <th>ลำดับ</th>
                    <th>รูป-สินค้า</th>
                    <th>ชื่อ-สินค้า</th>
                    <th>รหัส-สินค้า</th>
                    <th>จำนวน</th>
                    <th>ราคา <br>ต้นทุน</th>
                    <th>ราคา <br> สินค้าปกติ</th>
                    <th>ราคา <br> สินค้าส่ง</th>
                    <th>สถานะ</th>


                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Products;let i =index">
                    <td><button type="button" data-toggle="modal" data-target="#selectproduct"
                            (click)="selectproduct(item)" class="btn btn-sm btn-outline text-info">
                            <i class="fa fa-search" aria-hidden="true"></i></button>
                    </td>
                    <td>
                        <a data-toggle="modal" href="#" data-target="#selectproduct"
                            (click)="selectproduct(item)">{{i+1}}</a>
                    </td>
                    <td>
                        <div *ngIf="item.images == ''">
                            <a data-toggle="modal" data-target="#selectproduct" (click)="selectproduct(item)">
                                <img src="assets/img/noimg.png" style="width:35px;">
                            </a>
                        </div>
                        <div *ngFor="let item of item.images">
                            <a data-toggle="modal" data-target="#selectproduct" (click)="selectproduct(item)"> <img
                                    [src]="item.src" style="width: 40px;" /></a>
                        </div>
                    </td>
                    <td><a href="#" data-toggle="modal" data-target="#selectproduct"
                            (click)="selectproduct(item)">{{item.name}}</a></td>
                    <td>{{item.sku}}</td>
                    <td>
                        <div *ngIf="item.stock_quantity == null || item.stock_quantity == 0 " >
                            <a [routerLink]="'/GetProduct'" class="text-danger">สินค้าหมดแล้ว</a>
                        </div>
                        <div *ngIf="item.stock_quantity != null && item.stock_quantity != 0 ">
                            {{item.stock_quantity}} </div>
                    </td>
                    <td>
                        <div *ngFor="let items of costdata">
                            <div *ngIf="items.ID_product == item.id">
                                {{items.new_cost|number:"2.2"}}
                            </div>
                        </div>
                    </td>
                    <td>{{item.regular_price|number:"2.2"}}</td>
                    <td>
                        <div *ngFor="let items of wholesales">
                            <div *ngIf="items.ID_product == item.id">
                                {{items.wholesale|number:"2.2"}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="custom-control custom-switch" *ngIf="item.status != 'publish'">
                            <input type="checkbox" (click)="publish(item)" class="custom-control-input"
                                id="{{item.id}}">
                            <label class="custom-control-label" for="{{item.id}}">-<strong
                                    class="text-warning">ไม่เผยแพร่</strong></label>
                        </div>
                        <div class="custom-control custom-switch" *ngIf="item.status == 'publish'">
                            <input type="checkbox" checked (click)="closepublish(item)" class="custom-control-input"
                                id="{{item.id}}">
                            <label class="custom-control-label" for="{{item.id}}">-<strong
                                    class="text-success">เผยแพร่แล้ว</strong></label>
                        </div>
                    </td>
                    <td><button type="button" class="btn btn-sm btn-dark" data-toggle="modal" data-target="#barcode"
                            (click)="createBarcode(item)"><i class="fa fa-barcode" aria-hidden="true"></i></button></td>
                    <td></td>

                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="EditProductPrice" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">กำหนดราคาสินค้าขายส่ง</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">ราคา-สินค้าปกติ</label>
                            <input type="text" [(ngModel)]="Product.regular_price" class="form-control" readonly>
                        </div>

                    </div>
                    <div class="col-sm-6">
                        <label for="">ราคาส่ง P2:</label>
                        <input type="text" [(ngModel)]="wholesalesobj.wholesale" placeholder="กรอก ราคาส่ง P2"
                            class="form-control">
                    </div>
                    <div class="col-sm-6">
                        <label for="">ราคาส่ง P3:</label>
                        <input type="text" [(ngModel)]="wholesalesobj.P3" placeholder="กรอก ราคาส่ง P3"
                            class="form-control">
                    </div>
                    <div class="col-sm-6">
                        <label for="">ราคาส่ง P4:</label>
                        <input type="text" [(ngModel)]="wholesalesobj.P4" placeholder="กรอก ราคาส่ง P4"
                            class="form-control">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div *ngIf="wholesalesobj.ID == null">
                    <button type="button" (click)="savewholesale()" class="btn btn-success">บันทึก</button>
                </div>
                <div *ngIf="wholesalesobj.ID != null">
                    <button type="button" (click)="savewholesale()" class="btn btn-warning">แก้ไข</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="selectproduct" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-lg " role="document">
        <div class="modal-content">
            <!-- ลูปตรงนี้จ้าา -->
            <div class="modal-header">
                <h5 class="modal-title">ชื่อ-สินค้า:{{Productslt.name}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="d-flex justify-content-center" *ngIf="Productslt.images == ''" >
                    <img src="assets/img/noimg.png" class="brand-image img-circle elevation-5" style="width: 20%;"> 
                   
                </div>
                <div class="d-flex justify-content-center" *ngIf="Productslt.images != ''"  >
                        <img *ngFor="let item of Productslt.images"  [src]="item.src" class="brand-image img-circle elevation-5" style="width: 10%;">
                </div>
                <div class="container">
                   
                    <hr>
                    <div class="row">
                        <div class="col-sm-3">รหัส-สินค้า</div>
                        <div class="col-sm-3">{{Productslt.sku}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-3">จำนวน-สินค้าในคลัง</div>
                        <div class="col-sm-3">{{Productslt.stock_quantity}}</div>
                        <div *ngIf="Productslt.stock_status === 'instock'" class="text-success">
                            มีสินค้า
                        </div>
                        <div *ngIf="Productslt.stock_status === 'outofstock'" class="text-danger">
                            สินค้าหมดแล้ว
                        </div>
                        <div *ngIf="Productslt.stock_status === 'onbackorder'" class="text-warning">
                            อยู่ระหว่างการจอง
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-3">ราคา-สินค้าปกติ</div>
                        <div class="col-sm-3">{{Productslt.regular_price}}</div>
                        <div class="col-sm-3">ราคา-สินค้าลด</div>
                        <div class="col-sm-3">
                            <div *ngIf="Productslt.sale_price == ''" class="text-danger"> ยังไม่ได้กำหนด </div> {{Product.sale_price}}
                        </div>
                    </div>
                    
                    <hr>
                    <div class="row">
                        <div class="col-sm-3">สถานะ</div>
                        <div class="col-sm-3">
                            <div *ngIf="Productslt.status == 'draft' " class="text-info">
                                <strong>ร่างข้อมูล</strong>
                            </div>
                            <div *ngIf="Productslt.status == 'pending' " class="text-warning">
                                <strong>รอดำเนินการ</strong>
                            </div>
                            <div *ngIf="Productslt.status == 'publish'" class="text-success">
                                <strong>เผยแพร่แล้ว</strong>
                            </div>
                        </div>
                        <div class="col-sm-3">น้ำหนักสินค้า &nbsp;  </div>
                        <div class="col-sm-3">{{Productslt.weight}} </div>
                    </div>
                    <hr>
                
                    <div class="row">
                        <div class="col-sm-3">รายละเอียด-สินค้า</div>
                        <div class="col-sm-3">

                            <div  [innerHTML]="Productslt.description" ></div>

                        </div>
                    </div>
                    <hr>
                </div>

            </div>
            <div class="card-footer">
                <button class="btn btn-sm btn-warning" data-dismiss="modal" data-toggle="modal"
                    data-target="#AddProduct" (click)="edit(Productslt)"><i class="fa fa-edit"
                        aria-hidden="true"></i>แก้ไข
                </button>
                &nbsp;
                <button *ngIf="statusUser.status == 'administrator' " class="btn btn-sm btn-danger"
                    (click)="delectProduct(Productslt)" data-dismiss="modal"><i class="fa fa-trash"
                        aria-hidden="true"></i>ลบ
                </button>
                &nbsp;
                <button *ngIf="statusUser.status == 'administrator' " class="btn btn-sm btn-info" data-dismiss="modal"
                    data-toggle="modal" data-target="#EditProductPrice" (click)="EditProductPrice(Productslt)"><i
                        class="fa fa-marker" aria-hidden="true"></i>กำหนดราคา
                </button>
                &nbsp;
                <button *ngIf="statusUser.status == 'administrator' " class="btn btn-sm btn-info" data-dismiss="modal"
                    data-toggle="modal" data-target="#EditProductcost" (click)="EditProductcost(Productslt)"> <i
                        class="fa fa-cogs" aria-hidden="true"></i> กำหนดราคาต้นทุน
                </button>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="EditProductcost" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">กำหนดราคาต้นทุน</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">ราคา-สินค้าปกติ</label>
                            <input type="number" name="" readonly="readonly" [(ngModel)]="Product.regular_price"
                                class="form-control" id="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">ราคาต้นทุน</label>
                            <input type="number" [(ngModel)]="cost_product.new_cost" placeholder="กรอก ราคาต้นทุน"
                                class="form-control" name="" id="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">

                <button type="button" (click)="savecost()" class="btn btn-success" data-dismiss="modal">ยืนยัน</button>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade responsive " id="AddProduct" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="Product.id==null">เพิ่มสินค้า</h5>
                <h5 class="modal-title" *ngIf="Product.id!=null">แก้ไขสินค้า</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-sm-6">
                                    <label for="">รหัส-สินค้า:<strong class="text-danger">*</strong></label>
                                    <input type="text" class="form-control" [(ngModel)]="Product.sku"
                                        placeholder="กรอก รหัส-สินค้า">
                                </div>
                                <div class="col-sm-6">
                                    <label for="">ชื่อ-สินค้า:<strong class="text-danger">*</strong></label>
                                    <input type="text" class="form-control" [(ngModel)]="Product.name"
                                        placeholder="กรอก ชื่อ-สินค้า">
                                </div>
                                <div class="col-sm-6">
                                    <label for="">ราคา-สินค้าปกติ:<strong class="text-danger">*</strong></label>
                                    <input type="text" [(ngModel)]="Product.regular_price" class="form-control"
                                        placeholder="กรอก ราคา-สินค้าปกติ">
                                </div>
                                <div class="col-sm-6">
                                    <label for="">ราคา-สินค้าลด:</label>
                                    <input type="text" [(ngModel)]="Product.sale_price" class="form-control"
                                        placeholder="กรอก ราคา-สินค้าปกติ">
                                </div>
                                <div class="col-sm-6">
                                    <strong>น้ำหนัก(KG)</strong> <input type="text" [(ngModel)]="Product.weight"
                                        class="form-control" placeholder="กรอก น้ำหนัก">
                                </div>
                                <div class="col-sm-6">
                                    <strong>ความยาว(CM)</strong>
                                    <input type="text" class="form-control" [(ngModel)]="Product.dimensions.length"
                                        placeholder="กรอก ความยาว">
                                </div>
                                <div class="col-sm-6">
                                    <strong>ความกว้าง(CM)</strong>
                                    <input type="text" class="form-control" [(ngModel)]="Product.dimensions.width"
                                        placeholder="กรอก ความกว้าง">
                                </div>
                                <div class="col-sm-6">
                                    <strong>ความสูง(CM)</strong>
                                    <input type="text" [(ngModel)]="Product.dimensions.height" class="form-control"
                                        placeholder="กรอก ความสูง">
                                </div>
                            </div>

                        </div>

                        <div class="col-sm-6">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                                        aria-controls="home" aria-selected="true">เลือกหมวดหมู่สินค้า<strong
                                            class="text-danger">*</strong></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                                        aria-controls="profile" aria-selected="false">เลือกป้ายกำกับสินค้า</a>
                                </li>

                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <div *ngIf="!ManegerProductcategories">
                                        <div *ngFor="let item of categories">
                                            <ul>
                                                <li *ngIf="item.parent == 0">
                                                    <input type="checkbox" (change)="selectcategories($event)" name=""
                                                        id="{{item.id}}" value="{{item.id}}">
                                                    {{item.name}}({{item.count}})

                                                </li>
                                                <ul>
                                                    <li *ngIf=" item.parent  !== 0"> <input type="checkbox"
                                                            (change)="selectcategories($event)" name="" id="{{item.id}}"
                                                            value="{{item.id}}">
                                                        {{item.name}}({{item.count}})
                                                    </li>
                                                </ul>
                                            </ul>
                                        </div>
                                    </div>
                                    <div *ngIf="ManegerProductcategories">
                                        <div *ngFor="let items of Product.categories;let i=index ">

                                            <div *ngFor="let item of categories">

                                                <div *ngIf="items.id == item.id ">
                                                   
                                                    <ul>
                                                        <li *ngIf="item.parent == 0">
                                                            <input type="checkbox" checked  (change)="selectcategories($event)" name=""
                                                                id="{{item.id}}" value="{{item.id}}">
                                                            {{item.name}}({{item.count}})
        
                                                        </li>
                                                        <ul>
                                                            <li *ngIf=" item.parent  !== 0"> <input type="checkbox"
                                                                    (change)="selectcategories($event)" checked name="" id="{{item.id}}"
                                                                    value="{{item.id}}">
                                                                {{item.name}}({{item.count}})
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                                <div *ngIf="items.id != item.id ">
                                                    <li *ngIf="item.parent == 0">
                                                        <input type="checkbox" (change)="selectcategories($event)" name=""
                                                            id="{{item.id}}" value="{{item.id}}">
                                                        {{item.name}}({{item.count}})
    
                                                    </li>
                                                    <ul>
                                                        <li *ngIf=" item.parent  !== 0"> <input type="checkbox"
                                                                (change)="selectcategories($event)" name="" id="{{item.id}}"
                                                                value="{{item.id}}">
                                                            {{item.name}}({{item.count}})
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div *ngIf="!ManegerProducttags">
                                        <div *ngFor="let item of tags">
                                            <input type="checkbox" name="" id="" (change)="selecttag($event)"
                                                value="{{item.id}}"> &nbsp; {{item.name}}
                                        </div>
                                    </div>
                                    <div *ngIf="ManegerProducttags">

                                        <div *ngFor="let items of Product.tags">
                                            <div *ngFor="let item of tags">
                                                <div *ngIf="items.id == item.id ">
                                                    <input type="checkbox" checked (change)="selecttag($event)" name=""
                                                        *ngIf="item.id" id="{{item.id}}" value="{{item.id}}">
                                                    &nbsp;
                                                    {{item.name}}({{item.count}})
                                                </div>
                                                <div *ngIf="items.id != item.id ">
                                                    <input type="checkbox" (change)="selecttag($event)" name=""
                                                        *ngIf="item.id" id="{{item.id}}" value="{{item.id}}">
                                                    &nbsp;
                                                    {{item.name}}({{item.count}})
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">

                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="">รายละเอียดสินค้า:<strong class="text-danger">*</strong></label>
                                <ckeditor [(ngModel)]="Product.description"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-4">
                            <label for="">กำหนดวันที่ ลดราคา &nbsp;
                                <input type="checkbox" [(ngModel)]="ManegerProductsale" data-md-icheck
                                    (change)="Manegerdatesele($event)"> &nbsp;<strong></strong>
                            </label>
                            <div class="row" *ngIf="ManegerProductsale">
                                <div class="col-sm-6">
                                    <label for="">ตั้งแต่วันที่</label>
                                    <input type="date" [(ngModel)]="Product.date_on_sale_from" class="form-control"
                                        name="Product.date_on_sale_from" id="">
                                </div>

                                <div class="col-sm-6">
                                    <label for="">จนถึงวันที่ </label><input type="date"
                                        [(ngModel)]="Product.date_on_sale_to" class="form-control"
                                        name="Product.date_on_sale_to" id="">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label for="">จัดการคลังสินค้า ?</label>&nbsp;
                            <input type="checkbox" [(ngModel)]="Product.manage_stock"
                                (change)="ManegerProductevent($event)">
                            &nbsp;<strong>เปิดใช้งานการจัดการคลังสินค้าที่ระดับสินค้า</strong>
                            <div class="row" *ngIf="Product.manage_stock">
                                <div class="col-sm-4">
                                    <label for="">ปริมาณสินค้าในคลัง:</label>
                                    <input type="number" [(ngModel)]="Product.stock_quantity" class="form-control"
                                        placeholder="กรอก ปริมาณสินค้าในคลัง">
                                </div>

                                <div class="col-sm-8">
                                    
                                </div>

                            </div>
                        </div>

                        <div *ngIf="Product.id == null" class="col-sm-2">
                            <label for=""><strong>กำหนดราคาต้นทุน</strong>&nbsp; <input type="checkbox"
                                    [(ngModel)]="costcheck"> </label>&nbsp;&nbsp;
                            <div *ngIf="costcheck">
                                <div class="col-sm-12">
                                    <label for="">ราคาต้นทุน:<strong class="text-danger">*</strong></label>
                                    <input type="text" class="form-control" [(ngModel)]="cost_product.new_cost"
                                        placeholder="กรอก ราคาต้นทุน" name="" id="">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <hr>
                <div class="row">

                    <div class="col-sm-3">
                        <label for="">สถานะสินค้า?:<strong class="text-danger">*</strong></label>
                        <select class="form-control" (click)="selectstatus($event)" name="" id="">
                            <option value="">---โปรเลือกสถานะสินค้า----</option>
                            <option *ngFor="let item of stock_status" value="{{item}}">
                                <div *ngIf="item === 'instock'">
                                    มีสินค้า
                                </div>
                                <div *ngIf="item === 'outofstock'">
                                    สินค้าหมดแล้ว
                                </div>
                                <div *ngIf="item === 'onbackorder'">
                                    อยู่ระหว่างการจอง
                                </div>
                            </option>
                        </select>

                    </div>
                    <div class="col-sm-3">
                        <label for="">สินค้าสามารถสั่งจองได้?</label>
                        <select class="form-control" name="" id="">
                            <option value="">ไม่ยินยอม</option>
                            <option value="">ยินยอมแต่ต้องเป็นลูกค้า</option>
                            <option value="">ยินยอม</option>
                        </select>

                    </div>
                    <div class="col-sm-6">
                        <div class="row">
                            <label for="">จำกัดการขายแค่ 1 ชิ้น <input type="checkbox">
                                <br><strong>เลือกตัวเลือกนี้หากท่านต้องการให้สินค้านี้ซื้อได้แค่ 1
                                    ชิ้นต่อคำสั่งซื้อ</strong></label>&nbsp;

                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <label for=""><strong>กำหนดราคาส่ง:</strong>&nbsp; <input type="checkbox"
                                [(ngModel)]="wholesaleCheck"> </label>
                    </div>

                    <div class="col-sm-9" *ngIf="wholesaleCheck">
                        <div class="row">
                            <div class="col-sm-4">
                                <label for="">ราคาส่ง P2:<strong class="text-danger">*</strong></label>
                                <input type="text" [(ngModel)]="wholesalesobj.wholesale" placeholder="กรอก ราคาส่ง P2"
                                    class="form-control">
                            </div>
                            <div class="col-sm-4">
                                <label for="">ราคาส่ง P3:</label>
                                <input type="text" [(ngModel)]="wholesalesobj.P3" placeholder="กรอก ราคาส่ง P3"
                                    class="form-control">
                            </div>
                            <div class="col-sm-4">
                                <label for="">ราคาส่ง P4:</label>
                                <input type="text" [(ngModel)]="wholesalesobj.P4" placeholder="กรอก ราคาส่ง P4"
                                    class="form-control">
                            </div>
                        </div>

                    </div>
                </div>
                 <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <label for=""><strong>กำหนดวันหมดอายุสินค้า:</strong>&nbsp; <input type="checkbox"
                                [(ngModel)]="expiration_date"> </label>
                    </div>

                    <div class="col-sm-9" *ngIf="expiration_date">
                        <div class="row">
                            <div class="col-sm-4">
                                <strong>เลือกวันหมดอายุสินค้า</strong>  <strong class="text-danger">*</strong>  <input type="date" [(ngModel)]="Product.expiration_date" placeholder="กรอก ราคาส่ง P2"
                                    class="form-control">
                            </div>
                           
                        </div>

                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-3">
                        <label for=""><strong>รูปภาพ:</strong>&nbsp;  </label>
                        <button type="button"  data-toggle="modal" data-target="#selectimg" (click)="getdata()" class="btn btn-primary" >เลือกรูปภาพ</button>
                    </div>
                    <div class="col-sm-9" *ngIf="urlimg !== null" >
                     
                      
                        <img [src]="urlimg" width="100" height="150">
                    </div>
                </div>
               <br>
                <div class="modal-footer">

                    <button type="button" *ngIf="Product.id == null" (click)="saveproduct()" data-dismiss="modal"
                        class="btn btn-success"><i class="fas fa-save"> &nbsp; บันทึก</i></button>
                    <button type="button" *ngIf="Product.id != null" (click)="saveproduct()" data-dismiss="modal"
                        class="btn btn-warning "><i class="fas fa-edit"> &nbsp; แก้ไข</i></button>
                </div>
            </div>

        </div>
    </div>


</div>
<!-- Modal -->
<div class="modal fade" id="barcode" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content ">
            <div class="modal-header">
                <h5 class="modal-title">บาร์โค้ดสินค้า</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <select name="" class="form-control" (change)="selectamount($event)" id="">
                        <option disabled value="เลือกจำนวน">เลือกจำนวน</option>
                        <option *ngFor="let item of barcode;let i =index" value="{{i+1}}">{{i+1}}</option>

                    </select>
                    <div class="d-flex justify-content-center">
                        <ngx-barcode *ngFor="let item of barcodeProduct" [bc-value]="item.sku"
                            [bc-display-value]="true"></ngx-barcode>
                    </div>
                </div>


            </div>
            <div class="modal-footer">

                <button type="button" class="btn btn-primary" printSectionId="prinbarcode" ngxPrint>ยืนยัน/
                    พิมพ์</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="selectimg" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ข้อมูลรูปภาพ</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" data-toggle="modal" data-target="#addCate">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#upload">อัพโหลดรูปภาพ</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#selectmenu">เลือกรูปภาพ</a>
                        </li>
                    </ul>
                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div class="tab-pane container active " id="upload">
                            <div class="container  mt-5">
                                <div class="row">
                                    <div class="col-sm-2"></div>
                                    <div class="col-sm-8">
                                        <div class="row  d-flex justify-content-center">
                                          
                                                    <div class="col-sm-3">
                                                        <div class="imagePreview d-flex justify-content-center"
                                                            *ngIf="imageURL == null">
                                                            <img src="assets/img/upload.png" width="304" height="236">
                                                        </div>
                                                        <div class="imagePreview d-flex justify-content-center"
                                                            *ngIf="imageURL && imageURL !== null">
                                                            <img [src]="imageURL" width="304" height="236">
                                                        </div>
                                                        <br>

                                                        <input type="file" id="myFile" accept=".jpg, .jpeg, .png"
                                                            multiple name="filename" (change)="fileChange($event)">
                                                            <button class="btn btn-success mt-5" *ngIf="uploadedFiles && uploadedFiles !== null" (click)="upload()">อัพโหลด</button>
                                                    </div>
                                         

                                        </div>



                                    </div>
                                    <div class="col-sm-2"></div>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane container fade" id="selectmenu">
                            <div class="row">
                                <div class="col-sm-9" id="para1">
                                    <div class="row mt-2">
                                        <div class="col-sm-4"> <div class="d-flex justify-content-start"> <button *ngIf="page > 1" type="button" class="btn btn-sm btn-light" (click)="backpagesmedie()">ย้อนกลับ</button></div></div>
                                        <div class="col-sm-2">{{page}}</div>
                                        <div class="col-sm-4">      <div class="d-flex justify-content-end"> <button type="button" (click)="nextpagesmedie()" class="btn btn-sm btn-light">ถัดไป</button></div> </div>
                              
                                     </div>
                                    <div class="row mt-2">
                                        <div *ngFor="let item of media">
                                            <div class="col-sm-3">
                                                <div class="form-check-inline">
                                                    <label class="form-check-label"></label>
                                                    <input type="radio" (click)="selectimg(item)"
                                                        class="form-check-input" name="optradio">
                                                    <img [src]="item.source_url" style="width: 100px;" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                    
                                     </div>
                                </div>
                                <div *ngIf="medias.source_url" class="col-sm-3">
                                    <div class="d-flex justify-content-center"> <img [src]="medias.source_url"
                                            style="width: 150px;" /></div>
                                   
                                    <hr>
                                    <button type="button" data-dismiss="modal"
                                        (click)="selectdataimg(medias) " class="btn btn-success" data-toggle="modal" data-target="#addCate"><i
                                            class="fa fa-check" aria-hidden="true" ></i>ใช้รูปภาพนี้</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">

            </div>
        </div>

    </div>
</div>