import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShareService } from '../../ShareService';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit {
  [x: string]: any;

  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService) { }
  members: any = []
  member: any = []
  state: any = []
  Restaurantmember: any = []
  wholesalemember: any = []
  nullclassmember: any = []
  billing: any = []
  orderdetail: any = []
  seedetail: any = []
  showdetails: any = []
  All: number = 1
  Allwholesalemember: number = 1
  AllRestaurantmember: number = 1
  
  productdetail:any = []
  creditdetail: any = []
  orderID: any = null
  seachAllmember: any = null
  seachwholesalemember: any = null
  searchRestaurantmember: any = null
  member: any = {
    member_id: null,
    tax_id: null,
    phone: null,
    class: null,
    company: null,
    first_name: null,
    last_name: null,
    address: null,
    city: null,
    postcode: null,
    state: null,
    ID:null
  }
  customercredit: any = null
  customercreditdetail: any = []
  companycutomer: any = null;
  paymentcerdit: any = {
    ID: null,
    ID_credit: null,
    total_payment: null,
    type_payment: null

  }
  statusUser: any = {
    status: null,
    username: null,
    id: null,
    ID_branch:null
  }
  lookupresturants: any = []
  search: any = null;
  searchDate: any = null
  resturant: any = null
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();

    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }
  mamberclass: any = []
  ngOnInit(): void {
    this.loadCookie();
    this.Restaurant()
    this.loadclass()
    this.loadTh();
  }
  loadCustomer(item:any) {
    var param = {
      ID_branch:this.statusUser.ID_branch,
      class:item
    }
    this.http.post(this.ShareService.serverPath + '/customer/customers',param).subscribe((res: any) => {
   
      this.members = res
    })


  }
  lookupmember(item: any) {
    this.member = null;
    this.billing = null;
    this.orderdetail = null;
    this.member = item
    this.billing = item.billing
    
    var param = [this.member.id]
    this.http.post(this.ShareService.serverPath + '/customer/orderdetailcustomer', param).subscribe((res: any) => {
     
      this.orderdetail = res
    })
  }
  save() {
    this.member.member_id = this.member.phone
    

    var path = this.ShareService.serverPath + '/customer/insertcustomer'
    if (this.member.ID === null) {
      var filterstate = this.state.filter((e: any) => {
        return e.state == this.member.state
      })
     
      this.member.state = filterstate[0].id
    }
    if (this.member.ID != null) {
      var path = this.ShareService.serverPath + '/customer/updatecustomet'
      var filterstate = this.state.filter((e: any) => {
        return e.state == this.member.state
      })
    

      this.member.state = filterstate[0].id
    }
    this.http.post(path, this.member).toPromise().then((res: any) => {
      return res
    }).then((res) => {
      if (res.data == 'insertSuccess') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'เพิ่มข้อมูล สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        })
        

       this.Restaurant()
       this.wholesale()
      }
      if (res.data == 'updatemember') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'แก้ไขข้อมูล สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        })
        

    
      }
    }).catch(() => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'เพิ่มข้อมูลไม่สำเร็จ\nเกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง',
        showConfirmButton: false,
        timer: 1500
      })
    })

  }
  selectclass:any = null 
  selecteclass(item:any){
    this.selectclass = item
    this.loadCustomer(item)
  }
  loadclass(){
    
    this.http.post(this.ShareService.serverPath + '/customer/classcustomer',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
      if (res) {
        this.mamberclass = res
      
      }
    })
  }
  loadTh() {
    this.http.get(this.ShareService.serverPath + '/product/state').subscribe((res: any) => {
      if (res) {
        this.state = res
      }
    })
  }
  selectstate(e: any) {
  
    var state: string = e.target.value
    var filterstate = this.state.filter((e: any) => {
      return e.state == state
    })
    if (filterstate.length !== 0) {
      this.memberinsert.state = filterstate[0].state
  
    }
    if (filterstate.length === 0) {
      Swal.fire(
        'ไม่มีข้อมูลจังหวัดนี้!',
        'โปรดลองใหม่อีกครั้ง.',
        'error'
      ).then(() => {
        this.member.state = null
      })
    }
  }
  seedetailorder(item: any) {
    this.seedetail = item
   

  }
  selectmemberclass(e: any) {
   
    this.member.class = e.target.value
  }
  editMember(item: any) {
  

    if (item.ID != null) {
      var filtermember = this.members.filter((r: any) => {
        return r.id == item.ID_customer
      })
     
      this.memberinsert = filtermember[0].billing
      this.memberinsert.id = filtermember[0].id
      this.memberinsert.state = filtermember[0].billing.state
      this.memberinsert.ID = item.ID
      var filterstate = this.state.filter((r: any) => {
        return r.id == this.memberinsert.state
      })
      this.memberinsert.state = filterstate[0].state


    } else if (item.id != null) {
  
      this.memberinsert.id = item.id
      this.memberinsert = item.billing
      if (item.billing.state != "") {
        var filterstate = this.state.filter((r: any) => {
          return r.id == item.billing.state
        })
        this.memberinsert.state = filterstate[0].state

      }
      this.memberinsert.id = item.id
   

    }
  }
  editmemberdatabase(item: any) {
    this.member = item
    if (this.member.state !== '') {
      var filterstate = this.state.filter((e:any)=>{
        return e.id === item.state
      })
      this.member.state = filterstate[0].state
    }
   
  }


  del(item: any) {



    Swal.fire({
      title: 'คุณแน่ใจที่จะลบสมาชิก?',
      text: "คุณแน่ใจที่จะลบสมาชิก!\n",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยันการลบ '
    }).then((result) => {
      if (result.isConfirmed) {
        var path = this.ShareService.serverPath + '/customer/delectCustomer'
        this.http.post(path, item).toPromise().then((res: any) => {
          if (res.data == 'deleteSuccess') {

            Swal.fire(
              'ลบข้อมูลสมาชิก!',
              'ลบข้อมูลสมาชิกสำเร็จ.',
              'success'
            ).then(() => {
              this.classnull()
              this.wholesale()
              this.Restaurant()
            })

          }
          if (res.data == 'err') {
            Swal.fire(
              'ลบข้อมูลสมาชิก!',
              'ลบข้อมูลสมาชิกไม่สำเร็จ.',
              'error'
            ).then(() => {
              this.classnull()
              this.wholesale()
              this.Restaurant()
            })
          }


        })

      }
    })
  }
  Restaurant() {
    this.http.post(this.ShareService.serverPath + '/customer/customerRestaurant',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
      if (res) {
        this.Restaurantmember = res
     
      }
    })
  }
  wholesale() {
    this.http.post(this.ShareService.serverPath + '/customer/customerwholesal',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
      if (res) {
        this.wholesalemember = res
      

      }



    })
  }
  lookupresturant(item: any) {
    
    this.resturant = item.company
    this.http.post(this.ShareService.serverPath + '/customer/lookupresturant', item).subscribe((res: any) => {
      if (res) {
        this.lookupresturants = res
      
        if (res.length == 0) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'ไม่มีข้อมูลร้าน' + item.company,
            showConfirmButton: false,
            timer: 2000
          })
        }

      }
    })
  }
  showdetail(item: any) {

    this.http.post(this.ShareService.serverPath + '/customer/showdetail', item).subscribe((res: any) => {

      this.showdetails = res
    

    })
  }
  classnull() {
    this.http.get(this.ShareService.serverPath + '/customer/customerclassnull').subscribe((res: any) => {
      this.nullclassmember = res
     
    })
  }
  lookupallmember(item: any) {
   
    this.resturant = item.company
    this.http.post(this.ShareService.serverPath + '/customer/lookupresturant', item).subscribe((res: any) => {
      if (res) {
        this.lookupresturants = res
        
        if (res.length == 0) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'ไม่มีข้อมูลร้าน' + item.company,
            showConfirmButton: false,
            timer: 2000
          })
        }

      }
    })
  }
  lookupwholesale(item: any) {
    
    this.resturant = item.company
    this.http.post(this.ShareService.serverPath + '/customer/lookupresturant', item).subscribe((res: any) => {
      if (res) {
        this.lookupresturants = res
    
        if (res.length == 0) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'ไม่มีข้อมูลร้าน' + item.company,
            showConfirmButton: false,
            timer: 2000
          })
        }

      }
    })
  }
  nullMember() {
    this.member = {
      member_id: null,
      tax_id: null,
      phone: null,
      class: null,
      company: null,
      first_name: null,
      last_name: null,
      address: null,
      city: null,
      postcode: null,
      state: null,
      ID:null,
      ID_branch:this.statusUser.ID_branch
    }
  }
  nulldateseacrh() {
    this.searchDate = null
  }

  cuscredit() {
    this.http.post(this.ShareService.serverPath + '/customer/customercredit', { ID_branch: this.statusUser.ID_branch }).subscribe((res: any) => {
      if (res) {
        this.customercredit = res
      

      }
    })
  }
  ID_cus:any = null
  lookupallmembercredit(item: any) {
 
    this.ID_cus = item.ID_cus
    this.companycutomer = item.company
    this.http.post(this.ShareService.serverPath + '/customer/customercreditdetail', { ID_branch: this.statusUser.ID_branch, ID_cus: item.ID_cus }).subscribe((res: any) => {
      if (res) {
        this.customercreditdetail = res
        

      }
    })
  }

  detailcreditcustomerlookup(item: any) {
    this.orderID = item.ID_order
    this.paymentcerdit.ID_credit = item.ID_credit
    this.http.post(this.ShareService.serverPath + '/customer/creditdetailforcustomer', { ID_order: item.ID_order }).toPromise().then((res: any) => {
      if (res) {
        this.creditdetail = res
        

      }
    }).then(()=>{
      this.http.post(this.ShareService.serverPath + '/customer/productcreditdetailforcustomer', { ID_order: item.ID_order }).toPromise().then((res: any) => {
        if (res) {
          this.productdetail = res
   
  
        }
      })
    })
  }
  selectpaymentcredit(e: any) {
    this.paymentcerdit.type_payment = e.target.value
  }
  savepaymentcredit() {
    if (this.paymentcerdit.type_payment == null) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'โปรดเลือกรูปแบบการชำระ',
        showConfirmButton: false,
        timer: 1500
      })
    } else if (this.paymentcerdit.total_payment == null && this.paymentcerdit.total_payment == 0) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'โปรดกรอก จำนวนเงิน',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      this.paymentcerdit.ID_order = this.orderID
      this.paymentcerdit.ID_branch = this.statusUser.ID_branch
      this.paymentcerdit.ID_users = this.statusUser.name
     
      this.http.post(this.ShareService.serverPath + '/customer/paymentcerdit', this.paymentcerdit).subscribe((res: any) => {
        if (res) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'เพิ่มข้อมูลการชำระสำเร็จ',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.detailcreditcustomerlookup({ ID_order: this.paymentcerdit.ID_order })
            this.lookupallmembercredit({ID_branch:this.statusUser.ID_branch,ID_cus:this.ID_cus})
          }).then(() => {
            this.paymentcerdit = {
              ID: null,
              ID_credit: null,
              total_payment: null,
              type_payment: null

            }
          })
          
        }
      })
    }

  }
  addmember:boolean = !true
  addclassmember(){
    this.addmember = true
  }
  classmember:any = null
  saveclass(){
    var param = {
      class:this.classmember,
      ID_branch:this.statusUser.ID_branch
    }
    this.http.post(this.ShareService.serverPath + '/customer/addclass', param).subscribe((res: any) => {
      if(res.data === "insertsuccess" ){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'เพิ่มข้อมูลสำเร็จ',
          showConfirmButton: false,
          timer: 1500
        }).then(()=>{
          this.loadclass()
        this.classmember = null
        this.addmember = !true
         })
      }
    })
  }
}
