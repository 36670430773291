<div class="card ">

    <div class="card-body">
        <div class="p-2 bd-highlight">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#acc">ตั้งค่าบัญชี</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link " data-toggle="tab" href="#drw">ตั้งค่าล็อคเกอร์เงิน</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="acc" role="tabpanel" aria-labelledby="home-tab">
            <div class="container-md">
                <div class="card ">
                    <div class="card-body">
                        <button type="button" data-toggle="modal" data-target="#addAcc"
                            class="btn btn-info">เพิ่มข้อมูล</button>
                        <hr>
                        <table class="table">
                            <thead>
                                <tr>

                                    <th>รหัสบัญชี</th>
                                    <th>ชื่อบัญชี</th>
                                    <th>สาขา</th>

                                    <th>สถานะ</th>
                                    <th>วัน-เดือน-ปี</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of accounts">

                                    <td><a data-toggle="modal" (click)="lookupacc(item)" class="text-primary"
                                            data-target="#addAcc">{{item.ID}}</a></td>
                                    <td>{{item.name_acc}}</td>
                                    <td>
                                        <div *ngFor="let items of branchs">
                                            <div *ngIf="items.id === item.ID_branch">
                                                {{items.dokan_store_name|uppercase}}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="item.status == '1' "> <button type="button"
                                                class="btn btn-success">เปิดใช้งาน</button> </div>
                                        <div *ngIf="item.status == '2' "> <button type="button"
                                                class="btn btn-warning">รอดำเนินการ</button></div>
                                        <div *ngIf="item.status == '3' "> <button type="button" class="btn btn-danger">
                                                ปิดการใช้งาน</button></div>
                                        <div *ngIf="item.status == '4' "> <button type="button"
                                                class="btn btn-dark">ระงับการใช้งาน</button></div>
                                    </td>
                                    <td>{{item.date_create|date:'dd/MM/yyyy HH:mm'}}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>

        <div class="tab-pane fade " id="drw" role="tabpanel" aria-labelledby="home-tab">
            <div class="container-md">
                <div class="card ">
                    <div class="card-body">
                        <button type="button" data-toggle="modal" data-target="#adddrw"
                            class="btn btn-info">เพิ่มข้อมูล</button>
                        <hr>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>รหัสล็อคเกอร์เงิน</th>
                                    <th>รหัสบัญชี</th>

                                    <th>รายละเอียด</th>
                                    <th>สถานะ</th>
                                    <th>วัน-เดือน-ปี</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of drawers">
                                    <td><a data-toggle="modal" class="text-primary" (click)="lookupdrawer(item)"
                                            data-target="#adddrw">{{item.ID}}</a></td>
                                    <td>{{item.ID_acc}}-<small class="text-success">{{item.name_acc}}</small></td>

                                    <td>{{item.detail_drawer}}</td>
                                    <td>
                                        <div *ngIf="item.status == '1' "> <button type="button"
                                                class="btn btn-success">เปิดใช้งาน</button> </div>
                                        <div *ngIf="item.status == '2' "> <button type="button"
                                                class="btn btn-warning">รอดำเนินการ</button></div>
                                        <div *ngIf="item.status == '3' "> <button type="button" class="btn btn-danger">
                                                ปิดการใช้งาน</button></div>
                                        <div *ngIf="item.status == '4' "> <button type="button"
                                                class="btn btn-dark">ระงับการใช้งาน</button></div>
                                    </td>
                                    <td>{{item.date_create|date:'dd/MM/yyyy HH:mm'}}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>


        </div>
    </div>

</div>




<div class="modal fade" id="addAcc" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <div *ngIf="acc.ID === null "> เพิ่มข้อมูลบัญชี </div>
                    <div *ngIf="acc.ID !== null ">แก้ไขข้อมูลบัญชี</div>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row">
                        <div class="col-sm-4">
                            สาขา
                        </div>
                        <div class="col-sm-4">
                            <h3>{{branchname}}</h3>
                        </div>
                    </div>
                    <br>
                    <div class="row" *ngIf="acc.ID !== null">
                        <div class="col-sm-4">
                            รหัสบัญชี
                        </div>
                        <div class="col-sm-4">
                            <input type="text" readonly="readonly" [(ngModel)]="acc.ID" class="form-control" name=""
                                id="">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-4">
                            ชื่อ-บัญชี : <strong class="text-danger">*</strong>
                        </div>
                        <div class="col-sm-4">
                            <input type="text" placeholder="กรอก ชื่อ-บัญชี" [(ngModel)]="acc.name_acc"
                                class="form-control" name="" id="">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-4">
                            รายละเอียด :<strong class="text-danger">*</strong>
                        </div>
                        <div class="col-sm-4">
                            <textarea class="form-control" placeholder="กรอก  รายละเอียด " [(ngModel)]="acc.detail"
                                name="" id="" rows="3"></textarea>
                        </div>
                    </div>
                    <br>
                    <div class="row" *ngIf="acc.ID !== null">
                        <div class="col-sm-4">
                            สถานะ
                        </div>

                        <div class="col-sm-4">
                            <select name="" id="" (change)="selectacc($event)" class="form-control">
                                <option  *ngIf="acc.ID !== null" value="drw.status">
                                    <div *ngIf="acc.status == '1' "> เปิดใช้งาน </div>
                                    <div *ngIf="acc.status == '2' "> รอดำเนินการ</div>
                                    <div *ngIf="acc.status == '3' ">
                                        ปิดการใช้งาน</div>
                                    <div *ngIf="acc.status == '4' ">ระงับการใช้งาน</div>
                                </option>
                                <option *ngIf="acc.status !== '1' "  value="1">เปิดใช้งาน</option>
                                <option *ngIf="acc.status !== '2' "  value="2">รอดำเนินการ</option>
                                <option *ngIf="acc.status !== '3' " value="3">ปิดการใช้งาน</option>
                                <option  *ngIf="acc.status !== '4' " value="4">ระงับการใช้งาน</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">

                <button type="button" class="btn btn-success" data-dismiss="modal" *ngIf="acc.ID === null"
                    (click)="saveacc()">บันทึก</button>
                <button type="button" class="btn btn-warning" (click)="saveacc()" data-dismiss="modal"
                    *ngIf="acc.ID !== null" (click)="saveacc()">แก้ไข</button>

            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="adddrw" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <div *ngIf="drw.ID === null "> เพิ่มข้อมูลล็อคเกอร์เงิน </div>
                    <div *ngIf="drw.ID !== null ">แก้ไขข้อมูลล็อคเกอร์เงิน</div>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row" *ngIf="drw.ID === null">
                        <div class="col-sm-4">
                            บัญชี
                        </div>
                        <div class="col-sm-4">
                            <select name="" class="form-control" id="" (change)="selectaccounts($event)">
                                <option value="">---โปรดเลือกบัญชี---</option>

                                <option *ngFor="let item of accounts" value="{{item.ID}}">{{item.name_acc}}</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-4">
                            ชื่อ-ลิ้นชัก : <strong class="text-danger">*</strong>
                        </div>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" [(ngModel)]="drw.name"
                                placeholder="กรอก ชื่อ-ลิ้นชัก" name="" id="">

                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-4">
                            รายละเอียด :<strong class="text-danger">*</strong>
                        </div>
                        <div class="col-sm-4">
                            <textarea class="form-control" placeholder="กรอก รายละเอียด" [(ngModel)]="drw.detail_drawer"
                                name="" id="" rows="3"></textarea>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-4">
                            หมายเหตุ
                        </div>
                        <div class="col-sm-4">
                            <input type="text" [(ngModel)]="drw.remak" placeholder="กรอก หมายเหตุ" class="form-control"
                                name="" id="">
                        </div>
                    </div>
                    <br>
                    <div class="row" *ngIf="drw.ID !== null">
                        <div class="col-sm-4">
                            สถานะ
                        </div>
                        <div class="col-sm-4">
                            <select name="" id="" (change)="selectdrw($event)" class="form-control">
                                <option  *ngIf="drw.ID !== null" value="drw.status">
                                    <div *ngIf="drw.status == '1' "> เปิดใช้งาน </div>
                                    <div *ngIf="drw.status == '2' "> รอดำเนินการ</div>
                                    <div *ngIf="drw.status == '3' ">
                                        ปิดการใช้งาน</div>
                                    <div *ngIf="drw.status == '4' ">ระงับการใช้งาน</div>
                                </option>
                                <option *ngIf="drw.status !== '1' "  value="1">เปิดใช้งาน</option>
                                <option *ngIf="drw.status !== '2' "  value="2">รอดำเนินการ</option>
                                <option *ngIf="drw.status !== '3' " value="3">ปิดการใช้งาน</option>
                                <option  *ngIf="drw.status !== '4' " value="4">ระงับการใช้งาน</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="drw.ID === null" data-dismiss="modal" class="btn btn-success"
                    (click)="savedrw()">บันทึก</button>
                <button type="button" *ngIf="drw.ID !== null" data-dismiss="modal" class="btn btn-warning"
                    (click)="savedrw()">แก้ไข</button>
            </div>
        </div>
    </div>
</div>