import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ShareService } from '../../ShareService'
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common'
import * as moment from 'moment'; // เวลา
import 'moment-timezone';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnInit {

  seacrhSKU: any = null
  ManegerProduct: boolean = false
  wholesaleCheck: boolean = false;
  ManegerProductsale: boolean = false
  costcheck: boolean = false
  wholesale: any;
  ManegerProductcategories: boolean = false
  ManegerProducttags: boolean = false
  expiration_date:boolean = false
  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService, public datepipe: DatePipe) {

  }
  Products: any = [] //สินค้าทั้งหมด
  categories: any = [{}]//หมวดหมู่สินค้า
  costdata: any = []
  stock_status = [
    'instock', 'outofstock', 'onbackorder'
  ]
  Product: any = {
    name: null,
    sku: null,
    description: null,
    regular_price: null,
    sale_price: null,
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    stock_quantity: null,
    stock_status: null,
    manage_stock: null,
    dimensions: {
      height: String(''),
      length: String(''),
      width: String(''),
    },
    status: 'draft',
    weight: null, 
    categories: [{
      id: 1
    }],
    tags: [{
      id: 1
    }],
    images: [],
    total_sales: null,
    expiration_date:null,
    low_stock_quantity:null,
    id: null
  } //สินค้า
  statusUser: any = {
    status: null,
    username: null,
    name: null,
    ID_branch:null
  }
  pages:any =[
    '1','2','3','4','5','6','7','8','9','10'
  ]
  barcode: any = []
  wholesales: any = []
  wholesalesobj: any = {
    wholesale: null,
    P3: null,
    P4: null,
    ID_product: null,
    ID: null,
    ID_branch:null
  }
  cost_product: any = {
    ID: null,
    ID_product: null,
    amount: null,
    cost_total: null,
    date_input: null,
    name: null,
    nameuser: null,
    new_cost: null,
    new_stock: null,
    old_cost: null,
    old_stock: null,
    remaining_stock: null,
    sku: null,
    stock_qty: null,
  }
  barcodeProduct: any = []
  tags: any = [] //ป้ายกำกับสินค้า
  Productslt: any = []
  pageOfItems: any = 1
  getproduct: any = []
  status: any = null
  stop: any = null
  Search: any = ''
  printBarcode: any = []
  statusproduct: any = null
  media: any = []//รูปภาพ
  medias: any = {
    title: {
      rendered: null
    },
    caption: {
      rendered: null

    },
    description: {
      rendered: ''
    },
    media_type: "image",
    mime_type: null,
    author: this.statusUser.id,
    slug: null,
    source_url: null,
    status: 'draft',
    id:null
  }
  page:any =1
  LoadDataProducts() {
    this.http.get(this.ShareService.serverPath + '/product/products').toPromise().then((res: any) => {
      this.Products = res
      console.log( this.Products);
    }).then(()=>{
       this.loadwholesale()
    }).then(()=>{
        this.loadcostdata()
    
    }).then(()=>{
      this.LoadDatacategories();
    this.LoadDataTags();
    }).catch((err)=>{
      console.log(err);
      
    })
  }
  selectimg(item: any) {

    this.medias = item
  }
  getdata() {
    var param = {
      token: this.statusUser.token
    }
    if (this.media.length < 1) {
      this.http.post(this.ShareService.serverPath + '/media/mediaimg', param).subscribe((res: any) => {
        this.media = res
   
      })
    }

  }
  nextpagesmedie(){
    this.page++ 
    console.log(this.page);
    
    var param = {
      token: this.statusUser.token,
      page:this.page
    }
    
      this.http.post(this.ShareService.serverPath + '/media/mediaimgpage', param).subscribe((res: any) => {
        this.media = res
   
      })
    
  }
  backpagesmedie(){
    this.page-- 
    console.log(this.page);
    
    var param = {
      token: this.statusUser.token,
      page:this.page
    }
    
      this.http.post(this.ShareService.serverPath + '/media/mediaimgpage', param).subscribe((res: any) => {
        this.media = res
   
      })
    
  }
   imageURL: any = null
  uploadedFiles: any
  fileChange(element: any) {
    this.uploadedFiles = element.target.files;
  }
  urlimg:any = null
  selectdataimg(item: any) {
    var param = {
      id:item.id,
      src:item.source_url,
      
    }
   
    console.log(this.urlimg);
    if(this.Product.images.length === 0 ){
      this.Product.images.push(param)
      this.urlimg = param.src
    }else{
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'ไม่สามารถเพิ่มรูปได้อีก',
        showConfirmButton: false,
        timer: 1500
      })
    }
    
    console.log( this.Product.images);
    
   }
 loadwholesale(){
  var param = { 
    ID_branch:this.statusUser.ID_branch,
   }
  this.http.post(this.ShareService.serverPath + '/product/lookupproductwholesales', param).subscribe((res: any) => {

   
    this.wholesales = res
  })
 }
  loadcostdata() {
    var param = {ID_branch:this.statusUser.ID_branch}
        this.http.post(this.ShareService.serverPath + '/cost/lookupcost', param).toPromise().then((res: any) => {
       
        this.costdata = res
    })
  }
  LoadDatacategories() {
    this.http.get(this.ShareService.serverPath + '/product/categories').subscribe((res: any) => {
      this.categories = res
    })
  }

  LoadDataTags() {

    this.http.get(this.ShareService.serverPath + '/product/tags').subscribe((res: any) => {
      this.tags = res
     
    })
  }

  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
   
    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }
  saveproduct() {
  
    if (this.Product.sku == null ) {
       Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'โปรดกรอก รหัสสินค้า ',
        showConfirmButton: false,
        timer: 1500
      })
      return;
    }else if (this.Product.name == null ) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'โปรดกรอก ชื่อสินค้า ',
        showConfirmButton: false,
        timer: 1500
      })
      return;
    }else if (this.Product.stock_quantity == null ) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'โปรดกรอก จำนวนสินค้า ',
        showConfirmButton: false,
        timer: 1500
      })
      return;
    }else{
     
      var path = this.ShareService.serverPath + '/product/insertproduct'
      var dateFrom = moment(this.Product.date_on_sale_from)
      dateFrom.tz('Asia/Bangkok').format();
      this.Product.date_on_sale_from = dateFrom
      this.Product.date_on_sale_from_gmt = dateFrom
      var dateTo = moment(this.Product.date_on_sale_to)
      dateTo.tz('Asia/Bangkok').format();
      this.Product.date_on_sale_to = dateTo
      this.Product.date_on_sale_to_gmt = dateTo
      if (this.Product.id !== null) {
        var path = this.ShareService.serverPath + '/product/updateproduct'
      }
     
      this.Product.total_sales = this.Product.regular_price
      this.http.post(path, this.Product).toPromise().then((res: any) => {
        if (res) {
          this.cost_product.ID_product = res.id
          this.wholesalesobj.ID_product = res.id
          this.cost_product.name = res.name
          this.cost_product.sku = res.sku
          this.cost_product.nameuser = this.statusUser.name
        
          this.LoadDataProducts()
        }
       
        
        return res
      }).then(()=>{
        //ราคาขายส่ง
        this.wholesalesobj.ID_branch = this.statusUser.ID_branch
        if (this.wholesaleCheck == true) {
          var path = this.ShareService.serverPath + '/product/insertproductwholesale'
          if (this.wholesalesobj.ID !== null) {
            var path = this.ShareService.serverPath + '/product/editproductwholesale'
          }
          this.http.post(path, this.wholesalesobj).subscribe((res: any) => {
          
            
          })
        }
      }).then(()=>{
        this.cost_product.amount = this.Product.stock_quantity
        this.cost_product.stock_qty = this.Product.stock_quantity
        this.cost_product.ID_branch = this.statusUser.ID_branch
        var paths = this.ShareService.serverPath + '/cost/insertcost'
        if (this.costcheck == true) {
          if (this.cost_product.ID != null) {
            var paths = this.ShareService.serverPath + '/cost/ubdatecost'
          }
         
          this.http.post(paths, this.cost_product).subscribe((res: any) => {
            if (res) {
        
            }
          })
        }
      }).then(()=>{
        if (this.Product.id !== null) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'แก้ไขข้อมูล สำเร็จ',
            showConfirmButton: false,
            timer: 1500
          })
          this.LoadDataProducts()
        }
        if (this.Product.id === null) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'เพิ่มข้อมูล สำเร็จ',
            showConfirmButton: false,
            timer: 1500
          })
          
        }
        
      }).then(()=>{
        this.LoadDataProducts()
        this.nullProduct()
        //สินค้า
      }).catch(()=>{
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'เพิ่มข้อมูลไม่สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        })
      })
    }
   
  }
  ngOnInit(): void {
    this.loadCookie();
    this.LoadDataProducts()
    
   

  }


  selectproduct(item: any) {

    this.Productslt = item
  
    
  }
  ManegerProductevent(e: any) {
    this.Product.manage_stock = e.target.checked
   

  }
  nextpage() {
    this.pageOfItems++
    var page = [this.pageOfItems]
    
    this.http.post(this.ShareService.serverPath + '/product/productsnextpage', page).toPromise().then((res: any) => {
        this.Products = res
      if (res.length == 0) {
        this.Products = null
      }
    }).then(()=>{
      this.loadcostdata()
      this.loadwholesale()
    })

  }
  nextpagenumber(item:any){
    var page = [item]
    this.http.post(this.ShareService.serverPath + '/product/productsnextpage', page).toPromise().then((res: any) => {
      this.Products = res
    if (res.length == 0) {
      this.Products = null
    }
  }).then(()=>{
    this.pageOfItems =Number(item) 
    this.loadcostdata()
    this.loadwholesale()
  })
  }
  backpage() {
    this.pageOfItems--
    var page = [this.pageOfItems]
  
    this.http.post(this.ShareService.serverPath + '/product/productsnextpage', page).toPromise().then((res: any) => {
      this.Products = res
      if (res.length == 0) {
        this.Products = null
      }
    }).then(()=>{
      this.loadcostdata()
      this.loadwholesale()
    })
  }

  search() {
    var param = [this.Search]
    if (param == null) {
      Swal.fire({
        title: 'การค้นหาสินค้า',
        text: "การค้นหาไม่สามารถค้นหาได้!\nเนื่องจากข้อมูลเป็นค่าว่าง",
        icon: 'error',
        showCancelButton: false,

      })
    } else {
      this.http.post(this.ShareService.serverPath + '/product/productSearch', param).subscribe((res: any) => {
        

      })
    }
  }
  Manegerdatesele(e: any) {
    this.ManegerProductsale = e.target.checked
  }
  selectstatus(e: any) {

    this.Product.stock_status = e.target.value
  }
  edit(item: any) {
    this.Product = item
    if (item.date_on_sale_from !== null) {
      this.ManegerProductsale = true
      this.Product.date_on_sale_from = moment(this.Product.date_on_sale_from).format("yyyy-MM-DD")
      this.Product.date_on_sale_to = moment(this.Product.date_on_sale_to).format("yyyy-MM-DD")
    }
    var filterhtml = this.Products.filter((e:any)=>{
      return e.id === item.id 
    })
   
    this.Product.description = filterhtml[0].description
    
    this.ManegerProductcategories = !false
    this.ManegerProducttags = !false
    if (this.Product.tags.length == 0) {
      this.ManegerProducttags = false
    }
    if (this.Product.categories.length == 0) {
      this.ManegerProductcategories = false
    }

    var filter = this.wholesales.filter((e: any) => {
      return e.ID_product == item.id
    })
   

    if (filter.length != 0) {
      if (filter[0].wholesale !== null ) {
        this.wholesaleCheck = !false
      this.wholesalesobj = filter[0]
      }
      
    }

  }
  delectProduct(item: any) {
    this.Product = item
    Swal.fire({
      title: 'คุณแน่ใจที่จะลบสินค้า?',
      text: "คุณแน่ใจที่จะลบสินค้า!\n" + item.name,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยันการลบสินค้า '
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.post(this.ShareService.serverPath +'/product/deleteproduct', this.Product).toPromise().then((res: any) => {
          return res
        }).then((res)=>{
          if (res.data == 'deleteSuccess') {
            Swal.fire(
              'ลบข้อมูลสินค้า!',
              'ลบข้อมูลสินค้าสำเร็จ.',
              'success'
            )
            this.LoadDataProducts()
          }
        }).then(()=>{
          var param = {
            ID_product:this.Product.id,
            ID_branch:this.statusUser.ID_branch
          }
          this.http.post(this.ShareService.serverPath +'/product/deleteproductstore', param).toPromise().then((res: any) => {
            
            
          })
        }).catch((err)=>{
        
        })

      }
    })
    /* */
  }
  publish(item: any) {
    if (item) {
      Swal.fire({
        title: 'ยืนยันการเผยแพร่ข้อมูลสินค้า?',
        text: "คุณแน่ใจแล้วหรือยังที่\n จะดำเนินการเผยแพร่ข้อมูลสินค้า?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: ' ยืนยัน'
      }).then((result: any) => {
        if (result.isConfirmed) {
         
          var path = this.ShareService.serverPath + '/product/publishroduct';
          var param = [
            item.id,
            item.status
          ]
          this.http.post(path, param).subscribe((res: any) => {
            if (res.data == 'success') {
              Swal.fire(
                'เผยแพร่ข้อมูลสินค้าสำเร็จ',
                'เผยแพร่ข้อมูลสินค้าเสร็จสิ้นเรียบร้อย.',
                'success'
              ).then(() => {
                var update = this.Products.map((e: any) => {
                  if (e.sku === item.sku) {
                    return {
                      ...e,
                      status: 'publish'
                    };
                  }
                  return e;
                })
                this.Products = update
              })

            }

          })

        } else if (result.isDismissed) {
          var update = this.Products.map((e: any) => {
            if (e.sku === item.sku) {
              return {
                ...e,
                status: 'draft'
              };
            }
            return e;
          })
          this.Products = update
        }

      })
    }
  }
  closepublish(item: any) {
    if (item) {
      Swal.fire({
        title: 'ยืนยันการปิดเผยแพร่ข้อมูลสินค้า?',
        text: "คุณแน่ใจแล้วหรือยังที่\n จะดำเนินปิดการเผยแพร่ข้อมูลสินค้า?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: ' ยืนยัน'
      }).then((result: any) => {
        if (result.isConfirmed) {
   
          var path = this.ShareService.serverPath + '/product/closepublishroduct';
          var param = [
            item.id,
            item.status
          ]
          this.http.post(path, param).subscribe((res: any) => {
            if (res.data == 'success') {
              Swal.fire(
                'ปิดเผยแพร่ข้อมูลสินค้าสำเร็จ',
                'ปิดเผยแพร่ข้อมูลสินค้าเสร็จสิ้นเรียบร้อย.',
                'success'
              ).then(() => {
                var update = this.Products.map((e: any) => {
                  if (e.sku === item.sku) {
                    return {
                      ...e,
                      status: 'draft'
                    };
                  }
                  return e;
                })
                this.Products = update
              })

            }

          })

        } else if (result.isDismissed) {
          var update = this.Products.map((e: any) => {
            if (e.sku === item.sku) {
              return {
                ...e,
                status: 'publish'
              };
            }
            return e;
          })
          this.Products = update
        }
      })
    }

  }
  seacrhSKUs() {
    if (this.seacrhSKU == null) {
      Swal.fire(
        'การค้นหาข้อมูลสินค้า!',
        'โปรดกรอกข้อมูลสินค้าเพื่อค้นหา',
        'error'
      )
    } else {
      var param = [this.seacrhSKU]
      var path = this.ShareService.serverPath + '/product/serchproduct';
      this.http.post(path, param).subscribe((res: any) => {
        if (res.length != 0) {
          this.Products = res
          
         
          this.loadcostdata()
        }
        if (res.length == 0) {

          Swal.fire(
            'การค้นหาข้อมูลสินค้า!',
            'ไม่มีข้อมูลสินค้าที่คุณค้นหา.',
            'error'
          )

        }


      })
    }
  }
  EditProductPrice(item: any) {
    this.Product = item
    var filter = this.wholesales.filter((e: any) => {
      return e.ID_product == item.id
    })
   
    if (filter.length != 0) {
      this.wholesalesobj = filter[0]
    }
    this.wholesalesobj.ID_product = item.id
  }
  savewholesale() {
    delete this.wholesalesobj.price;
    this.wholesalesobj.ID_branch = this.statusUser.ID_branch
    var path = this.ShareService.serverPath + '/product/insertproductwholesale'
    if (this.wholesalesobj.ID != null) {
      var path = this.ShareService.serverPath + '/product/editproductwholesale'
    }

    this.Product.total_sales = this.Product.regular_price
    this.http.post(path, this.wholesalesobj).subscribe((res: any) => {
      if (res.data == 'insertSuccess') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'กำหนดข้อมูลราคาส่ง สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        }).then( ()=> {
          this.loadwholesale()
        });

      }
      if (res.data == 'updateProduct') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'แก้ไขข้อมูลราคาส่ง สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        }).then(()=>{
          this.loadwholesale()
          
        })
       
      }


    })

  }
  nullProduct() {
    this.Product = {
      name: null,
      sku: null,
      description: null,
      regular_price: null,
      sale_price: null,
      date_on_sale_from: null,
      date_on_sale_to: null,
      stock_quantity: null,
      stock_status: null,
      manage_stock: null,
      dimensions: {
        height: String(''),
        length: String(''),
        width: String(''),
      },images:[]
      ,
      status: 'draft',
      weight: null,
      categories: [],
      tags: [],
      total_sales: null,
      id: null
    } //สินค้า
    this.ManegerProduct = false
    this.wholesaleCheck = false;
    this.ManegerProductsale = false
    this.costcheck = false
    this.ManegerProductcategories = false
    this.ManegerProducttags = false
    this.wholesalesobj = {
      wholesale: null,
      P3: null,
      P4: null,
      ID_product: null,
      ID: null
    }
    this.cost_product = {
      ID: null,
      ID_product: null,
      amount: null,
      cost_total: null,
      date_input: null,
      name: null,
      nameuser: null,
      new_cost: null,
      new_stock: null,
      old_cost: null,
      old_stock: null,
      remaining_stock: null,
      sku: null,
      stock_qty: null,
    }
    this.expiration_date = !true
  }
  createBarcode(item: any) {
    this.barcode = []
    this.barcodeProduct = []
    for (let index = 0; index < item.stock_quantity; index++) {
      var barcode = {
        sku: item.sku,
        stock_quantity: item.stock_quantity
      }
      this.barcode.push(barcode)
    }
    this.barcodeProduct.push({ sku: item.sku })
  
  }
  selectamount(e: any) {
    this.printBarcode = []
    
    var numbers: number = e.target.value
    for (let index = 0; index < numbers; index++) {
      var data = { sku: this.barcodeProduct[0].sku }
      this.printBarcode.push(data)
    }
    
  }
  EditProductcost(item: any) {
    this.cost_product.cost_total = null
    this.Product = item
    var filter = this.costdata.filter((e: any) => {
      return e.ID_product == item.id
    })


    if (filter.length !== 0) {
      this.cost_product = filter[0]
      
    } else {
      this.cost_product = {
        ID: null,
        ID_product: item.id,
        cost_total: null,
        date_input: null,
        name: item.name,
        nameuser: this.statusUser.name,
        new_cost: null,
        new_stock: item.stock_quantity,
        old_cost: 0,
        old_stock: 0,
        remaining_stock: item.stock_quantity,
        sku: item.sku,
      }

    }


  }
  savecost() {
    this.cost_product.ID_branch =this.statusUser.ID_branch 
  
    var paths = this.ShareService.serverPath + '/cost/insertcost'
    if (this.cost_product.ID !== null) {
      var paths = this.ShareService.serverPath + '/cost/updatecost'
    }

    this.http.post(paths, this.cost_product).subscribe((res: any) => {
      if (res.data == 'insertSuccess') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'กำหนดราคาต้นทุน สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.loadcostdata()
        })

      }
      if (res.data == 'updateSucess') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'กำหนดราคาต้นทุน สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
         
          this.loadcostdata()
        })
      }
    })
  }
  selectcategories(e: any) {

    if (e.target.checked) {
      let data: number = +e.target.value
      this.Product.categories.push({ id: data });

    } else {
      let data: number = +e.target.value
      this.Product.categories.forEach((value: any, index: any) => {
        if (value.id == data) this.Product.categories.splice(index, 1);
      })
      if (this.Product.categories.length == 0) {

        this.ManegerProductcategories = false
      }
    }

   


  }
  selecttag(e: any) {


    if (e.target.checked) {
      let data: number = +e.target.value
      this.Product.tags.push({ id: data });
    } else {
      let data: number = +e.target.value
      this.Product.tags.forEach((value: any, index: any) => {
        if (value.id == data) this.Product.tags.splice(index, 1);
      })
      if (this.Product.tags.length == 0) {

        this.ManegerProducttags = false
      }
    }
  
  }
 
 
  upload() {
    var file = this.uploadedFiles[0]
    var param = {
      title: file.name,
      caption: '',
      description: '',
      media_type: "image",
      mime_type: file.type,
      author: this.statusUser.id,
      slug: null,
      
    }
   this.http.post('http://pinsouqstore.com/wp-json/wp/v2/media',file,{
    
      headers: {
        "Content-Disposition": 'attachment; filename=' + param.title,
        "Authorization": 'Bearer' + this.statusUser.token,
    
      },
    }).subscribe((res: any) => {
      if(res.source_url !== null){
        this.imageURL  = res.source_url
        this.getdata()
        this.selectdataimg(res)
      }
    
    })
    /*this.http.post(this.ShareService.serverPath + '/media/creatmedia', { param,files:file,token: this.statusUser.token }).subscribe((res: any) => {
      console.log(res);*/
    /*  var path = res.source_url.split('uploads')\1].replace(param.title, '')
      console.log(path);
      const contentType = file.mime_type;
      console.log(contentType);
      const bucket = new S3(
        {
          accessKeyId: 'AKIA36JLHUAJXQHVIYOS',
          secretAccessKey: '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',
          region: 'ap-southeast-1'
        }
      );
      const params = {
        Bucket: 's3.pinsouqstore',
        Key: this.FOLDER + path +file.name,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType
      };
      bucket.upload(params, (err: any, data: any) => {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          return false;
        }
        console.log(data);
        return true;
      })
    })*/ 

  }
}
