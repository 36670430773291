<div class="container-md">
    <div class="card card-primary">
        <div class="card-header">รายงาน</div>
        <div class="card-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">

                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#seles" role="tab"  aria-controls="profile" aria-selected="false">รายงานการขาย</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#product" role="tab" (click)="gotoProduct" aria-controls="contact" aria-selected="false">รายงานสินค้า</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#customer" role="tab" aria-controls="contact" aria-selected="false">รายงานสมาชิก</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#members" role="tab" aria-controls="contact" aria-selected="false">รายงานบุคลากร</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#accounts" role="tab" aria-controls="contact" aria-selected="false">รายงานบัญชี</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="seles" role="tabpanel" aria-labelledby="home-tab">
                   
                </div>
                <div class="tab-pane fade" id="product" role="tabpanel" aria-labelledby="profile-tab">
                   
                </div>
                <div class="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="contact-tab">
                    <app-customer></app-customer>
                </div>
                <div class="tab-pane fade" id="members" role="tabpanel" aria-labelledby="contact-tab">
                    <app-personal></app-personal>
                </div>
                <div class="tab-pane fade" id="accounts" role="tabpanel" aria-labelledby="contact-tab">
                    <app-accounts></app-accounts>
                </div>
            </div>
        </div>
    </div>

</div>