<div class="card card-primary">
    <div class="card-header">หมวดหมู่สินค้า</div>
    <div class="card-body">


        <button type="button" class="btn btn-info" (click)="nulldata()" data-toggle="modal" data-target="#addCate"> <i class="fa fa-plus"
                aria-hidden="true"></i> เพิ่มหมวดหมู่</button>
        <hr>
        
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th>รูป</th>
                    <th>ชื่อ</th>
                    <th>รายละเอียด</th>
                    <th></th>
                    <th>Count</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of categories">
                    <td><button type="button" data-toggle="modal" data-target="#addCate" (click)="selectcate(item)"
                            class="btn btn-sm btn-info"><i class="fa fa-search" aria-hidden="true"></i></button></td>
                    <td scope="row">
                        <div *ngIf="item.image  == null">
                            <img src="assets/img/noimg.png" style="width:35px;">
                        </div>
                        <div *ngIf="item.image  != null">
                            <img [src]="item.image.src" style="width: 40px;" />
                        </div>
                    </td>
                    <td>
                        <div *ngIf="item.parent === 0"><strong>{{item.name}}</strong></div>
                        <div *ngIf="item.parent > 0">&nbsp;&nbsp;&nbsp;{{item.name}}</div>
                    </td>
                    <td>{{item.description}}</td>
                    <td></td>
                    <td>{{item.count}}</td>
                </tr>

            </tbody>
        </table>
    </div>

</div>

<!-- Modal -->
<div class="modal fade" id="addCate" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">ชื่อ-หมวดหมู่</label>
                            <input type="text" [(ngModel)]="categorie.name" placeholder="กรอก ชื่อ-หมวดหมู่"
                                class="form-control">
                            <label for="">slug</label>
                            <input type="text" [(ngModel)]="categorie.slug" placeholder="กรอก slug"
                                class="form-control">
                            <label for="">รายละเอียด</label>
                            <textarea name="" class="form-control" placeholder="กรอก รายละเอียด"
                                [(ngModel)]="categorie.description" id="" cols="30" rows="10">
                            </textarea>    
                            <label for="">หมวดหมู่หลัก</label>
                            <select name="" class="form-control" (change)="selectparent($event)"  id="">
                                <option value="">----เลือกหมวดหมู่หลัก----</option>
                                <option *ngFor="let item of categories"  value="{{item.id}}">
                                   {{item.name}}
                                </option>
                            </select>

                        </div>
                        <div class="col-sm-6">
                            <div *ngIf="categorie.image.src == null">
                                <img src="assets/img/noimg.png" class="rounded" alt="Cinque Terre" width="304"
                                    height="236">
                            </div>
                            <div *ngIf="categorie.image.src !== null">
                                <img [src]="categorie.image.src" class="rounded" alt="Cinque Terre" width="304"
                                    height="236">
                            </div>


                            <br>
                            <button type="button" data-dismiss="modal" class="btn btn-primary mt-5" *ngIf="categorie.image.src == null"
                                data-toggle="modal" data-target="#selectimg" (click)="getdata()"><i
                                    class="fas fa-image" ></i> เลือกรูปภาพ</button>
                            <button type="button" data-dismiss="modal" class="btn btn-warning mt-5" *ngIf="categorie.image.src != null"
                                data-toggle="modal" data-target="#selectimg" (click)="getdata()"><i
                                    class="fas fa-image" ></i> เปลี่ยนรูปภาพ</button>
                        </div>
                       
                    </div>
                    <div    class="mt-2">
                    <button type="button" *ngIf="categorie.id == null" class="btn btn-success" data-dismiss="modal" (click)="save()">บันทึก</button>
                
                    <button type="button" *ngIf="categorie.id != null" class="btn btn-warning"  data-dismiss="modal" (click)="save()">แก้ไข</button> &nbsp;
                    <button type="button" *ngIf="categorie.id != null" class="btn btn-danger" data-dismiss="modal" (click)="del()">ลบ</button>
                 </div>
                </div>
            </div>
            <div class="modal-footer">

               
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="selectimg" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ข้อมูลรูปภาพ</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"  data-toggle="modal" data-target="#addCate">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#home">อัพโหลดรูปภาพ</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu1">เลือกรูปภาพ</a>
                        </li>
                    </ul>
                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div class="tab-pane container active " id="home">
                            <div class="container  mt-5">
                                <div class="row">
                                    <div class="col-sm-2"></div>
                                    <div class="col-sm-8">
                                        <div class="row d-flex justify-content-center">
                                          
                                                    <div class="col-sm-3">
                                                        <div class="imagePreview d-flex justify-content-center"
                                                            *ngIf="imageURL == null">
                                                            <img src="assets/img/upload.png" width="304" height="236">
                                                        </div>
                                                        <div class="imagePreview d-flex justify-content-center"
                                                            *ngIf="imageURL && imageURL !== null">
                                                            <img [src]="imageURL" width="304" height="236">
                                                        </div>
                                                        <br>

                                                        <input type="file" id="myFile" accept=".jpg, .jpeg, .png"
                                                            multiple name="filename" (change)="fileChange($event)">
                                                            <button class="btn btn-success mt-5" *ngIf="uploadedFiles && uploadedFiles !== null" (click)="upload()">อัพโหลด</button>
                                                    </div>
                                         

                                        </div>



                                    </div>
                                    <div class="col-sm-2"></div>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane container fade" id="menu1">
                            <div class="row">
                                <div class="col-sm-9" id="para1">
                                    <div class="row mt-2">
                                        <div class="col-sm-4"> <div class="d-flex justify-content-start"> <button *ngIf="page > 1" type="button" class="btn btn-sm btn-light" (click)="backpagesmedie()">ย้อนกลับ</button></div></div>
                                        <div class="col-sm-2">{{page}}</div>
                                        <div class="col-sm-4">      <div class="d-flex justify-content-end"> <button type="button" (click)="nextpagesmedie()" class="btn btn-sm btn-light">ถัดไป</button></div> </div>
                              
                                     </div>
                                    <div class="row mt-2">
                                        <div *ngFor="let item of media">
                                            <div class="col-sm-3">
                                                <div class="form-check-inline">
                                                    <label class="form-check-label"></label>
                                                    <input type="radio" (click)="selectimg(item)"
                                                        class="form-check-input" name="optradio">
                                                    <img [src]="item.source_url" style="width: 100px;" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                    
                                     </div>
                                </div>
                                <div *ngIf="medias.source_url" class="col-sm-3">
                                    <div class="d-flex justify-content-center"> <img [src]="medias.source_url"
                                            style="width: 150px;" /></div>
                                   
                                    <hr>
                                    <button type="button" data-dismiss="modal"
                                        (click)="selectdataimg(medias) " class="btn btn-success" data-toggle="modal" data-target="#addCate"><i
                                            class="fa fa-check" aria-hidden="true" ></i>ใช้รูปภาพนี้</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">

            </div>
        </div>

    </div>
</div>