import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ShareService } from '../../ShareService'
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment'; // เวลา
import Swal from 'sweetalert2';
@Component({
  selector: 'app-forntsale',
  templateUrl: './forntsale.component.html',
  styleUrls: ['./forntsale.component.css']
})
export class ForntsaleComponent implements OnInit {

  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService) { }
  sales: any = []
  totalselesmonth:any =[]
  totalgoodseles:any = []
  totalyears:any = []
  customsalesdata:any = []
 
  ngOnInit(): void {
    this.loadCookie();
    this.loadstateEn()
    this.loadForntsell();
    
  }
  statusUser: any = {
    username: null,
    name: null,
    token: null,
    email: null,
    id: null,
    status: null,
    billing_state:null,
    billing_address_1:null,
    billing_address_2:null,
    billing_city:null,
    billing_postcode:null,
    billing_phone:null,
    stete:null,
    ID_branch:null,
    name_branch:null

  }
  users:any = null
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
  
    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }
  DataSaleandproduct: any = []
  DataSaleandproducts: any = []
  product: any = []
  date_input: any = null
  lookupsales: any = []
  getmoney: any = null
  changemoney: any = null
  GrandTotal: any = null
  ///การค้นหา
  search: any = null
  searchFrom:any = null
  searchTo:any = null
  searchTomonth:any = null
  searchgoodsale:any = null
  scr:any = {
    dateto:null,
    datefrom:null,
    ID_branch:null
  }
  ////////////////////////////
  today:number = 1
  goodsale:number = 1
  monthsale:number = 1


  TotleSaleDay: any = 0
  totalpricetoday: any = 0
  TotleCustomerSaleDay: any = 0
  totalpricetomonth: any = 0
  sale:any = {
    ID: null,
    date_input: null,
    discount: null,
    id_coupon: null,
    id_customer: null,
    payment: null,
    totalprice: null,
    totalquantity: null,
    ID_order:null
  }
  billing:any = {
    address_1: null,
    address_2: null,
    city: null,
    company: null,
    country: null,
    email: null,
    first_name: null,
    last_name: null,
    phone: '',
    postcode: null,
    state: null,
    member_id:null
  }
  loadForntsell() {
    this.http.post(this.ShareService.serverPath + '/sale/Allsale',{ID_branch:this.statusUser.ID_branch}).toPromise().then((res: any) => {
      this.sales = res;
      
    }).then(()=>{
      this.http.post(this.ShareService.serverPath + '/sale/totlesaleday',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
        this.TotleSaleDay = res[0].totlesaleday;

      })
    }).then(()=>{
      this.http.post(this.ShareService.serverPath + '/sale/totlecustomertoday',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {

        this.TotleCustomerSaleDay = res[0].totle
      })
    }).then(()=>{
      this.http.post(this.ShareService.serverPath + '/sale/totalpricetoday',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
       
        this.totalpricetoday = res[0].total
      })
    }).then(()=>{

      this.http.post(this.ShareService.serverPath + '/sale/totalpricetomonth',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
    
        this.totalpricetomonth = res[0].total
      })
    }).catch((err)=>{
     
      
    })

  }
  lookupsale(item: any) {
      var param = item
      this.http.post(this.ShareService.serverPath + '/sale/lookupsale', param).toPromise().then((res: any) => {
    
        
        return res
      }).then((res)=>{
        this.sale = res[0]
        
        this.DataSaleandproduct = res
        this.DataSaleandproducts = res
        this.date_input = res[0].date_input
       
        this.getmoney = res[0].getmoney
        this.changemoney = res[0].changemoney
        this.GrandTotal = res[0].totalprice
        this.users = res[0].users
        return res
      }).then((res)=>{
        if(res[0].id_customer !== 0){
          var param = {ID:res[0].id_customer}
          this.http.post(this.ShareService.serverPath + '/sale/lookupcustomer', param).toPromise().then((res: any) => {
        
            this.billing = res[0]
            this.billing.address_1 = res[0].address
            if(this.billing.first_name === '' ){
              this.billing.first_name = res[0].company
            }
            if(this.billing.phone == ''){
              this.billing.phone = String("ไม่มีข้อมูล")
            }
          })
        }
       
      }).then(()=>{

      })
      .catch((err)=>{
     
      })
    
  }
  totalmonth(){
    this.http.post(this.ShareService.serverPath + '/sale/totalselesmonth',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
      this.totalselesmonth = res
    
      
    })
  }
  totalyear(){
    this.http.get(this.ShareService.serverPath + '/sale/totalselesyear').subscribe((res: any) => {
      this.totalyears = res
     
      
    })
  }
  loadtotalgoodseles(){
    this.http.post(this.ShareService.serverPath + '/sale/totalgoodseles',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
      this.totalgoodseles = res
     
    
    })
  }
  loadcustomsales(){
    var dateTo = moment(this.scr.dateto)
    dateTo.tz('Asia/Bangkok').format();
    this.scr.dateto = dateTo 
    var datefrom = moment(this.scr.datefrom)
    datefrom.tz('Asia/Bangkok').format();
    this.scr.datefrom  = datefrom
    this.scr.ID_branch  = this.statusUser.ID_branch 
    this.http.post(this.ShareService.serverPath + '/sale/totalselescustomdate',this.scr).subscribe((res: any) => {
    
      this.customsalesdata = res
    })
  }
  loadstateEn(){
    this.http.get(this.ShareService.serverPath + '/product/stateEn').subscribe((res: any) => {
  
      var filterstate = res.filter((e:any)=>{
        return e.id === this.statusUser.billing_state
      })
      this.statusUser.billing_state = filterstate[0].state
      
        
        this.http.get(this.ShareService.serverPath + '/product/state').subscribe((res: any) => {
     
          var filterstates = res.filter((e:any)=>{
            return e.id === filterstate[0].id
          })
          this.statusUser.state = filterstates[0].state
        
            
        })  
    })
  }
  searchbill(){
    this.http.post(this.ShareService.serverPath + '/sale/searchbill',{data:this.search}).subscribe((res: any) => {
   
      
      if(res.data === 'nohavedata'){
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'ไม่มีข้อมูลเลขที่ใบเสร็จนี้ \n โปรดตรวจสอบใหม่อีกครั้ง',
          showConfirmButton: false,
          timer: 1500
        })
      }else{
        this.sales = res
      }
    })
  }
  datasearchtotaltoday:any = []
  seacrhdatadata(item:any){
  
    
    this.http.post(this.ShareService.serverPath + '/sale/searchtotaltoday',{data:item.date_string}).subscribe((res: any) => {
      this.datasearchtotaltoday = res
    })
  }
  cancelbill(item:any) {
    Swal.fire({
      title: 'คุณแน่ใจหรอที่จะยกเลิกบิลเลขที่ \n '+item.ID,
      text: "กดปุ่มตกกลงเพื่อยกเลิก!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ตกลง'
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.post(this.ShareService.serverPath + '/sale/deletebill',{ ID:item.ID} ).subscribe((res: any) => {
          if (res.data == 'deletesuccess') {
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'การยกเลิกเสร็จสิ้น \nโปรดรอสักครู่....',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              
                this.loadForntsell()
             
            });

          }
        })

      }
    })
  }
  selectgoodsale(e:any){
    var data = e.target.value
    if (data === "goodseleday") {
      this.loadtotalgoodseles();
    }else if (data === "goodseleweek") {
      this.http.post(this.ShareService.serverPath + '/sale/goodseleweek',{ID_branch:this.statusUser.ID_branch}).toPromise().then((res: any) => {
        this.totalgoodseles = res
        
       })
    }else if (data === "goodselemonth") {
      this.http.post(this.ShareService.serverPath + '/sale/goodselemonth',{ID_branch:this.statusUser.ID_branch}).toPromise().then((res: any) => {
        this.totalgoodseles = res
        
       })
    }
  }
}
