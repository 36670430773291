import { Component, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
@Component({
  selector: 'app-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.css']
})
export class DayComponent implements OnInit {

  constructor() { }
  doughnutChartLabels: Label[] = ['เนื้อ', 'เนื้อบด', 'เนื้อสเต็ก','เฟรนฟราย'];
  doughnutChartData: MultiDataSet = [
    [55, 25, 20,15]
  ];
  doughnutChartType: ChartType = 'doughnut';


  ngOnInit(): void {
  }

}
