import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ShareService } from '../../ShareService'
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-get-product',
  templateUrl: './get-product.component.html',
  styleUrls: ['./get-product.component.css']
})
export class GetProductComponent implements OnInit {

  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService) { }
  datacosts: any = []
  detailcost: any = []
  Product = {
    sku: null,
    id: null,
    stock_quantity: 1
  }
  p: number = 1
  detailget:number = 1
  seacrhdata: any = null
  seacrhdatetime: any = null
  seacrhdatetimedetailget:any = null
  Products: any = []
  Product_qty: any = []
  datacost: any = []
  statusUser: any = {
    status: null,
    username: null,
    name: null,
    ID_branch: null
  }
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();

    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }

  ngOnInit(): void {
    this.loadCookie()
    this.loaddatacost()
  }
  loaddatacost() {
    var param = { ID_branch: this.statusUser.ID_branch }
    this.http.post(this.ShareService.serverPath + '/product/lookupcost', param).toPromise().then((res: any) => {


      if (res.length !== 0) {
        this.datacosts = res
      
      }
    })
  }
  searchPeoduct(e: any) {
    this.Product.sku = e.target.value
    var param = { ID_product: e.target.value, ID_branch: this.statusUser.ID_branch }
   

    this.http.post(this.ShareService.serverPath + '/cost/productlookup', param).subscribe((res: any) => {
  
    
      if (res.data === 'nothavedata') {
        Swal.fire(
          'ล้มเหลว!',
          'ไม่มีข้อมูลสินค้าที่ท่านเลือก .',
          'error'
        )
        this.Product.sku = null
      }else
      if (res.data !== 'nothavedata'){
        this.Products.push(res[0])
        this.Product.sku = null
      }

      
     
    })
  }
  save() {
    if (this.Products.length >= 1 || this.Product_qty.length >= 1 || this.datacost.length >= 1) {


      var param = {
        data: this.Product_qty,
        ID_branch: this.statusUser.ID_branch
      }
      this.http.post(this.ShareService.serverPath + '/cost/storeproductsqty', param).toPromise().then((res: any) => {

      }).then(() => {
       
      }).then(() => {
        var param = {
          datacost: this.datacost,
          dataqty: this.Product_qty,
          dataproduct: this.Products,
          ID_branch: this.statusUser.ID_branch,
          nameuser: this.statusUser.name
        }
        this.http.post(this.ShareService.serverPath + '/cost/addcost', param).toPromise().then((res: any) => {
  

        })
      }).then(()=>{
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'รับสินค้าเรียบร้อย\nโปรดรอดำเนินการสักครู่',
          showConfirmButton: false,
          timer: 3000
        }).then(()=>{
          location.href= '/GetProduct'
        })
      }).catch((err)=>{

        
      })

    } else {
      Swal.fire(
        'ไม่มีข้อมูล!',
        'การรับสินค้าไม่สำเร็จ .',
        'error'
      )
    }

  }



  delete(item: any) {

    Swal.fire({
      title: 'คุณแน่ใจที่จะยกเลิกรับสินค้า?' + item.name,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยันการยกเลิกรับสินค้า '
    }).then((result) => {
      if (result.isConfirmed) {
        this.Products.forEach((value: any, index: any) => {
          if (value.id == item.id) this.Products.splice(index, 1);
        })
        Swal.fire(
          'ยกเลิกรับสินค้าสินค้า!',
          'ยกเลิกรับสินค้าสำเร็จ.',
          'success'
        )

      }
    })
  }
  qty(e: any) {

    var stock_quantity: number = e.target.value
    var processing = this.Product_qty.filter(function (res: any) {
      return res.sku == e.target.id;
    });
    if (processing.length == 0) {
      this.Product_qty.push({
        sku: e.target.id,
        stock_quantity: stock_quantity
      })
    }
    if (processing.length == 1) {
      const updatedqty = this.Product_qty.map(update);
      function update(qty: any) {
        if (qty.sku === e.target.id) {
          return {
            ...qty,
            stock_quantity: e.target.value
          };
        }
        return qty;
      }
      this.Product_qty = updatedqty
    }
  


  }
  cost(e: any) {
   
    var param: number = e.target.id
    const cost: number = e.target.value
    var filterDatacost = this.datacost.filter((res: any) => {
      return res.ID_product == param
    })
  
    if (filterDatacost.length == 1) {
      const updatedqty = this.datacost.map(update);
      function update(co: any) {
        if (co.sku === e.target.name) {
          return {
            ...co,
            new_cost: e.target.value
          };
        }
        return co;
      }
      this.datacost = updatedqty



    }
    if (filterDatacost.length == 0) {
      var filterDataCosts = this.datacosts.filter((rs: any) => {
        return rs.ID_product == e.target.id;
      })
  
      if (filterDataCosts.length == 1) {
        this.datacost.push({
          ID: filterDataCosts[0].ID,
          ID_product: filterDataCosts[0].ID_product,
          sku: filterDataCosts[0].sku,
          new_cost: cost,
          old_cost: filterDataCosts[0].new_cost,
          old_stock: filterDataCosts[0].new_stock
        })
    

      }
      if (filterDataCosts.length == 0) {
        this.datacost.push({
          ID: null,
          ID_product: param,
          sku: e.target.name,
          new_cost: cost,

        })
      }
    }


    


  }
  lookupdetailcost(item: any) {
   
    this.http.post(this.ShareService.serverPath + '/product/lookupcostdetail', [item]).subscribe((res: any) => {

      if (res) {
        this.detailcost = res
      }
    })


  }
  nulldata() {
    this.seacrhdata = null
    this.seacrhdatetime = null
  }
  nulldatadetail(){
    this.seacrhdatetimedetailget = null
  }
  
}
