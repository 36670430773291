<div class="card card-primary">
    <div class="card-header"><i class="fa fa-box" aria-hidden="true"></i></div>
    <div class="card-body">
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#lookupdata"><i
                class="fa fa-inbox" aria-hidden="true"></i> ดูข้อมูลรับสินค้า</button>

        <div class="row">
            <div class="col-sm-3">
                <label for=""></label>
                <input type="text" class="form-control" [(ngModel)]="Product.sku" (keyup.enter)="searchPeoduct($event)"
                    name="" id="" aria-describedby="helpId" placeholder="สแกนรหัสสินค้า">
            </div>
        </div>

        <table class="table  table-striped table-inverse table-responsive">
            <thead class="">
                <tr>
                    <th>รหัส-สินค้า</th>
                    <th>ชื่อ-สินค้า</th>
                    <th>จำนวนปัจจุบัน</th>
                    <th>ราคาปัจจุบัน</th>
                    <th>จำนวน(ที่รับ)</th>
                    <th>ต้นทุน(ต่อหน่วย)</th>
                   <!--<th>วันที่ผลิต</th>--> 
                  <!--  <th>วันที่หมดอายุ</th>--> 
                    <th>ยกเลิก</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Products ">

                    <td>{{item.sku}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.stock_quantity|number}}</td>
                    <td>{{item.price|number:'2.2'}}</td>
                    <td>
                        <div class="row">
                            <div class="col-sm-12">
                                <input class="form-control" id="{{item.sku}}" type="number" min="1"
                                    (change)="qty($event)" placeholder="กรอก จำนวนสินค้า">
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-12">
                                <input type="number" min="1" required class="form-control" name="{{item.sku}}"
                                    id="{{item.ID_product}}" (change)="cost($event)" aria-describedby="helpId"
                                    placeholder="กรอก ต้นทุน(ต่อหนวย)">

                            </div>
                        </div>

                    </td>
                 <!--   <td>
                        <div class="row">
                            <div class="col-sm-12">
                                <input type="date" min="" required class="form-control"
                                    placeholder="กรอก ต้นทุน(ต่อหนวย)">

                            </div>
                        </div>
                    </td>-->
                <!--    <td>
                        <div class="row">
                            <div class="col-sm-12">
                                <input type="date" min="1" required class="form-control"
                                    placeholder="กรอก ต้นทุน(ต่อหนวย)">

                            </div>
                        </div>
                    </td>-->
                    <td><button type="button" class="btn btn-sm btn-danger" (click)="delete(item)"><i
                                class="fa fa-trash " aria-hidden="true"></i></button></td>

                </tr>

            </tbody>

        </table>
        <br>

        <div class="d-flex justify-content-center"> <button class="btn  btn-success" (click)="save()"> ยืนยัน</button>
        </div>


    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="lookupdata" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content ">
            <div class="modal-header">
                <h5 class="modal-title">ข้อมูลการรับสินค้าล่าสุด</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-2">

                            <input type="search" placeholder="ค้นหาข้อมูล" class="form-control" [(ngModel)]="seacrhdata"
                                name="" id="">
                            &nbsp;

                        </div>
                        <div class="col-sm-3">

                            <input type="date" placeholder="ค้นหาข้อมูล" class="form-control"
                                [(ngModel)]="seacrhdatetime" name="" id="">
                        </div>
                        <div class="col-sm-2">

                            <button type="button" class="btn btn-danger btn-sm" (click)="nulldata()">ยกเลิก</button>
                        </div>
                        <div class="col-sm-5">
                            <div class="d-flex flex-row-reverse bd-highlight">
                                <div class="p-2 bd-highlight">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>

                    <table class="table table-striped table-inverse table-responsive">
                        <thead>
                            <tr>
                                <th></th>
                                <th>รหัส-สินค้า</th>
                                <th>ชื่อ-สินค้า</th>
                                <th>จำนวนที่รับ(ใหม่)</th>
                                <th>จำนวนที่เหลือ</th>
                                <th>จำนวนทั้งหมด</th>
                                <th>ต้นทุนต่อหน่วย(ใหม่)</th>
                                <th>ต้นทุนทั้งหมด</th>
                                <th>วันที่-เดือน-ปี</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let item of datacosts|filter:seacrhdata |filter:seacrhdatetime| paginate: { itemsPerPage: 10, currentPage: p };let i=index">
                                <td><button type="button" class="btn btn-sm btn-primary"
                                        (click)="lookupdetailcost(item)" data-toggle="modal"
                                        data-target="#lookupdetailcost"><i class="fa fa-search"
                                            aria-hidden="true"></i></button></td>
                                <td>{{item.sku}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.new_stock|number}}</td>

                                <td>{{item.remaining_stock|number}}</td>
                                <td>{{item.remaining_stock + item.new_stock |number}}</td>
                                <td>{{item.new_cost|number:"1.2"}}</td>
                                <td>{{item.cost_total|number:"2.2"}}</td>
                                <td>{{item.date_input |date:'dd/MM/yyyy '}}</td>

                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="lookupdetailcost" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">รายละเอียดข้อมูลการรับสินค้า</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-3">
                        <input type="date" placeholder="ค้นหาข้อมูล" class="form-control"
                            [(ngModel)]="seacrhdatetimedetailget" name="" id="">
                    </div>
                    <div class="col-sm-3">
                        <button type="button" (click)="nulldatadetail()" class="btn btn-sm btn-danger">ยกเลิก</button>
                    </div>
                </div>

                <div class="d-flex flex-row-reverse bd-highlight">
                    <div class="p-2 bd-highlight">
                        <pagination-controls (pageChange)="detailget = $event"></pagination-controls>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row">


                    </div>
                    <table class="table  table-striped table-inverse table-responsive">
                        <thead>
                            <tr>
                                <th>รหัส-สินค้า</th>
                                <th>ชื่อ-สินค้า</th>
                                <th>จำนวนที่รับ(ใหม่)</th>

                                <th>จำนวนที่เหลือ</th>
                                <th>จำนวนทั้งหมด</th>
                                <th>ต้นทุนทั้งหมด</th>
                                <th>ต้นทุนต่อหน่วย(ใหม่)</th>
                                <th>ต้นทุนต่อหน่วย(เก่า)</th>
                                <th>วันที่-เดือน-ปี</th>
                                <th>ผู้รับสินค้า</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let item of detailcost|filter:seacrhdatetimedetailget| paginate: { itemsPerPage: 10, currentPage: detailget }">
                                <td>{{item.sku}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.stock_qty|number}}</td>
                                <td>{{item.remaining_stock|number}}</td>
                                <td>{{item.remaining_stock + item.stock_qty|number}}</td>
                                <td>{{item.cost_total|number:"2.2"}}</td>
                                <td>{{item.cost_unit|number:"1.2"}}</td>
                                <td>{{item.cost_unit_old|number:"1.2"}}</td>
                                <td>{{item.date_input |date:'dd/MM/yyyy '}}</td>
                                <td>{{item.nameuser}}</td>

                        </tbody>
                    </table>

                </div>
            </div>

        </div>
    </div>
</div>