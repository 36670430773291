<div class="container">
    <div class="card  card-primary">
        <div class="card-header">ยอดเดือนที่ : 01/202</div>
        <div class="card-body">
            <div class="chart-wrapper">
                <canvas baseChart 
                    [datasets]="lineChartData" 
                    [labels]="lineChartLabels" 
                    [options]="lineChartOptions"
                    [colors]="lineChartColors" 
                    [legend]="lineChartLegend" 
                    [chartType]="lineChartType" 
                    [plugins]="lineChartPlugins">
                </canvas>
            </div>
        </div>
    </div>
      <div class="card  card-sm">
        <div class="card-header">ยอดเดือนที่ : 02/2020</div>
        <div class="card-body">
          
        </div>
      </div>
</div>
   
