import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ShareService } from '../../ShareService'
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService, private router: Router) { }
  orders: any = []
  ordersCf: any = []
  OrdersTotal: any = null
  Order: any = {
    address_1: null,
    address_2: null,
    city: null,
    company: null,
    country: null,
    email: null,
    first_name: null,
    last_name: null,
    phone: null,
    postcode: null,
    state: null,
    id: null,
    id_orderOnline:null,
    date_completed: null,
    date_created: null
  }
  ID_order: any = null
  oknull: any = null
  listproduct: any = []
  orderprocessing: any = null
  ordersuccess: any = null
  ordercancelled: any = null
  state: any = []
  seacrhorder:any = null
  pageOfItems: any = 1
  page =[
    '1','2','3','4','5','6','7','8','9','10'
  ]
  status:any = [{status:'any'},{status:'panding'},{status:'processing'},{status:'on-hold'},{status:'completed'},{status:'cancelled'},{status:'refunded'},{status:'failed'}] 
  
  selectstatus:any = null
  ngOnInit(): void {
    this.loadCookie();
    this.LoadDataOrders()

    this.LoadDataOrdersCf();
    this.loadIDonline();
    this.loadstateEn()
  }
  statusUser: any = {
    username: null,
    name: null,
    token: null,
    email: null,
    id: null,
    status: null,
    billing_state:null,
    billing_address_1:null,
    billing_address_2:null,
    billing_city:null,
    billing_postcode:null,
    billing_phone:null,
    stete:null,
    ID_branch:null
  }
  newstate: any = null
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
  
    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }
  LoadDataOrders() {
    this.http.get(this.ShareService.serverPath + '/order/orders').subscribe((res: any) => {
      res.forEach((e:any) => {
        if (e.parent_id == 0) {
          this.orders.push(e)
        }
      });
       
  
      this.OrdersTotal = res.length
      var processing = res.filter(function (res: any) {
        return res.status == "processing";
      });
      this.orderprocessing = processing.length
      var success = res.filter(function (res: any) {
        return res.status == "completed";
      });
      this.ordersuccess = success.length
      var cancelled = res.filter(function (res: any) {
        return res.status == "cancelled";
      });
      this.ordercancelled = cancelled.length
    })
    this.loadstate()
  }
  LoadDataOrdersCf() {
    this.http.get(this.ShareService.serverPath + '/order/ordersonlinedetail').subscribe((res: any) => {
      if (res) {
        this.ordersCf = res
    

      }

    })

  }
  loadIDonline() {
    this.http.post(this.ShareService.serverPath + '/product/FirstIDOnline',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
      if (res) {

        var id: number = res 
        this.ID_order = id
      
      }

    })
  }
  loadstate() {
    this.http.get(this.ShareService.serverPath + '/product/state').subscribe((res: any) => {

      this.state = res

    })
  }
  selectorder(item: any) {
    this.oknull = null
    this.Order = item.billing
    this.Order.id = item.id
    this.listproduct = item.line_items

    this.Order.date_completed= item.date_completed
    this.Order.date_created= item.date_created
    var state = this.state.filter((e: any) => {
      return e.id == this.Order.state
    })
    this.newstate = state[0].state
    var nullObj = this.ordersCf.filter((e: any) => {
      return e.order_id == item.id
    })
    if (nullObj.length !== 0 ) {
      this.Order.id_orderOnline = nullObj[0].ID
 
    if (nullObj.length != 0) {
      this.oknull = 1
    }
    }
    

  }
  CfOrder(item: any) {
    this.oknull = 1
    var order = this.orders.filter((e: any) => {
      return e.id == item.id
    })


    var param = [{
      productOrder: order[0].line_items,
      Order: this.Order,
      total: order[0].total,
      date_created: order[0].date_created,
      id_customer: order[0].customer_id,
      ID: this.ID_order
    }]

    this.http.post(this.ShareService.serverPath + '/order/detailOrdersOnline', param).subscribe((res: any) => {
      if (res) {
   
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'ยืนยันสำเร็จ \nโปรดรอสักครู่....',
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          this.router.navigate(['/', 'orders']).then(() => {
            this.LoadDataOrders()
            this.LoadDataOrdersCf();
            this.loadIDonline();
          }, err => {
            // when there's an error
          });
        });
        
      }
    })
  }
  seacrhOrder(){
    this.http.post(this.ShareService.serverPath + '/order/lookuporders',[this.seacrhorder]).subscribe((res: any) => {
     
      res.forEach((e:any) => {
        if (e.parent_id == 0) {
          this.orders = res
          this.seacrhorder = null
        }
      });
    })
  }
  nextpage(item:any){
  
    this.http.post(this.ShareService.serverPath + '/order/lookupordersselectpage',[item]).subscribe((res: any) => {
  
      res.forEach((e:any) => {
        if (e.parent_id == 0) {
          this.orders = res
          this.seacrhorder = null
          this.pageOfItems = item
        }
      });
    })

  }
  nextpages(){
    this.pageOfItems++
    var param = this.pageOfItems++
 
    this.http.post(this.ShareService.serverPath + '/order/lookupordersselectpage',[param]).subscribe((res: any) => {

      res.forEach((e:any) => {
        if (e.parent_id == 0) {
          this.orders = res
          this.seacrhorder = null
        }
      });
    })

  }
  backpages(){
    this.pageOfItems--
    var param = this.pageOfItems--

    this.http.post(this.ShareService.serverPath + '/order/lookupordersselectpage',[param]).subscribe((res: any) => {
   
      res.forEach((e:any) => {
        if (e.parent_id == 0) {
          this.orders = res
          this.seacrhorder = null
        }
      });
    })

  }
  selectStatus(e:any){

  this.selectstatus = e.target.value
    
    this.http.post(this.ShareService.serverPath + '/order/lookupordersstatus',[this.selectstatus]).subscribe((res: any) => {
  
      this.orders = res
      if (res.data == "err") {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'ไม่มีข้อมูล',
          showConfirmButton: false,
          timer: 2000

        }).then(()=>{
          this.orders = []
        })
      }
    })
  }
  del(item:any){
  
    Swal.fire({
      title: 'คุณแน่ใจที่จะคำสั่งซื้อ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยันการลบคำสั่งซื้อ'
    }).then((result) => {
      if (result.isConfirmed) {
        var path = this.ShareService.serverPath + '/order/delOrder'
        this.http.post(path, item).subscribe((res: any) => {
          if (res.data == 'deleteSuccess') {

            Swal.fire(
              'ลบข้อมูลคำสั่งซื้อ!',
              'ลบข้อมูลคำสั่งซื้อสำเร็จ.',
              'success'
            ).then(()=>{
              this.loadCookie();
              this.LoadDataOrders()
          
              this.LoadDataOrdersCf();
              this.loadIDonline();
            })
          
          }


        })

      }
    })
    /* */
  }
  loadstateEn(){
    this.http.get(this.ShareService.serverPath + '/product/stateEn').subscribe((res: any) => {

      var filterstate = res.filter((e:any)=>{
        return e.id === this.statusUser.billing_state
      })
      this.statusUser.billing_state = filterstate[0].state
    
        this.http.get(this.ShareService.serverPath + '/product/state').subscribe((res: any) => {
         
          var filterstates = res.filter((e:any)=>{
            return e.id === filterstate[0].id
          })
          this.statusUser.state = filterstates[0].state
       ;
            
        })  
    })
  }
}
