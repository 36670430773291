import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ShareService } from '../../ShareService'
import Swal from 'sweetalert2';
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {

  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService) { }
  branchs: any = []
  accounts: any = []
  drawers: any = []
  states:any = [];
  ngOnInit(): void {
    this.loadCookie();
    this.loadbranch()
    this.loadacc()
    this.loaddrawer()
    this.loadstate()
  }
  statusUser: any = {
    status: null,
    username: null,
    token:null
  } 
  drw: any = {
    detail_drawer: null,
    remak: null,
    ID_acc: null,
    ID_branch: null,
    ID: null,
    status: 1,
    name: null 
  }
  acc: any = {
    ID: null,
    name_acc: null,
    detail: null,
    status: 1,
    ID_branch: null
  }
  branchname:any = null
  brah: any = {
    ID: null,
    name: null,
    address: null,
    city: null,
    state: null,
    postcode: null,
    status: 1,
  }
  loadstate(){
    this.http.get(this.ShareService.serverPath + '/branch/state').subscribe((res: any) => {
      this.states = res
      console.log(this.states);

    })
  }
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
    console.log(allCookies);
    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }
  loadacc() {
    this.http.post(this.ShareService.serverPath + '/account/showaccount',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
      this.accounts = res
      console.log(this.accounts);

    })
  }
  loadbranch() {
    var param = {token:this.statusUser.token}
    this.http.post(this.ShareService.serverPath + '/branch/showbranch',param).subscribe((res: any) => {
      
      var filterbranch = res.filter((e:any)=>{
         return e.id == this.statusUser.ID_branch 
      })
      this.branchs = filterbranch
      this.branchname = filterbranch[0].dokan_store_name
      console.log(this.branchs)
    })
  }
  loaddrawer() {
    this.http.post(this.ShareService.serverPath + '/drawer/showdrawer',{ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
      this.drawers = res
      console.log(this.drawers);

    })
  }
  saveacc() {
    console.log(this.acc);
    var path = this.ShareService.serverPath + '/account/insertaccount'
    if (this.acc.ID == null) {
      var filterbranch = this.branchs.filter((res:any)=>{
        return res.dokan_store_name ===  this.branchname
      })
      this.acc.ID_branch = filterbranch[0].id
    }
    if (this.acc.ID !== null) {
      path = this.ShareService.serverPath + '/account/updateaccount'
      var filterbranch = this.branchs.filter((res:any)=>{
        return res.dokan_store_name ===  this.acc.ID_branch
      })
      this.acc.ID_branch = filterbranch[0].id
    }
    this.http.post(path, this.acc).subscribe((res: any) => {
      console.log(res);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'เพิ่มข้อมูลบัญชีเรียบร้อย ',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        this.acc = {
          ID: null,
          name_acc: null,
          detail: null,
          status: 1,
          ID_branch: null
        }
        this.loadbranch()
        this.loadacc()
        this.loaddrawer()
      })
    })
  }
  selectacc(e: any) {
    this.acc.status = e.target.value
    console.log(e.target.value);

  }
  selectbranch(e: any) {
   
    var filterbranch = this.branchs.filter((res:any)=>{
      return res.id ===  Number(e.target.value)
    })
    if (filterbranch.length !== 0  ) {
      this.acc.ID_branch = filterbranch[0].dokan_store_name 
    }
  }
  savedrw() {
    console.log(this.acc);
    var path = this.ShareService.serverPath + '/drawer/insertdrawer'
    if (this.drw.ID !== null) {
      path = this.ShareService.serverPath +  '/drawer/updatedrawer'
    }
    this.http.post(path, this.drw).subscribe((res: any) => {
      if(res.data === "insert"){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'เพิ่มข้อมูลลิ้นชักเงินรียบร้อย ',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.drw = {
            detail_drawer: null,
            remak: null,
            ID_acc: null,
            ID_branch: null,
            ID: null,
            status: 1
          }
    
          this.loadbranch()
          this.loadacc()
          this.loaddrawer()
        })
      }
      if(res.data === "update"){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'แก้ไขข้อมูลลิ้นชักเงินรียบร้อย ',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.drw = {
            detail_drawer: null,
            remak: null,
            ID_acc: null,
            ID_branch: null,
            ID: null,
            status: 1
          }
    
          this.loadbranch()
          this.loadacc()
          this.loaddrawer()
        })
      }
        
      
  })
}
savebrah(){
  
  var path = this.ShareService.serverPath + '/branch/insertbranch' 
  if (this.brah.ID == null) {
    var filterstate = this.states.filter((e:any)=>{
      return e.state === this.brah.state 
     })
    this.brah.state = filterstate[0].id
  }
  if (this.brah.ID !== null) {
    path = this.ShareService.serverPath + '/branch/updatebranch'
    var filterstate = this.states.filter((e:any)=>{
      return e.state === this.brah.state 
     })
    this.brah.state = filterstate[0].id
  }
  this.http.post(path, this.brah).subscribe((res: any) => {
    console.log(res);

    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'เพิ่มข้อมูลสาขารียบร้อย ',
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      this.brah = {
        ID: null,
        name: null,
        address: null,
        city: null,
        state: null,
        postcode: null,
        status: 1,
      }
      this.loadbranch()
      this.loadacc()
      this.loaddrawer()

     
    })
  })
}
lookupacc(item: any){
  
  this.acc.ID = item.ID
  this.acc.name_acc = item.name_acc
  this.acc.detail = item.detail
  this.acc.status= item.status
  var filterbranch = this.branchs.filter((res:any)=>{
    return res.id ===  Number(item.ID_branch)
  })
  if (filterbranch.length !== 0  ) {
    this.acc.ID_branch = filterbranch[0].dokan_store_name 
  }
 
  console.log(this.acc);
  
}
///เลือกในหมวดของล็อคเกอร์เงิน
selectbranchs(e: any){
  this.drw.ID_branch = e.target.value
 
  
}
selectaccounts(e: any){
  this.drw.ID_acc = e.target.value
  console.log(e.target.value);
  console.log( this.drw.ID_acc);
  
  var filteracc = this.accounts.filter((res:any)=>{
    return res.ID === Number(e.target.value)
  })
  console.log(filteracc);
  if (filteracc.length === 1  ) {
    this.drw.ID_branch = filteracc[0].ID_branch 
  }
}
  /////

  lookupbrah(item:any){
    console.log(item);
    this.brah =item
    var filterstate = this.states.filter((e:any)=>{
      return e.id === item.state 
     })
    this.brah.state = filterstate[0].state
  }
  selectbrahstatus(e:any){
    this.brah.status = e.target.value
   }
   lookupdrawer(item:any){
    this.drw.detail_drawer=item.detail_drawer
    this.drw.remak =item.remak
    this.drw.ID_acc=item.ID_acc
    this.drw.ID_branch =item.ID_branch
    this.drw.ID=item.ID
    this.drw.status=item.status
    this.drw.name = item.name
   }
   selectdrw(e:any){
    this.drw.status = e.target.value
    console.log(e.target.value);

   }
}
