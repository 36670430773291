import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShareService } from '../../ShareService';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-debtor',
  templateUrl: './debtor.component.html',
  styleUrls: ['./debtor.component.css']
})
export class DebtorComponent implements OnInit {

  constructor(private http: HttpClient, private ShareService: ShareService, private Cookie: CookieService) { }

  ngOnInit(): void {
    this.loadCookie()
    this.cuscredit()
  }
  statusUser: any = {
    status: null,
    username: null,
    id: null
  }
  history:number = 1
  successhistory:any = 1
  searchhitory:any = null
  datahitory:any = []
  customercredit:any = []
  customercreditdetail:any = []
  ID_cus :any = null
  companycutomer:any = null
  orderID:any = null
  creditdetail:any = []
  productdetail:any = []
  paymentcerdit: any = {
    ID: null,
    ID_credit: null,
    total_payment: null,
    type_payment: null

  }
 
  loadCookie() {
    const allCookies: {} = this.Cookie.getAll();
    
    this.statusUser = allCookies
    if (this.statusUser.status == null || this.statusUser.username == null) {
      alert("โปรดเข้าสู่ระบบ")
      location.href = '/login'
    }

  }
  cuscredit() {
    this.http.post(this.ShareService.serverPath + '/customer/customercredit', { ID_branch: this.statusUser.ID_branch }).subscribe((res: any) => {
      if (res) {
        this.customercredit = res
        

      }
    })
  }
  lookupallmembercredit(item: any) {
  
    this.ID_cus = item.ID_cus
    this.companycutomer = item.company
    this.http.post(this.ShareService.serverPath + '/customer/customercreditdetail', { ID_branch: this.statusUser.ID_branch, ID_cus: item.ID_cus }).subscribe((res: any) => {
      if (res) {
        this.customercreditdetail = res
        
      }
    })
  }

  detailcreditcustomerlookup(item: any) {
    this.orderID = item.ID_order
    this.paymentcerdit.ID_credit = item.ID_credit
    this.http.post(this.ShareService.serverPath + '/customer/creditdetailforcustomer', { ID_order: item.ID_order }).toPromise().then((res: any) => {
      if (res) {
        this.creditdetail = res
        

      }
    }).then(()=>{
      this.http.post(this.ShareService.serverPath + '/customer/productcreditdetailforcustomer', { ID_order: item.ID_order }).toPromise().then((res: any) => {
        if (res) {
          this.productdetail = res
      
  
        }
      })
    })
  }
  savepaymentcredit() {
    if (this.paymentcerdit.type_payment == null) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'โปรดเลือกรูปแบบการชำระ',
        showConfirmButton: false,
        timer: 1500
      })
    } else if (this.paymentcerdit.total_payment == null && this.paymentcerdit.total_payment == 0) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'โปรดกรอก จำนวนเงิน',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      this.paymentcerdit.ID_order = this.orderID
      this.paymentcerdit.ID_branch = this.statusUser.ID_branch
      this.paymentcerdit.ID_users = this.statusUser.name
      
      this.http.post(this.ShareService.serverPath + '/customer/paymentcerdit', this.paymentcerdit).subscribe((res: any) => {
        if (res) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'เพิ่มข้อมูลการชำระสำเร็จ',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.detailcreditcustomerlookup({ ID_order: this.paymentcerdit.ID_order })
            this.lookupallmembercredit({ID_branch:this.statusUser.ID_branch,ID_cus:this.ID_cus})
            this.cuscredit()
          }).then(() => {
            this.paymentcerdit = {
              ID: null,
              ID_credit: null,
              total_payment: null,
              type_payment: null

            }
          })
          
        }
      })
    }

  }
  selectpaymentcredit(e: any) {
    this.paymentcerdit.type_payment = e.target.value
  }

  historypaymentcredit(){
    this.http.post(this.ShareService.serverPath + '/customer/historypaymentcredit', {ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
      this.datahitory = res
      
      
    })
  }
  datahitorysuccess:any = []
  historypaymentcreditsuccess(){
    this.http.post(this.ShareService.serverPath + '/customer/historypaymentcreditsuccess', {ID_branch:this.statusUser.ID_branch}).subscribe((res: any) => {
      this.datahitorysuccess = res
    
    })
  }
}
