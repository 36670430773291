import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ShareService } from '../ShareService'
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-menuadmin',
  templateUrl: './menuadmin.component.html',
  styleUrls: ['./menuadmin.component.css']
})
export class MenuadminComponent implements OnInit {
  @ViewChild("myinput") myInputField!: ElementRef;
  ngAfterViewInit() {
    this.myInputField.nativeElement.focus();
  }

  constructor(private http: HttpClient,
    private ShareService: ShareService,
    private router: Router,
    private Cookie: CookieService) {

  }
  statusUser :any = {
    username:null,
    name:null,
    token:null,
    email:null,
    id:null,
    status:null,
    ID_branch:null,
    ID_drw:null,
    ID_formdrwusr:null
  }

  product = {
    sku: null,
    iduser:null
  }
  loadCookie(){
    const allCookies: {} = this.Cookie.getAll();
   
    this.statusUser = allCookies
    this.product.iduser=this.statusUser.id
  }
  Lookup(e: any) {
    this.product.sku = e.target.value
    var param = {sku:this.product.sku ,ID_branch:this.statusUser.ID_branch,iduser:this.statusUser.id}
    if (this.product.sku !== '') {
      this.http.post(this.ShareService.serverPath + '/product/insertcart', param).subscribe((res: any) => {
        if (res.data == 'nohavedata') {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'ไม่มีข้อมูลสินค้าที่คุณเลือก!',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          }).then(() => {
            this.myInputField.nativeElement.value = null
          });
        }else if(res.data === 'soldout'){
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'สินค้านี้ได้หมดไปแล้ว \n โปรดลองใหม่อีกครั้ง ',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          }).then(()=>{
            this.myInputField.nativeElement.value = null
           
          })
        }else if (res.data === 'insertsuccess') {
          this.myInputField.nativeElement.value = null
         
          location.href = '/cart'
        }
      })
    }

    this.myInputField.nativeElement.value = null
  }
  lookupproduct(){
    console.log(this.product);
    var param = {sku:this.product.sku ,ID_branch:this.statusUser.ID_branch,iduser:this.statusUser.id}
    if (this.product.sku !== ''  ) {
      this.http.post(this.ShareService.serverPath + '/product/insertcart', param).subscribe((res: any) => {
        if (res.data == 'nohavedata') {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'ไม่มีข้อมูลสินค้าที่คุณเลือก!',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          }).then(() => {
            this.myInputField.nativeElement.value = null
          });
        }else if(res.data === 'soldout'){
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'สินค้านี้ได้หมดไปแล้ว \n โปรดลองใหม่อีกครั้ง ',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          }).then(()=>{
            this.myInputField.nativeElement.value = null
           
          })
        }else if (res.data === 'insertsuccess') {
          this.myInputField.nativeElement.value = null
         
          location.href = '/cart'
        }
      })
    }
  }
  gato(){
    if (this.statusUser.token !== null ) {
      this.router.navigate(['/', 'Dashboard']);
     
      
    }
  }
  ngOnInit(): void {
    this.loadCookie();

  }
  logout(){
    Swal.fire({
      title: 'คุณแน่ใจหรอที่จะออกจากระบบ?',
      text: "กดปุ่มตกกลงเพื่อออกจากระบบ!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก' ,
      confirmButtonText: 'ตกลง ออกจากระบบ'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          position: 'top',
          icon: 'info',
          title: 'กำลังออกจากระบบ \nโปรดรอสักครู่....',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        }).then(() =>{
          this.Cookie.deleteAll();
        location.href = '/login'
        })
      }
    })
    
  }
  checkdrw(){
    if (!this.statusUser.ID_drw) {
     // this.router.navigate([/],"account", { relativeTo: this.route })
     Swal.fire({
      position: 'top',
      icon: 'error',
      title: 'ไม่มีข้อมูลบัญชี และลิ้นชัก \n โปรดกำหนดสิทธิ์ด้วย ค่ะ/ครับ ',
      showConfirmButton: false,
     
      })
    } else {
     
      location.href = '/account'
    }
  }
}


