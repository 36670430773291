<div class="container">
    <div class="card  card-dark">
        <div class="card-header">ยอดปีที่ : 2020</div>
        <div class="card-body">
          <div class="chart-wrapper">
              <canvas baseChart 
              [datasets]="barChartData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartType">
            </canvas>
          </div>
        </div>
      </div>
</div>
   
