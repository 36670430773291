<div class="card card-primary">
    <div class="card-header">จัดการสาขา</div>
    <div class="card-body">
      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addbranch">เพิ่มสาขา</button> 
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th>รหัสสาขา</th>
                    <th>ชื่อสาขา</th>
                    <th>อีเมล์</th>
                    <th>เบอร์โทร</th>
                    <th>วัน-เดือน-ปี</th>
                    <th>สถานะ</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of branchs">
                    <td><button data-toggle="modal" class="text-primary" (click)="lookupbrah(item)"
                            data-target="#showbrah" class="btn  btn-sm btn-primary"><i class="fa fa-search"
                                aria-hidden="true"></i></button></td>
                    <td><a data-toggle="modal" class="text-primary" (click)="lookupbrah(item)"
                            data-target="#showbrah">{{item.id}}</a></td>
                    <td><a data-toggle="modal" class="text-primary" (click)="lookupbrah(item)"
                            data-target="#showbrah">{{item.dokan_store_name}}</a></td>
                    <td>{{item.email}}</td>
                    <td>{{item.dokan_profile_settings.phone}}</td>
                    <td>{{item.registered_date|date:'dd/MM/yyyy HH:ss'}}</td>
                    <td>
                        <div class="custom-control custom-switch" *ngIf="item.dokan_enable_selling == 'no' ">
                            <input type="checkbox" (click)="openbranch(item)" class="custom-control-input"
                                id="{{item.id}}">
                            <label class="custom-control-label" for="{{item.id}}">-<strong
                                    class="text-warning">ปิดการใช้งาน</strong></label>
                        </div>
                        <div class="custom-control custom-switch" *ngIf="item.dokan_enable_selling == 'yes'">
                            <input type="checkbox" (click)="closebranch(item)" checked class="custom-control-input"
                                id="{{item.id}}">
                            <label class="custom-control-label" for="{{item.id}}">-<strong
                                    class="text-success">เปิดการใช้งาน</strong></label>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</div>
<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="showbrah" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ชื่อสาขา:{{branch.billing_first_name}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
<div class="row">
     <div class="col-sm-6">
        <label for="">ที่อยู่</label>
       
     </div>
</div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-warning " >แก้ไข</button>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
                <div class="modal-header">
                        <h5 class="modal-title">Modal title</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
            <div class="modal-body">
                <div class="container-fluid">
                    Add rows here
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>

