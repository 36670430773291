
<div class="row">
    <div class="col-xl-8">
        <div class="card card-primary">
            <div class="card-header"><i class="fa fa-cart-plus" aria-hidden="true"></i>&nbsp; แสดงข้อมูลสินค้า </div>
            <div class="card-body">
                <div class="contrainer">
                    <div class="row ">
                        <div class="col-sm-3"><strong>จำนวนสินค้าทั้งหมด</strong> </div>
                        <div class="col-sm-3"><strong>{{totallist}} &nbsp;รายการ</strong></div>
                        <div class="col-sm-3"><strong>{{Countsum}} &nbsp;ชิ้น</strong></div>

                        <div class="col-sm-3"><button (click)="closeSell()" *ngIf="Products != null"
                                class="btn btn-sm btn-danger">ยกเลิกการขาย</button></div>
                    </div>

                    <table class="table mt-2 ">
                        <thead class="thead-inverse">
                            <tr>
                                <th>รหัสสินค้า</th>
                                <th>ชื่อ-สินค้า</th>
                                <th>จำนวนสินค้า</th>
                                <th style="text-align:right ;">ราคาต่อหน่วย</th>
                              <th></th>
                                <th>ราคารวม</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of Products">
                                <td >{{item.sku}}</td>
                                <td>{{item.name}}</td>
                                <td style="width: 100px;">
                                   
                                        
                                            <input class="form-control" type="text" min="1" (change)="upqty($event)"
                                                value="{{item.quantity}}" name="" id="{{item.ID_cart}}">
                                     
                                  
                                </td>
                                <td style="text-align:right ;"><strong>{{item.price|number}}</strong></td>
                                <td>
                                    <div *ngFor="let items of wholesales">
                                        <div *ngIf="items.ID_product  == item.id">
                                                    <select id="{{item.id}}" class="form-control"
                                                        (change)="selectMoreprice($event)">
                                                        <option value="{{item.price}}">{{item.price|number}}</option>
                                                        <option value="{{items.price}}">P1:{{items.price|number}}</option>
                                                        <option value="{{items.wholesale}}">P2:{{items.wholesale|number}}
                                                        </option>
                                                        <option value="{{items.P3}}">P3:{{items.P3|number}}</option>
                                                        <option value="{{items.P4}}">P4:{{items.P4|number}}</option>
                                                    </select>

                                        </div>

                                    </div>
                                </td>

                                <td><strong>{{item.quantity * item.price |number}}</strong></td>
                                <td><button class="btn btn-sm btn-danger " (click)="delProduct(item)"><i
                                            class="fa fa-trash" aria-hidden="true"></i></button> </td>
                            </tr>

                        </tbody>
                    </table>

                </div>

            </div>
        </div>


    </div>
    <div class="col-xl-4">
        <div class="card card-primary">
            <div class="card-header"><i class="fas fa-cash-register"></i> &nbsp;แสดงข้อมูลยอดรวม
            </div>
            <div class="card-body">
                <div class="contrainer">
                    <div class="row">
                        <div class="col-sm-6">
                            <h4><strong>ยอดรวม</strong></h4>
                        </div>
                        <div class="col-sm-6">
                            <h4><strong> {{TotalPrice|number}} &nbsp;&nbsp; บาท</strong></h4>
                        </div>

                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-sm-6">
                            <strong>เบอร์โทร/เลขสมาชิก:</strong>
                        </div>
                        <div class="col-sm-6">
                            <input type="text" class="form-control" [(ngModel)]="TelMember"
                                (change)="selectmember($event)" placeholder="กรอก เบอร์โทร/เลขสมาชิก">
                        </div>
                    </div>
                    <br>
                    <div class="row">

                        <div class="col-sm-6"> <strong>ส่วนลด:</strong> </div>

                        <div class="col-sm-6"><input type="number" placeholder="กรอก ส่วนลด" min="1"
                                (change)="seleprice($event)" class="form-control">
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-6">
                            <h3><strong>ยอดชำระ:</strong></h3>
                        </div>
                        <div class="col-sm-6">
                            <h3> <strong>{{GrandTotal|number}}&nbsp; บาท</strong> </h3>
                        </div>
                    </div>
                    <br>
                    <div class="row col-sm-12 ">

                        <div *ngFor="let item of detailpayment">

                            <div class="form-check">

                                <label class="form-check-label">

                                    <input type="radio" (click)="selectpay(item)" value="" class="form-check-input "
                                        name="optionradio">


                                    <div *ngIf="item == 'cash' "><strong>เงินสด</strong></div>
                                    <div *ngIf="item == 'bank' "><strong>โอน</strong></div>
                                   <!--<div *ngIf="item == 'credit' "><strong>เครดิต</strong></div>--> 


                                </label>

                            </div>

                            &nbsp;


                        </div>

                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <strong>รับเงิน:</strong>
                        </div>
                        <div class="col-sm-6">
                            <input type="number" placeholder="กรอก จำนวนเงิน" [(ngModel)]="getmoney"
                                min="{{GrandTotal}}" (change)="getmoneys($event)" class="form-control">
                        </div>
                    </div>
                    <br>
                   


                    <div class="d-flex justify-content-center"><button type="button" class="btn btn-success"
                            *ngIf="getmoney != null && payment != null " (click)="save()">&nbsp;&nbsp;&nbsp;<i
                                class="fas fa-clipboard-check"></i>&nbsp; <strong>จบการขาย</strong>
                            &nbsp;&nbsp;&nbsp;</button>
                    </div>



                </div>

            </div>
        </div>

    </div>
</div>