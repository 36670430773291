import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-selegood',
  templateUrl: './selegood.component.html',
  styleUrls: ['./selegood.component.css']
})
export class SelegoodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
