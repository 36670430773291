<!-- Modal -->
<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="demo">
        <div class="container">

          <h2 class="text-center"><strong>{{statusUser.name_branch}}</strong></h2>

          <small> {{statusUser.billing_address_1}}{{statusUser.billing_address_2}} อ.{{statusUser.billing_city}}
            จ.{{statusUser.state}} </small><br>
          <small>Tel.{{statusUser.billing_phone}}</small>&nbsp;&nbsp;
          <small>No.{{sale.ID_order}}</small> <br>
          <small>วันที่:{{date_input|date:"dd-MM-yyy"}}</small><br>
          <div></div>
          <div *ngIf="billing.member_id != null">
            ชื่อลูกค้า {{billing.first_name}}&nbsp;{{billing.last_name}} ( {{billing.phone}} ) <br>
            ที่อยู่ {{billing.address_1}}&nbsp;{{billing.address_2}}&nbsp;{{billing.city}} &nbsp; {{billing.postcode}}
          </div>


          <hr>
          <table id="t01" class="table">
            <thead>
              <tr>
                <th>รายการ</th>
                <th>จำนวน</th>
                <th>ราคา</th>
                <th>รวม</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let items of DataSaleandproducts">
                <td><small>{{items.name}}</small></td>
                <td><small>{{items.quantity}}</small></td>
                <td><small>{{items.price}}</small></td>
                <td><small>{{items.quantity * items.price |number}}</small></td>
              </tr>

            </tbody>
          </table>
          <hr>
          <table width="100%" class="table">
            <thead>
              <tr>
                <th> รวมสุทธิ</th>
                <th></th>
                <th></th>
                <th><u>{{GrandTotal|number}}&nbsp;บาท</u> </th>
              </tr>
            </thead>

          </table>


          <div *ngIf="sale.payment == 'credit'">
            <hr>
            <small>
              กรุณาโอนเงินเข้าบัญชี
            </small> <br>
            <small>
              พร้อมเพย์: 0955561000243
            </small><br>
            <small>
              บริษัท แฮส ออเดอร์ จำกัด
            </small>
          </div>
          <hr>
          <div class="text-center" *ngIf="sale.payment !== 'credit'">
            รับเงิน: {{getmoney|number}} |เงินทอน: {{changemoney|number}} <br>
            ผู้รับเงิน:{{users}}|ขอบคุณค่ะ/ครับ
        </div>
        <div class="text-center" *ngIf="sale.payment === 'credit'">
            รับเงิน: {{getmoney|number}} |ยอดค้างชำระ: {{changemoney|number}} <br>
            ผู้รับเงิน:{{users}}|ขอบคุณค่ะ/ครับ
        </div>

          <div class="d-flex justify-content-center">
            <small>ซื้อสินค้าออนไลน์ได้ที่ &nbsp; <strong> www.pinsouqstore.com </strong> </small>
            <br>
            <small> หรือแอดไลน์&nbsp;<strong>@pinsouqstore</strong> </small>

          </div>
          <div class="d-flex justify-content-center">
            <img src="../assets/img/Line_QR.jpg" style="width: 30%;" class="brand-image img-circle elevation-3">
          </div>


        </div>


      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-sm-6 col-md-3">
    <div class="info-box">
      <span class="info-box-icon bg-info elevation-1"><i class="fas fa-shopping-cart"></i></span>

      <div class="info-box-content">
        <span class="info-box-text">จำนวนสินค้าที่ขายวันนี้</span>
        <span class="info-box-number">

          {{TotleSaleDay|number}} <strong>ชิ้น</strong>
        </span>
      </div>
      <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
  </div>
  <div class="col-12 col-sm-6 col-md-3">
    <div class="info-box mb-3">
      <span class="info-box-icon bg-primary elevation-1"><i class="fas fa-users"></i></span>

      <div class="info-box-content">
        <span class="info-box-text">จำนวนสมาชิกที่ซื้อวันนี้</span>
        <span class="info-box-number">{{TotleCustomerSaleDay}} คน </span>
      </div>
      <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
  </div>
  <!-- /.col -->
  <div class="col-12 col-sm-6 col-md-3">
    <div class="info-box mb-3">
      <span class="info-box-icon bg-warning elevation-1  "><i class="fas fa-coins text-light"></i></span>

      <div class="info-box-content">
        <span class="info-box-text">ยอดรวมทั้งหมดวันนี้</span>
        <span class="info-box-number">{{totalpricetoday|number}} บาท</span>
      </div>
      <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
  </div>
  <!-- /.col -->

  <!-- fix for small devices only -->
  <div class="clearfix hidden-md-up"></div>

  <div class="col-12 col-sm-6 col-md-3">
    <div class="info-box mb-3">
      <span class="info-box-icon bg-success elevation-1"><i class="fas fa-hand-holding-usd"></i></span>

      <div class="info-box-content">
        <span class="info-box-text">ยอดขายสินค้าในเดือนนี้</span>
        <span class="info-box-number">{{totalpricetomonth|number}} บาท</span>
      </div>
      <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
  </div>
  <!-- /.col -->

  <!-- /.col -->
</div>



<div class="card card-primary">

  <div class="card-body">



    <div class="d-flex flex-row bd-highlight mb-3">

      <div class="p-2 bd-highlight">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#day">ยอดวันนี้</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" (click)="loadtotalgoodseles()" href="#goodseles">สินค้าขายดี</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" (click)="totalmonth()" href="#month">ยอดรายเดือน</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" (click)="totalyear()" href="#year">ยอดรายปี</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#customDate">ยอดกำหนดวัน</a>
          </li>

        </ul>
      </div>

    </div>


    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="day" role="tabpanel" aria-labelledby="home-tab">
        <div class="d-flex flex-row-reverse bd-highlight">
          <div class="p-2 bd-highlight">
            <input type="search" name="" class="form-control" (change)="searchbill()" [(ngModel)]="search" placeholder="ค้นหา" id="">
          </div>
          <div class="p-2 bd-highlight">
            <pagination-controls (pageChange)="today  = $event"></pagination-controls>
          </div>
        </div>
        <table class="table ">
          <thead>
            <tr>
              <th></th>
              <th>ลำดับที่</th>
              <th>เลขที่ใบเสร็จ</th>
              <th>จำนวนสินค้า</th>
              <th>ยอดรวมทั้งสิ้น</th>
              <th>เวลา</th>
              <th>ยกเลิกบิล</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of sales|filter:search| paginate: { itemsPerPage: 10, currentPage: today };let i =index">
              <td><button class="btn btn-info btn-sm" (click)="lookupsale(item)" data-toggle="modal"
                  data-target="#lookupsele"><i class="fa fa-search" aria-hidden="true"></i></button></td>
              <td>{{i+1}}</td>
              <td>#{{item.ID}}</td>
              <td>{{item.totalquantity|number}}</td>
              <td>{{item.totalprice|number}}บาท</td>
              <td>{{item.date_input|date:'HH:mm':'UTC+7'}}</td>
              <td><button type="button" class="btn btn-sm btn-danger " (click)="cancelbill(item)"><i class="fa fa-file" aria-hidden="true"></i></button></td>
            </tr>

          </tbody>

        </table>

      </div>
      <div class="tab-pane" id="goodseles" role="tabpanel" aria-labelledby="home-tab">
        <div class="d-flex flex-row-reverse bd-highlight">
          <div class="p-2 bd-highlight">
            <select name="" (change)="selectgoodsale($event)" class="form-control" id="">
              <option value="goodseleday">รายวัน</option>
              <option value="goodseleweek">รายสัปดาห์</option>
              <option value="goodselemonth">รายเดือน</option>
            </select>
           </div>
          <div class="p-2 bd-highlight">
            <input type="search" name="" class="form-control" [(ngModel)]="searchgoodsale" placeholder="ค้นหา" id="">
          </div>
          <div class="p-2 bd-highlight">
            <pagination-controls (pageChange)="goodsale = $event"></pagination-controls>
          </div>
         
        </div>
        <table class="table ">
          <thead>
            <tr>

              <th>ลำดับที่</th>
              <th style="text-align: right ;">รหัสสินค้า</th>
              <th style="text-align: left ;">ชื่อสินค้า</th>
              <th style="text-align: right ;">ขายรวม(ชิ้น)</th>
              <th style="text-align: right ;">ราคารวม(บาท)</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of totalgoodseles|filter:searchgoodsale |paginate: { itemsPerPage: 10, currentPage: goodsale };let i =index">
              <td>{{i+1}}</td>
              <td style="text-align: right ;">{{item.sku}}</td>
              <td style="text-align: left ;">{{item.name}}</td>
              <td style="text-align: right ;">{{item.qty|number}}</td>
              <td style="text-align: right ;">{{item.totalprices * item.qty |number:'2.2'}}</td>

            </tr>

          </tbody>

        </table>

      </div>
      <div class="tab-pane fade" id="month" role="tabpanel" aria-labelledby="profile-tab">
        <div class="d-flex flex-row-reverse bd-highlight">
          <div class="p-2 bd-highlight">
           
            <input type="date" name="" id="" [(ngModel)]="searchTo" class="form-control">
          </div>
          <div class="p-2 bd-highlight">
            <pagination-controls (pageChange)="monthsale = $event"></pagination-controls>
          </div>

        </div>
     
        <table class="table mt-2">
          <thead>
            <tr>
              <th>วันที่ขาย</th>
              <th style="text-align: right ;">จำนวน</th>
              <th style="text-align: right ;">ยอดขายรวม</th>
              <th style="text-align: right ;">ส่วนลดรวม</th>
              <th style="text-align: right ;">ยอดสุทธิ</th>
              <th style="text-align: right ;">ราคาต้นทุน(บาท)</th>
              <th style="text-align: right ;">กำไร(บาท)</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of totalselesmonth|filter:searchTo|paginate: { itemsPerPage: 10, currentPage: monthsale }">
              <td><strong data-toggle="modal" data-target="#seacrhdatadata" class="text-primary" (click)="seacrhdatadata(item)">{{item.date_string}}</strong></td>
              <td style="text-align: right ;">{{item.total_qty|number:'1.1'}}</td>
              <td style="text-align: right ;">{{item.sumprice|number:'2.2'}}</td>
              <td style="text-align: right ;">{{item.discounts|number:'2.2'}}</td>
              <td style="text-align: right ;">{{ item.sumprice - item.discounts|number:'2.2' }}</td>
              <td style="text-align: right ;">{{item.totalcost |number:'2.2'}}</td>
              <td style="text-align: right ;">{{ (item.sumprice - item.discounts) - item.totalcost |number:'2.0' }}</td>

            </tr>
          </tbody>

        </table>

      </div>
      <div class="tab-pane fade" id="year" role="tabpanel" aria-labelledby="contact-tab">
        <div class="d-flex justify-content-center">
          <div class="row">

            <div class="col-sm-12">
              <label for="">ค้นหาเดือน:</label>
              <input type="month" name="" id="" [(ngModel)]="searchTomonth" class="form-control">
            </div>
          </div>
        </div>
        <table class="table mt-2">
          <thead>
            <tr>
              <th>เดือนที่ขาย</th>
              <th style="text-align: right ;">จำนวน</th>
              <th style="text-align: right ;">ยอดขายรวม</th>

              <th style="text-align: right ;">ส่วนลดรวม</th>
              <th style="text-align: right ;">ยอดสุทธิ</th>
              <th style="text-align: right ;">ราคาต้นทุน(บาท)</th>
              <th style="text-align: right ;">กำไร(บาท)</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of totalyears|filter:searchTomonth">
              <td><a >{{item.date_string}}</a></td>
              <td style="text-align: right ;">{{item.total_qty|number:'1.1'}}</td>
              <td style="text-align: right ;">{{item.sumprice|number:'2.2'}}</td>
              <td style="text-align: right ;">{{item.discounts|number:'2.2'}}</td>
              <td style="text-align: right ;">{{ item.sumprice - item.discounts|number:'2.2' }}</td>
              <td style="text-align: right ;">{{item.totalcost |number:'2.2'}}</td>
              <td style="text-align: right ;">{{ (item.sumprice - item.discounts) - item.totalcost |number:'2.0' }}</td>

            </tr>
          </tbody>
        </table>
      </div>
      <div class="tab-pane" id="customDate" role="tabpanel" aria-labelledby="home-tab">
        <div class="d-flex justify-content-center">
          <div class="row">
            <div class="col-sm-5">
              <label for="">จากวันที่:</label>
              <input type="date" [(ngModel)]="scr.datefrom" name="" id="" class="form-control">
            </div>
            <div class="col-sm-5">
              <label for="">ถึงวันที่:</label>
              <input type="date" name="" [(ngModel)]="scr.dateto" id="" class="form-control">
            </div>
            <div class="col-sm-2">
              <label for="">ค้นหา:</label>
              <button type="button" class="btn btn-sm btn-success" (click)="loadcustomsales()"><i class="fa fa-search"
                  aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
        <table class="table mt-2">
          <thead>
            <tr>
              <th>วันที่ขาย</th>
              <th style="text-align: right ;">จำนวน</th>
              <th style="text-align: right ;">ยอดขายรวม</th>
              <th style="text-align: right ;">ส่วนลดรวม</th>
              <th style="text-align: right ;">ยอดสุทธิ</th>
              <th style="text-align: right ;">ราคาต้นทุน(บาท)</th>
              <th style="text-align: right ;">กำไร(บาท)</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of customsalesdata">
              <td><a href="">{{item.date_string}}</a></td>
              <td style="text-align: right ;">{{item.total_qty|number:'1.1'}}</td>
              <td style="text-align: right ;">{{item.sumprice|number:'2.2'}}</td>
              <td style="text-align: right ;">{{item.discounts|number:'2.2'}}</td>
              <td style="text-align: right ;">{{ item.sumprice - item.discounts|number:'2.2' }}</td>
              <td style="text-align: right ;">{{item.totalcost |number:'2.2'}}</td>
              <td style="text-align: right ;">{{ (item.sumprice - item.discounts) - item.totalcost |number:'2.0' }}</td>
            </tr>

          </tbody>

        </table>

      </div>
    </div>

  </div>
</div>




<div class="modal fade" id="seacrhdatadata" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
        <table class="table ">
          <thead>
            <tr>
              <th></th>
              <th>ลำดับที่</th>
              <th>เลขที่ใบเสร็จ</th>
              <th>จำนวนสินค้า</th>
              <th>ยอดรวมทั้งสิ้น</th>
              <th>เวลา</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of datasearchtotaltoday;let i =index">
              <td><button class="btn btn-info btn-sm" (click)="lookupsale(item)" data-toggle="modal"
                  data-target="#lookupsele"><i class="fa fa-search" aria-hidden="true"></i></button></td>
              <td>{{i+1}}</td>
              <td>#{{item.ID}}</td>
              <td>{{item.totalquantity|number}}</td>
              <td>{{item.totalprice|number}}บาท</td>
              <td>{{item.date_input|date:'HH:mm':'UTC+7'}}</td>
            </tr>

          </tbody>

        </table>
      </div>
      <div class="modal-footer">
        
      </div>
    </div>
  </div>
</div>







<!-- Modal -->
<div class="modal fade" id="lookupsele" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">ใบเสร็จเลขที่ #{{sale.ID_order}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="billing.member_id !== null">
          ชื่อลูกค้า: {{billing.first_name}}&nbsp;{{billing.last_name}} ( {{billing.phone}} )<br>
          ที่อยู่ {{billing.address_1}}&nbsp;{{billing.address_2}}&nbsp;{{billing.city}} &nbsp; {{billing.postcode}}
        </div>
        <div class="container-fluid">
          <table class="table">
            <thead>
              <tr>

                <th>รหัส-สินค้า</th>
                <th>ชื่อ-สินค้า</th>
                <th>จำนวน</th>
                <th>ราคาต่อหน่วย</th>
                <th>ราคารวมทั้งสิน</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of DataSaleandproduct">

                <td>{{item.sku}}</td>
                <td>{{item.name}}</td>
                <td>{{item.quantity|number}}</td>
                <td>{{item.price|number}}</td>
                <td>{{item.price * item.quantity|number }}</td>
              </tr>

            </tbody>
          </table>
          <table width="100%" class="table">
            <thead>
              <tr>
                <th> </th>
                <th></th>
                <th>รวมสุทธิ</th>
                <th></th>
                <th></th>
                <th></th>
                <th><u>{{GrandTotal|number}}&nbsp;บาท</u> </th>
              </tr>
            </thead>

          </table>

        </div>
      </div>
      <div class="modal-footer">

        <button type="button" class="btn btn-sm btn-primary" printSectionId="demo" ngxPrint><i
            class="fas fa-file-invoice-dollar"></i>&nbsp; ออกใบเสร็จ</button>
      </div>
    </div>
  </div>
</div>